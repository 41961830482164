import React, { useEffect, useState, } from 'react'
import Swal from 'sweetalert2';
import { addDoc, collection, doc, getDoc, onSnapshot, orderBy, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import "../../App.css";
import Menu from '../../panel/Menu';
import moment from 'moment';

function Damagenotes() {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  const [rooms, setrooms] = useState([]);
  const [damages, setdamages] = useState([]);
  const [username, setusername] = useState();
  const [userData, setUserData] = useState();
  const [loadincount, setloadincount] = useState(true);
  const [searchText, setsearchText] = useState("");

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUserData(user);
      fethUsers()
    });
  });

  const fethUsers = async () => {
    try {
      if (userData) {
        if (loadincount) {
          const docRef = doc(db, "users", userData.uid)
          const docSnap = await getDoc(docRef)
          if (docSnap.exists()) {
            setusername(docSnap.data().username);
            setloadincount(false)
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (searchText === '') {
      fetchData1()
    } else {
      fetchData2()
    }
  }, [searchText]);


  const fetchData1 = async () => {
    try {
      const q = query(collection(db, 'damages'), orderBy("createdAt", "desc"))
      onSnapshot(q, (querySnapshot) => {
        setdamages(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })));
      });
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.message
      });
    }
  }


  const fetchData2 = async () => {
    try {
      const filteredData = damages.filter((item) =>
        item.data.customers.custname.toUpperCase().includes(searchText.toUpperCase())
      );
      setdamages(filteredData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }





  return (
    <div>
      <Menu />
      <div className="content-wrapper" style={{ backgroundColor: '#fff' }}>
        <section className="content">
          <div className="container-fluid">
            <button className="btn btn-secondary " style={{ textTransform: "uppercase" }} >|| Damage Notes ||</button>
            <div className="card card-primary card-outline mt-2">
              <div className=" p-1">
                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                  className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
              </div>
              <div className="card-body table-responsive p-0" >

                <table className="table table-bordered table-hover table-striped table-head-fixed">
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>Customer Name </th>
                      <th>Customer Mobile </th>
                      <th>Damage Amount </th>
                      <th>Damage Note </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      damages.map((item, num = 1) =>
                        <tr key={num + 1}>
                          <td width="80">{num + 1}</td>
                          <td>{item.data.customers.custname}</td>
                          <td>{item.data.customers.mobile}</td>
                          <td>{item.data.damageamount} </td>
                          <td>{item.data.damagenote} </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

      </div>



    </div>
  )
}

export default Damagenotes
