import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { auth, db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';

import Login from './auth/Login';
import Register from './auth/Register';
import Header from './panel/Header';
import Menu from './panel/Menu';
import Footer from './panel/Footer';

import Dashboard from './components/Dashboard';
import Forgetpass from './auth/Forgetpass';

import Roomtypes from './components/Roomtypes';
import Rateplans from './components/Rateplans';
import Roomdetails from './components/Roomdetails';
import Roomtarrif from './components/Roomtarrif';
import Roomservices from './components/Roomservices';
import Paymentmode from './components/Paymentmode';
import Roomimages from './components/Roomimages';

import Usermgmt from './components/Users/Usermgmt';

import Websiterooms from './components/ota/Websiterooms';
import Websitegallery from './components/ota/Websitegallery';

import Approvals from './components/Approvals';
import Bookingtypes from './components/Bookingtypes';
import Allrooms from './components/dashboard/Allrooms';
import Livebookingrooms from './components/dashboard/Livebookingrooms';
import Availablerooms from './components/dashboard/Availablerroms';
import Cleaningrooms from './components/dashboard/Cleaningrooms';

import Onlinebookings from './components/ota/Onlinebookings';
import Pendingbookings from './components/ota/Pendingbookings';

import Livebookings from './components/booking/Livebookings';
import Viewdetails from './components/booking/Viewdetails';
import Advancebooking from './components/booking/Advancebooking';
import Viewadvance from './components/booking/Viewadvance';
import Advancedetails from './components/booking/Advancedetails';
import Websitereservation from './components/booking/Websitereservation';
import Futurereservation from './components/booking/Futurereservation';

import Checkouthistory from './components/booking/Checkouthistory';
import Roomcleanings from './components/booking/Roomcleaning';
import Roomcleaned from './components/booking/Roomcleaned';
import Damagenotes from './components/booking/Damagenotes';
import Hotelinvoice from './components/booking/Hotelinvoice';

import Allbookingreport from './components/report/Addbookingreport';
import Receptionistreport from './components/report/Receptionistreport';
import Logindetails from './components/report/Logindetails';
import Userlogs from './components/report/Userlogs';
import Paymodewise from './components/report/Paymodewise';
import Bookingtypewise from './components/report/Bookingtypewise';
import Roomtypewiseonline from './components/report/Roomtypewise';

function App() {
  const [user, setUser] = useState();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
    });
  });




  return (
    <div className="wrapper">
      {
        !user ?
          <Router>
            <Routes>
              <Route path='/' element={<Login />} />
              <Route path='/login' element={<Login />} />
              <Route path='/Register' element={<Register />} />
              <Route path='/forgot-password' element={<Forgetpass />} />
            </Routes>
          </Router>
          :
          <>
            <Menu />
            <Header />
            <Router>
              <Routes>
                <Route path='/' element={<Dashboard />} />
                <Route path='/home' element={<Dashboard />} />
                <Route path='/room-types' element={<Roomtypes />} />
                <Route path='/rate-plans' element={<Rateplans />} />
                <Route path='/room-details' element={<Roomdetails />} />
                <Route path='/room-tarrif' element={<Roomtarrif />} />
                <Route path='/room-services' element={<Roomservices />} />
                <Route path='/payment-modes' element={<Paymentmode />} />
                <Route path='/room-type-images' element={<Roomimages />} />
                <Route path='/booking-types' element={<Bookingtypes />} />

                <Route path='/user-mgmt' element={<Usermgmt />} />

                <Route path='/website-rooms' element={<Websiterooms />} />
                <Route path='/website-gallery' element={<Websitegallery />} />

                <Route path='/approvals' element={<Approvals />} />
                <Route path='/view-details/:documentId' element={<Viewdetails />} />

                <Route path='/all-rooms' element={<Allrooms />} />
                <Route path='/live-booking-rooms' element={<Livebookingrooms />} />
                <Route path='/available-rooms' element={<Availablerooms />} />
                <Route path='/cleaning-rooms' element={<Cleaningrooms />} />

                <Route path='/online-bookings/:type' element={<Onlinebookings />} />
                <Route path='/pending-bookings' element={<Pendingbookings />} />
                <Route path='/website-reservation/:webid' element={<Websitereservation />} />
                <Route path='/futurebookings-reservation/:webid' element={<Futurereservation />} />
                <Route path='/checkout-history' element={<Checkouthistory />} />
                <Route path='/room-cleaning' element={<Roomcleanings />} />
                <Route path='/room-cleaned' element={<Roomcleaned />} />
                <Route path='/damage-notes' element={<Damagenotes />} />
                <Route path='/print-invoice/:documentId' element={<Hotelinvoice />} />
                <Route path='/live-bookings' element={<Livebookings />} />
                <Route path='/future-paid-bookings' element={<Advancebooking />} />
                <Route path='/view-future-bookings' element={<Viewadvance />} />
                <Route path='/view-futuredetails/:documentId' element={<Advancedetails />} />

                <Route path='/all-booking-report' element={<Allbookingreport />} />
                <Route path='/receptionist-report' element={<Receptionistreport />} />
                <Route path='/login-details' element={<Logindetails />} />
                <Route path='/user-logs' element={<Userlogs />} />
                <Route path='/paymode-wise-report' element={<Paymodewise />} />
                <Route path='/booking-type-report' element={<Bookingtypewise />} />
                <Route path='/room-type-wise-online-bookings' element={<Roomtypewiseonline />} />



              </Routes>
            </Router>
            <Footer />
          </>
      }

    </div >
  );
}

export default App;
