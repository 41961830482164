import React, { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2';
import { collection, getCountFromServer, getDocs, query, where } from 'firebase/firestore';
import { auth, db } from '../firebase';
import "../App.css";
import Menu from '../panel/Menu';
import moment from 'moment';

function Dashboard() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();
    const [bookedrooms, setbookedrooms] = useState(0);
    const [availablerooms, setavailablerooms] = useState(0);
    const [cleaningrooms, setcleaningrooms] = useState(0);
    const [allrooms, setallrooms] = useState(0);

    const [todayCount, settodayCount] = useState(0);
    const [tommCount, settommCount] = useState(0);
    const [thisMonth, setthisMonth] = useState(0);
    const [nextMonth, setnextMonth] = useState(0);

    const [todayCount1, settodayCount1] = useState(0);
    const [tommCount1, settommCount1] = useState(0);
    const [thisMonth1, setthisMonth1] = useState(0);
    const [nextMonth1, setnextMonth1] = useState(0);

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
        fetchData1()
    });
    }, [userData]);

    const fetchData1 = async () => {
        try {
            if (userData) {
                const today = new Date().toISOString().split('T')[0];
                const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
                const firstDateOfMonth = moment().startOf('month').format('YYYY-MM-DD');
                const lastDateOfMonth = moment().endOf('month').format('YYYY-MM-DD');
                const firstDayOfNextMonth = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
                const lastDayOfNextMonth = moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');
    
                // Creating queries in advance
                const queries = [
                    query(collection(db, 'roomdetails')), // q1: all rooms
                    query(collection(db, 'roomdetails'), where('status', '==', 'Booked')), // q2: booked rooms
                    query(collection(db, 'roomdetails'), where('status', '==', 'Available')), // q3: available rooms
                    query(collection(db, 'roomdetails'), where('status', '==', 'Cleaning')), // q4: cleaning rooms
                    query(collection(db, 'websitebookings'), where('checkindate', '==', today), where('status', '==', 'Paid')), // q5: today's bookings
                    query(collection(db, 'websitebookings'), where('checkindate', '==', tomorrow), where('status', '==', 'Paid')), // q6: tomorrow's bookings
                    query(collection(db, 'websitebookings'), where('checkindate', '>=', firstDateOfMonth), where('checkindate', '<=', lastDateOfMonth), where('status', '==', 'Paid')), // q7: this month's bookings
                    query(collection(db, 'websitebookings'), where('checkindate', '>=', firstDayOfNextMonth), where('checkindate', '<=', lastDayOfNextMonth), where('status', '==', 'Paid')), // q8: next month's bookings
                    query(collection(db, 'advancebookings'), where('checkindate', '==', today), where('status', '==', 'Paid')), // q9: today's advance bookings
                    query(collection(db, 'advancebookings'), where('checkindate', '==', tomorrow), where('status', '==', 'Paid')), // q10: tomorrow's advance bookings
                    query(collection(db, 'advancebookings'), where('checkindate', '>=', firstDateOfMonth), where('checkindate', '<=', lastDateOfMonth), where('status', '==', 'Paid')), // q11: this month's advance bookings
                    query(collection(db, 'advancebookings'), where('checkindate', '>=', firstDayOfNextMonth), where('checkindate', '<=', lastDayOfNextMonth), where('status', '==', 'Paid')) // q12: next month's advance bookings
                ];
    
                // Fetching all data concurrently
                const [
                    snapshot1, snapshot2, snapshot3, snapshot4, snapshot5, snapshot6, snapshot7, snapshot8,
                    snapshot9, snapshot10, snapshot11, snapshot12
                ] = await Promise.all(queries.map(q => getDocs(q)));
    
                // Set states after all data has been fetched
                setallrooms(snapshot1.size);
                setbookedrooms(snapshot2.size);
                setavailablerooms(snapshot3.size);
                setcleaningrooms(snapshot4.size);
                settodayCount(snapshot5.size);
                settommCount(snapshot6.size);
                setthisMonth(snapshot7.size);
                setnextMonth(snapshot8.size);
                settodayCount1(snapshot9.size);
                settommCount1(snapshot10.size);
                setthisMonth1(snapshot11.size);
                setnextMonth1(snapshot12.size);
            }
        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
            console.error(error.message);
        }
    };
    



    return (
        <div>
            <Menu />
            <div className="content-wrapper" style={{ backgroundColor: '#fff' }}>
                <section className="content"><br />
                    <div className="container-fluid">
                        <button className="btn btn-secondary " >|| Dashboard ||</button><br /><br />
                        <div className="row">
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-pink">
                                    <div className="inner">
                                        <h3>{todayCount + todayCount1}</h3>
                                        <p>Today's  Bookings</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-users" />
                                    </div>
                                    <a href="online-bookings/todays" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>

                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-orange">
                                    <div className="inner">
                                        <h3>{tommCount + tommCount1}</h3>
                                        <p>Tommorrow's  Bookings</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-users" />
                                    </div>
                                    <a href="online-bookings/tommorrows" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>

                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-purple">
                                    <div className="inner">
                                        <h3>{thisMonth + thisMonth1}</h3>
                                        <p>This Month  Bookings</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-users" />
                                    </div>
                                    <a href="online-bookings/thismonth" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>

                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-blue">
                                    <div className="inner">
                                        <h3>{nextMonth + nextMonth1}</h3>
                                        <p>Next Month   Bookings</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-users" />
                                    </div>
                                    <a href="online-bookings/nextmonth" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>

                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-6">

                                <div className="small-box bg-danger">
                                    <div className="inner">
                                        <h3>{bookedrooms}</h3>
                                        <p>Live Bookings Rooms</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    <a href="live-booking-rooms" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">

                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{availablerooms}</h3>
                                        <p>Available Rooms</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    <a href="available-rooms" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">

                                <div className="small-box bg-warning">
                                    <div className="inner">
                                        <h3>{cleaningrooms}</h3>
                                        <p>Cleaning Rooms</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    <a href="cleaning-rooms" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">

                                <div className="small-box bg-teal">
                                    <div className="inner">
                                        <h3>{allrooms}</h3>
                                        <p>All Rooms</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add"></i>
                                    </div>
                                    <a href="all-rooms" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>


        </div>
    )
}

export default Dashboard
