import React, { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2';
import { addDoc, collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import "../../App.css";
import Menu from '../../panel/Menu';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import numberToWords from "number-to-words";

function Hotelinvoice() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
            fethUsers()
        });
    });
    const printRef = useRef();
    const fethUsers = async () => {
        try {
            if (userData) {
                if (loadincount) {
                    const docRef = doc(db, "users", userData.uid)
                    const docSnap = await getDoc(docRef)
                    if (docSnap.exists()) {
                        setusername(docSnap.data().username);
                        setloadincount(false)
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    const [loadincount, setloadincount] = useState(true);
    const [userData, setUserData] = useState();
    const { documentId } = useParams();

    const [bookingData, setbookingData] = useState([]);

    const [username, setusername] = useState("");
    const [custname, setcustname] = useState("");
    const [mobile, setmobile] = useState("");
    const [email, setemail] = useState("");
    const [address, setaddress] = useState("");
    const [city, setcity] = useState("");
    const [state, setstate] = useState("");
    const [gender, setgender] = useState("Male");
    const [visitpurpose, setvisitpurpose] = useState("Darshan");
    const [gstname, setgstname] = useState("");
    const [gstno, setgstno] = useState("");
    const [bookingsource, setbookingsource] = useState("Walking");
    const [documenttype, setdocumenttype] = useState("");
    const [idno, setidno] = useState("");
    const [adults, setadults] = useState("");
    const [childs, setchilds] = useState("");
    const [subtotal, setsubtotal] = useState("");
    const [taxes, settaxes] = useState("");
    const [payhistory, setpayhistory] = useState([]);
    const [amountInWords, setAmountInWords] = useState("");

    const [roomtaxable, setroomtaxable] = useState(0);
    const [roomgst, setroomgst] = useState(0);
    const [roomtotal, setroomtotal] = useState(0);

    const [servicetaxable, setservicetaxable] = useState(0);
    const [servicegst, setservicegst] = useState(0);
    const [servicetotal, setservicetotal] = useState(0);

    const [servicedamagetotal, setservicedamagetotal] = useState(0);

    const [restotaxable, setrestotaxable] = useState(0);
    const [restogst, setrestogst] = useState(0);
    const [restototal, setrestototal] = useState(0);

    const [damagetaxable, setdamagetaxable] = useState(0);
    const [damagegst, setdamagegst] = useState(0);
    const [damagetotal, setdamagetotal] = useState(0);

    const [totaltax, settotaltax] = useState(0);
    const [cgst, setcgst] = useState(0);



    useEffect(() => {
        fetchData1()
    }, []);


    const fetchData1 = async () => {
        try {
            const docRef = doc(db, "bookings", documentId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setbookingData(docSnap.data());
                let tax = parseFloat(docSnap.data().totalamount) * 12 / 100;


                let roomtotal = parseFloat(docSnap.data().rooamount) * parseFloat(docSnap.data().noofdays);
                let roomteax = parseFloat(roomtotal) * 12 / 100;
                let taxable = parseFloat(roomtotal) - parseFloat(roomteax)
                setroomtotal(roomtotal.toFixed(2))
                setroomtaxable(taxable.toFixed(2))
                setroomgst(roomteax.toFixed(2));
                console.log(roomteax);


                let servicetotal = parseFloat(docSnap.data().serviceamount);
                let serviceteax = parseFloat(servicetotal) * 12 / 100;
                let sertaxable = parseFloat(servicetotal) - parseFloat(serviceteax)
                setservicetaxable(sertaxable.toFixed(2))
                setservicegst(serviceteax.toFixed(2))
                setservicetotal(servicetotal.toFixed(2))


                let restototal = parseFloat(docSnap.data().restoamount);
                let restoteax = parseFloat(restototal) * 5 / 100;
                let restotaxable = parseFloat(restototal) - parseFloat(restoteax)
                setrestotaxable(restotaxable.toFixed(2))
                setrestogst(restoteax.toFixed(2))
                setrestototal(restototal.toFixed(2))

                let damagetotal = parseFloat(docSnap.data().damageamount);
                let damageteax = parseFloat(damagetotal) * 5 / 100;
                let damagetaxable = parseFloat(damagetotal) - parseFloat(damageteax)
                setdamagetaxable(damagetaxable.toFixed(2))
                setdamagegst(damageteax.toFixed(2))
                setdamagetotal(damagetotal.toFixed(2))

                let servciedamage = parseFloat(servicetotal) + parseFloat(damagetotal)
                setservicedamagetotal(servciedamage.toFixed(2))

                let tottax = parseFloat(roomteax);
                settotaltax(tottax.toFixed(2));
                let cgst = parseFloat(tottax) / 2;
                setcgst(cgst.toFixed(2));

                let subtotal = parseFloat(docSnap.data().totalamount) - parseFloat(tottax);
                setsubtotal(subtotal.toFixed(2));
                settaxes(tax.toFixed(2));
                const words = numberToWords.toWords(docSnap.data().totalamount);
                setAmountInWords(words);

            }



            const q1 = query(collection(db, 'payhistory'), orderBy("createdAt", "asc"), where('bookingid', '==', documentId))
            onSnapshot(q1, async (querySnapshot1) => {
                const combinedData = [];
                let paymode1 = ""
                let paymode2 = ""
                for (const doc1 of querySnapshot1.docs) {
                    const docRef1 = doc(db, "paymentmodes", doc1.data().paymode1)
                    const docSnap1 = await getDoc(docRef1)
                    if (docSnap1.exists()) {
                        paymode1 = docSnap1.data().paymode;
                    } else {
                        paymode1 = ""
                    }

                    const docRef2 = doc(db, "paymentmodes", doc1.data().paymode2)
                    const docSnap2 = await getDoc(docRef2)
                    if (docSnap2.exists()) {
                        paymode2 = docSnap2.data().paymode;
                    } else {
                        paymode2 = ""
                    }

                    const date = doc1.data().createdAt.toDate();
                    const day = date.getDate().toString().padStart(2, '0');
                    const month = (date.getMonth() + 1).toString().padStart(2, '0');
                    const year = date.getFullYear();
                    const hours = date.getHours().toString().padStart(2, '0');
                    const minutes = date.getMinutes().toString().padStart(2, '0');
                    const seconds = date.getSeconds().toString().padStart(2, '0');

                    const createdat = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

                    combinedData.push({
                        id: doc1.id,
                        data: doc1.data(),
                        paymode1: paymode1,
                        paymode2: paymode2,
                        createdat: createdat,
                    });
                }
                setpayhistory(combinedData);
            });




        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }


    const getCustData = () => {
        setcustname(bookingData.customers.custname);
        setmobile(bookingData.customers.mobile);
        setemail(bookingData.customers.email);
        setaddress(bookingData.customers.address);
        setcity(bookingData.customers.city);
        setstate(bookingData.customers.state);
        setgender(bookingData.customers.gender);
        setgstname(bookingData.customers.gstname);
        setgstno(bookingData.customers.gstno);
        setdocumenttype(bookingData.customers.documenttype);
        setidno(bookingData.customers.idno);
        setvisitpurpose(bookingData.visitpurpose);
        setbookingsource(bookingData.bookingsource);
        setadults(bookingData.adults);
        setchilds(bookingData.childs);
    }

    const updateCust = async () => {
        const customerData = {
            custname: custname,
            mobile: mobile,
            email: email,
            address: address,
            city: city,
            state: state,
            gender: gender,
            gstname: gstname,
            gstno: gstno,
            documenttype: documenttype,
            idno: idno,
            uid: userData.uid,
            createdAt: Timestamp.now(),
        };

        await updateDoc(doc(db, "bookings", documentId), {
            customers: customerData,
            bookingsource: bookingsource,
            visitpurpose: visitpurpose,
            adults: adults,
            childs: childs,
        });

        await updateDoc(doc(db, "customers", bookingData.customerId), {
            customers: customerData,
            bookingsource: bookingsource,
            visitpurpose: visitpurpose,
        });
        fetchData1()
        Toast.fire({ icon: "success", title: 'Customer Details Successfully Updated' });

    }

    const handlemobile = (e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, '');
        if (value.length <= 10) {
            setmobile(value);
        }

    }

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    })

    return (
        <div>
            <Menu />
            {
                bookingData.customerId &&
                <div className="content-wrapper">
                    <section className="content mt-2">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="card card-primary card-outline">
                                        <div className="card-body box-profile">
                                            <h3 className="profile-username text-center">{bookingData.customers.custname} </h3>
                                            <p className="text-muted text-center">{bookingData.customers.mobile}</p>
                                         
                                            <ul className="list-group list-group-unbordered mb-3">
                                                <li className="list-group-item">
                                                    <b>Email</b> <span className="float-right">{bookingData.customers.email}</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Address</b> <span className="float-right">{bookingData.customers.city} {bookingData.customers.address} {bookingData.customers.state}</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Gender</b> <span className="float-right">{bookingData.customers.gender}</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>GST Name</b> <span className="float-right">{bookingData.customers.gstname}</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>GST No</b> <span className="float-right">{bookingData.customers.gstno}</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Document Type</b> <span className="float-right">{bookingData.customers.documenttype}</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>ID No</b> <span className="float-right">{bookingData.customers.idno}</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Visit Purpose</b> <span className="float-right">{bookingData.visitpurpose} </span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Booking Source</b> <span className="float-right">{bookingData.bookingsource} </span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Note</b> <span className="float-right">{bookingData.note} </span>
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                    <div className='card'>
                                        <div className='card-body text-center'>
                                            <h6>Visitor Photo</h6>
                                            {
                                                bookingData.visitorPhotoUrl &&
                                                <img src={bookingData.visitorPhotoUrl} alt='img' width="100%" />
                                            }
                                        </div>
                                    </div>
                                    <div className='card'>
                                        <div className='card-body text-center'>
                                            <h6>ID Proof Front</h6>
                                            {
                                                bookingData.idFrontUrl &&
                                                <img src={bookingData.idFrontUrl} alt='img' width="100%" />
                                            }
                                        </div>
                                    </div>
                                    <div className='card'>
                                        <div className='card-body text-center'>
                                            <h6>ID Proof Back</h6>
                                            {
                                                bookingData.idBackUrl &&
                                                <img src={bookingData.idBackUrl} alt='img' width="100%" />
                                            }
                                        </div>
                                    </div>
                                    <div className='card'>
                                        <div className='card-body text-center'>
                                            <h6>Couple Photo</h6>
                                            {
                                                bookingData.couplePhotoUrl &&
                                                <img src={bookingData.couplePhotoUrl} alt='img' width="100%" />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="card card-primary card-outline" ref={printRef}>
                                        <div className='card-body'>
                                            <br />
                                            <div className="border1">
                                                <div className="invoice p-2 mb-3" >
                                                    <div className="row">
                                                        <div className="col-9 text-center">
                                                            <h4>The Sky Land Hotel & Restaurant.</h4>
                                                            <h6>GSTIN No - 27AAWFP8677Q1ZN</h6>
                                                            <b style={{ fontSize: 17 }}>
                                                                Near New Bus Stand, Latur Road, Tuljapur, Dist - Dharashiv, MH - 413601<br />
                                                                Phone: +91 70777779163 | Email:  theskylandhotel@gmail.com<br />
                                                                Website:  www.theskylandhotel.com
                                                            </b>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <h3>TAX INVOICE</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr style={{ borderWidth: 1, borderColor: "black", margin: 0 }} />
                                                <div className="row invoice-info" style={{ padding: 5 }}>
                                                    <div className="col-sm-7 invoice-col ">
                                                        <b style={{ fontSize: 16 }}>To</b><br />
                                                        <table width="100%">
                                                            <tr>
                                                                <td style={{ fontSize: 16 }}><b>Guest Name</b></td>
                                                                <td>: </td>
                                                                <td style={{ textTransform: "capitalize", fontSize: 16, color: "black" }}>&nbsp; {bookingData.customers.custname}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: 16 }}><b>Address</b></td>
                                                                <td>: </td>
                                                                <td style={{ textTransform: "capitalize", fontSize: 16, color: "black" }}>&nbsp; {bookingData.customers.address} , {bookingData.customers.city},   {bookingData.customers.state} </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: 16 }}><b>Phone</b></td>
                                                                <td>: </td>
                                                                <td style={{ textTransform: "capitalize", fontSize: 16, color: "black" }}>&nbsp; {bookingData.customers.mobile}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: 16 }}><b>Email</b></td>
                                                                <td>: </td>
                                                                <td style={{ fontSize: 16, color: "black" }}>&nbsp; {bookingData.customers.email}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: 16 }}><b>GST Name</b></td>
                                                                <td>: </td>
                                                                <td style={{ textTransform: "capitalize", fontSize: 16, color: "black" }}>&nbsp; {bookingData.customers.gstname}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: 16 }}><b>GST No</b></td>
                                                                <td>: </td>
                                                                <td>&nbsp; {bookingData.customers.gstno}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div className="col-sm-5 invoice-col"><br />
                                                        <table width="100%">
                                                            <tr>
                                                                <td style={{ fontSize: 16 }}><b>Invoice No</b></td>
                                                                <td>: </td>
                                                                <td style={{ textTransform: "capitalize", fontSize: 16, color: "black" }}>&nbsp; {bookingData.invoiceno}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: 16 }}><b>Invoice Date</b></td>
                                                                <td>: </td>
                                                                <td style={{ textTransform: "capitalize", fontSize: 16, color: "black" }}>&nbsp; {moment().format('DD/MM/YYYY HH:mm:ss A')}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: 16 }}><b>No Of Rooms</b></td>
                                                                <td>: </td>
                                                                <td style={{ textTransform: "capitalize", fontSize: 16, color: "black" }}>&nbsp; {bookingData.noofrooms} Rooms </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: 16 }}><b>No of Days</b></td>
                                                                <td>: </td>
                                                                <td style={{ textTransform: "capitalize", fontSize: 16, color: "black" }}>&nbsp; {bookingData.noofdays} Days</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: 16 }}><b>Check-In Date</b></td>
                                                                <td>: </td>
                                                                <td style={{ textTransform: "capitalize", fontSize: 16, color: "black" }}>&nbsp; {moment(bookingData.checkindate).format('DD/MM/YYYY')}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: 16 }}><b>Check-Out Date</b></td>
                                                                <td>: </td>
                                                                <td style={{ textTransform: "capitalize", fontSize: 16, color: "black" }}>&nbsp; {moment(bookingData.checkoutdate).format('DD/MM/YYYY')}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: 16 }}><b>No Of Persons</b></td>
                                                                <td>: </td>
                                                                <td>&nbsp; {bookingData.adults} (A) / {bookingData.childs} (C)</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 table-responsive">
                                                        <table className="customers" style={{width:"100%"}}>
                                                            <tbody>
                                                                <tr>
                                                                    <th>Room Type</th>
                                                                    <th>Room No</th>
                                                                    <th>Plan Name</th>
                                                                    {/* <th style={{ textAlign: "right" }}>Room Amount</th> */}
                                                                    {/* <th style={{ textAlign: "right" }}>CGST</th> */}
                                                                    {/* <th style={{ textAlign: "right" }}>SGST</th> */}
                                                                    <th style={{ textAlign: "right" }}>Rate</th>
                                                                    <th style={{ textAlign: "right" }}>No Of Day</th>
                                                                    <th style={{ textAlign: "right" }}>Room Amount</th>
                                                                    <th style={{ textAlign: "right" }}>Discount</th>
                                                                    <th style={{ textAlign: "right" }}>Total <i>(Inc Tax)</i></th>
                                                                </tr>
                                                                {
                                                                    bookingData.rooms.map((item, index) => {
                                                                        let diaamount = (parseFloat(item.amount) - parseFloat(item.roomrate)) * parseFloat(bookingData.noofdays);
                                                                        let amount = parseFloat(item.amount);
                                                                        let roomrate = parseFloat(item.roomrate) * parseFloat(bookingData.noofdays);
                                                                        let totall = parseFloat(item.amount) * parseFloat(bookingData.noofdays);
                                                                        // let gstamount = parseFloat(amount) * 12 / 100;
                                                                        // let rooamount = parseFloat(amount) - parseFloat(gstamount);
                                                                        // let cgst = parseFloat(gstamount) / 2;
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{item.roomtypename}</td>
                                                                                <td>
                                                                                    {item.roomname}
                                                                                    {item.status === 'Exchanged' && " (Exchanged)"}
                                                                                </td>
                                                                                <td>{item.plananme}</td>
                                                                                {/* <td style={{ textAlign: "right" }}>{rooamount.toFixed(2)}</td> */}
                                                                                {/* <td style={{ textAlign: "right" }}>{cgst.toFixed(2)}</td> */}
                                                                                {/* <td style={{ textAlign: "right" }}>{cgst.toFixed(2)}</td> */}
                                                                                <td style={{ textAlign: "right" }}>{amount.toFixed(2)}</td>
                                                                                <td style={{ textAlign: "right" }}>{bookingData.noofdays} Day</td>
                                                                                <td style={{ textAlign: "right" }}>{totall.toFixed(2)}</td>
                                                                                <td style={{ textAlign: "right" }}>{diaamount.toFixed(2)} ({parseFloat(item.discount)}%)</td>
                                                                                <td style={{ textAlign: "right" }}>{roomrate.toFixed(2)}</td>
                                                                            </tr>
                                                                        )
                                                                    }

                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <table className='customers' width="100%">
                                                            <tr>
                                                                <td width="50%">
                                                                    <center>
                                                                        <h5>BANK DETAILS</h5>
                                                                        <h6>Bank Name : HDFC Bank Ltd</h6>
                                                                        <h6>Account No : 50200078560085</h6>
                                                                        <h6>Ifsc Code : HDFC0009305</h6>
                                                                        <h6>Account Name : PAGE REALTORS</h6>
                                                                        <h6>Branch : TULJAPUR</h6>
                                                                    </center>
                                                                    {/* <table className='customers' style={{ width: "100%" }}>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>Paymode </th>
                                                                                <th>Amount </th>
                                                                                <th>Paymode </th>
                                                                                <th>Amount </th>
                                                                                <th>Datetime</th>
                                                                            </tr>

                                                                            {
                                                                                payhistory.map((item, index) =>
                                                                                    <tr key={index}>
                                                                                        <td>{item.paymode1}</td>
                                                                                        <td>{item.data.amount1}</td>
                                                                                        <td>{item.paymode2}</td>
                                                                                        <td>{item.data.amount2}</td>
                                                                                        <td>{item.createdat}</td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        </tbody>
                                                                    </table> */}
                                                                    {/*  */}
                                                                    {/* <p style={{ textTransform: "capitalize", fontSize: 16, color: "black", fontWeight: "700" }}> </p> */}
                                                                </td>
                                                                <td width="50%" style={{ padding: 0 }}>
                                                                    <table className='customers' style={{ width: "100%" }}>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th style={{ width: '50%' }}>Subtotal</th>
                                                                                <td style={{ textAlign: "right" }}>{subtotal}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Total CGST(6%)</th>
                                                                                <td style={{ textAlign: "right" }}>{cgst}</td>
                                                                            </tr>
                                                                            {
                                                                                bookingData.customers.state === 'Maharashtra' ?
                                                                                    <>
                                                                                        <tr>
                                                                                            <th>Total SGST(6%)</th>
                                                                                            <td style={{ textAlign: "right" }}>{cgst}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Total IGST</th>
                                                                                            <td style={{ textAlign: "right" }}>0.00</td>
                                                                                        </tr>
                                                                                    </> :
                                                                                    <>
                                                                                        <tr>
                                                                                            <th>Total SGST(0)</th>
                                                                                            <td style={{ textAlign: "right" }}>0.00</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th>Total IGST(6%)</th>
                                                                                            <td style={{ textAlign: "right" }}>{cgst}</td>
                                                                                        </tr>
                                                                                    </>
                                                                            }

                                                                            <tr>
                                                                                <th>Room Amount</th>
                                                                                <td style={{ textAlign: "right" }}>{roomtotal}</td>
                                                                            </tr>
                                                                            {
                                                                                restototal > 0 &&
                                                                                <tr>
                                                                                    <th>Food Amount</th>
                                                                                    <td style={{ textAlign: "right" }}>{restototal}</td>
                                                                                </tr>
                                                                            }
                                                                            {
                                                                                servicetotal > 0 &&
                                                                                <tr>
                                                                                    <th>Extra Service</th>
                                                                                    <td style={{ textAlign: "right" }}>{servicetotal}</td>
                                                                                </tr>
                                                                            }
                                                                            {
                                                                                damagetotal > 0 &&
                                                                                <tr>
                                                                                    <th> Damage Amount</th>
                                                                                    <td style={{ textAlign: "right" }}>{damagetotal}</td>
                                                                                </tr>
                                                                            }
                                                                            <tr>
                                                                                <th>Round Off</th>
                                                                                <td style={{ textAlign: "right" }}>{(parseFloat(bookingData.totalamount.toFixed(2)) - parseFloat(bookingData.totalamount.toFixed(0))).toFixed(2)}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Grand Total</th>
                                                                                <td style={{ textAlign: "right" }}>{bookingData.totalamount.toFixed(0)}.00</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Paid</th>
                                                                                <td style={{ textAlign: "right" }}>{bookingData.advance.toFixed(2)}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Balance</th>
                                                                                <td style={{ textAlign: "right" }}>{bookingData.remaining.toFixed(2)}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}><h6 style={{ textTransform: "capitalize" }}>Total in Words  : {amountInWords} Rupees Only  </h6> </td>
                                                            </tr>
                                                        </table>
                                                        {/* <table className="customers mt-1">
                                                            <tbody>
                                                                <tr>
                                                                    <th>Sr No</th>
                                                                    <th>Particular</th>
                                                                    <th style={{ textAlign: "right" }}>Amount</th>
                                                                    <th style={{ textAlign: "right" }}>Qty</th>
                                                                    <th style={{ textAlign: "right" }}>Taxable</th>
                                                                    <th style={{ textAlign: "right" }}>CGST</th>
                                                                    <th style={{ textAlign: "right" }}>SGST</th>
                                                                    <th style={{ textAlign: "right" }}>Total</th>
                                                                </tr>
                                                                {
                                                                    roomtotal > 0 &&
                                                                    <tr>
                                                                        <td>1</td>
                                                                        <td>Room  Charges</td>
                                                                        <td style={{ textAlign: "right" }}>{roomtaxable}</td>
                                                                        <td style={{ textAlign: "right" }}>1</td>
                                                                        <td style={{ textAlign: "right" }}>{roomtaxable}</td>
                                                                        <td style={{ textAlign: "right" }}>{roomgst / 2} (6%) </td>
                                                                        <td style={{ textAlign: "right" }}>{roomgst / 2} (6%) </td>
                                                                        <td style={{ textAlign: "right" }}>{roomtotal}</td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    servicetotal > 0 &&
                                                                    <tr>
                                                                        <td>2</td>
                                                                        <td>Room Service Charges</td>
                                                                        <td style={{ textAlign: "right" }}>{servicetaxable}</td>
                                                                        <td style={{ textAlign: "right" }}>1</td>
                                                                        <td style={{ textAlign: "right" }}>{servicetaxable}</td>
                                                                        <td style={{ textAlign: "right" }}>{servicegst / 2} (6%) </td>
                                                                        <td style={{ textAlign: "right" }}>{servicegst / 2} (6%) </td>
                                                                        <td style={{ textAlign: "right" }}>{servicetotal}</td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    restototal > 0 &&
                                                                    <tr>
                                                                        <td>3</td>
                                                                        <td>Restaurent Charges</td>
                                                                        <td style={{ textAlign: "right" }}>{restotaxable}</td>
                                                                        <td style={{ textAlign: "right" }}>1</td>
                                                                        <td style={{ textAlign: "right" }}>{restotaxable}</td>
                                                                        <td style={{ textAlign: "right" }}>{restogst / 2} (0%)</td>
                                                                        <td style={{ textAlign: "right" }}>{restogst / 2} (2.5%)</td>
                                                                        <td style={{ textAlign: "right" }}>{restototal}</td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    damagetotal > 0 &&
                                                                    <tr>
                                                                        <td>4</td>
                                                                        <td>Damage Charges</td>
                                                                        <td style={{ textAlign: "right" }}>{damagetaxable}</td>
                                                                        <td style={{ textAlign: "right" }}>1</td>
                                                                        <td style={{ textAlign: "right" }}>{damagetaxable}</td>
                                                                        <td style={{ textAlign: "right" }}>{damagegst / 2} (0%)</td>
                                                                        <td style={{ textAlign: "right" }}>{damagegst / 2} (0%)</td>
                                                                        <td style={{ textAlign: "right" }}>{damagetotal}</td>
                                                                    </tr>
                                                                }

                                                            </tbody>
                                                        </table> */}
                                                    </div>
                                                </div>
                                                <div className="row mt-1">
                                                    <div className="col-6">
                                                        {/* <b>Payment Details:</b> */}

                                                        <br />

                                                    </div>
                                                    <div className="col-5">
                                                        <div className="table-responsive">

                                                        </div>
                                                    </div>

                                                </div>
                                                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                                                <div className='row'>
                                                    <div className='col-sm-4'>
                                                        <center>
                                                            <h6 style={{ color: "black" }}>Receiver Signature</h6>
                                                        </center>
                                                    </div>
                                                    <div className='col-sm-4'></div>
                                                    <div className='col-sm-4'>
                                                        <center>
                                                            <h6 style={{ color: "black" }}>The Sky Land Hotel & Restaurant</h6>
                                                            <h6 style={{ color: "black" }}>Tuljapur</h6>
                                                        </center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <center>
                                        <button className='btn btn-success' onClick={handlePrint}>Print Invoice</button>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </section >


                    <div className="modal fade" id="custModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Update Customer Details</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' color="secondary" value={custname} onChange={(e) => setcustname(e.target.value)} label="Customer Name" type='text' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' color="secondary" value={mobile} onChange={handlemobile} label="Mobile" type='tel' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' value={email} onChange={(e) => setemail(e.target.value)} label="Email" type='email' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' color="secondary" value={address} onChange={(e) => setaddress(e.target.value)} label="Address" type='text' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' color="secondary" value={city} onChange={(e) => setcity(e.target.value)} label="City" type='text' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' value={state} onChange={(e) => setstate(e.target.value)} label="State" type='text' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <FormControl className='form-control'>
                                                <InputLabel id="gender" style={{ backgroundColor: "white" }}>Gender</InputLabel>
                                                <Select labelId='gender' size='small' value={gender} onChange={(e) => setgender(e.target.value)} label="Age">
                                                    <MenuItem value='Male'>Male</MenuItem>
                                                    <MenuItem value='Female'>Female</MenuItem>
                                                    <MenuItem value='Other'>Other</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <FormControl className='form-control'>
                                                <InputLabel id="visitpurpose" style={{ backgroundColor: "white" }}>Visit Purpose</InputLabel>
                                                <Select labelId='visitpurpose' size='small' value={visitpurpose} onChange={(e) => setvisitpurpose(e.target.value)} label="Age">
                                                    <MenuItem value='Darshan'>Darshan</MenuItem>
                                                    <MenuItem value='Tourist'>Tourist</MenuItem>
                                                    <MenuItem value='Business'>Business</MenuItem>
                                                    <MenuItem value='Official'>Official</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' value={gstname} onChange={(e) => setgstname(e.target.value)} label="GST Name" type='text' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' value={gstno} onChange={(e) => setgstno(e.target.value)} label="GST No" type='text' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <FormControl className='form-control'>
                                                <InputLabel id="bookingsource" style={{ backgroundColor: "white" }}>Booking Source</InputLabel>
                                                <Select labelId='bookingsource' size='small' value={bookingsource} onChange={(e) => setbookingsource(e.target.value)} label="Age">
                                                    <MenuItem value='Walking'>Walking</MenuItem>
                                                    <MenuItem value='Website'>Website</MenuItem>
                                                    <MenuItem value='OTA'>OTA</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' color="secondary" value={documenttype} onChange={(e) => setdocumenttype(e.target.value)} label="Document Type" type='text' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' color="secondary" value={idno} onChange={(e) => setidno(e.target.value)} label="ID No" type='text' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' color="secondary" value={adults} onChange={(e) => setadults(e.target.value)} label="Adults" type='text' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' color="secondary" value={childs} onChange={(e) => setchilds(e.target.value)} label="Childs" type='text' size='small' />
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-success" onClick={updateCust} >Submit</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >
            }



        </div >

    )
}
const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Ensure the overlay is on top
};

export default Hotelinvoice
