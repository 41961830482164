import React, { useEffect, useState, } from 'react'
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import "../App.css";
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore'

export default function Bookingtypes() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();
    const [loadincount, setloadincount] = useState(true);

    const [bookingtypes, setbookingtypes] = useState([]);
    const [searchText, setsearchText] = useState("");

    const [bookingtype, setbookingtype] = useState("");
    const [status, setstatus] = useState("Active");

    const [updocid, setupdocid] = useState("");
    const [upbookingtype, setupbookingtype] = useState("");
    const [upstatus, setupstatus] = useState("");

    useEffect(() => {
        if (searchText === '') {
            fetchData1()
        } else {
            fetchData2()
        }
    }, [searchText]);



    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
            fetchPropid()
        });
    });

    const fetchPropid = async () => {
        if (userData) {
            if (loadincount) {
                const docRef = doc(db, "users", userData.uid)
                const docSnap = await getDoc(docRef)
                if (docSnap.exists()) {
                    fetchData1();
                    setloadincount(false)
                }
            }
        }
    }

    const fetchData1 = async () => {
        try {
            const q = query(collection(db, 'bookingtypes'), orderBy('createdAt', 'asc'))
            onSnapshot(q, (querySnapshot) => {
                setbookingtypes(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });
        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const fetchData2 = async () => {
        try {
            const filteredData = bookingtypes.filter((item) =>
                item.data.bookingtype.toUpperCase().includes(searchText.toUpperCase())
            );
            setbookingtypes(filteredData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    const handleSubmit = async () => {
        try {
            await addDoc(collection(db, "bookingtypes"), {
                bookingtype: bookingtype,
                status: status,
                uid: userData.uid,
                createdAt: Timestamp.now(),
            });

            setbookingtype("");
            setstatus("Active");
            fetchData1();

            Toast.fire({
                icon: "success",
                title: 'Booking Type Added Successfully!!'
            });
        } catch (err) {
            Toast.fire({
                icon: "error",
                title: 'Error in Room Service Adding!!'
            });

            console.log(err.message);
        }
    }

    const handleDelete = async (docid, imgLink) => {
        const taskDocRef = doc(db, 'bookingtypes', docid)
        await deleteDoc(taskDocRef)
        Toast.fire({
            icon: "success",
            title: 'Booking Type Deleted Successfully!!'
        });
        fetchData1();
    }

    const handleEdit = (id, bookingtype, status) => {
        setupdocid(id);
        setupbookingtype(bookingtype);
        setupstatus(status);
    }

    const handleUpdate = async () => {
        if (!upbookingtype) {
            Toast.fire({
                icon: "error",
                title: 'Please Enter Required Fields!!'
            });
            return;
        }

        await updateDoc(doc(db, "bookingtypes", updocid), {
            bookingtype: upbookingtype,
            status: upstatus,
        });
        Toast.fire({
            icon: "success",
            title: 'Booking Type Updated Successfully!!'
        });

        fetchData1();
    }


    return (
        <div>
            <div className="content-wrapper">
                <br />

                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ float: "right", textTransform: "uppercase" }} >|| Booking Type  Mgmt ||</button>
                        <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#insert-modal">Add Booking Type</button><br /><br />
                        <div className="card card-primary card-outline ex1">
                            <div className=" p-1">
                                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                                    className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
                            </div>
                            <div className="card-body table-responsive p-0" >

                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Booking Type </th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            bookingtypes.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.data.bookingtype}</td>
                                                    <td>{item.data.status}</td>
                                                    <td>
                                                        <a onClick={() => handleEdit(item.id, item.data.bookingtype, item.data.status)} href='#0' data-toggle="modal" data-target="#text-modal1" className="btn btn-sm btn-success" > <i className="fa fa-edit"></i></a>&nbsp;
                                                        <a onClick={() => { if (window.confirm('Are u sure?')) { handleDelete(item.id, item.data.imageurl) }; }} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a>&nbsp;
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}

            <div className="modal fade" id="insert-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Booking Type</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>  Booking Type </label>
                                    <input type="text" value={bookingtype} onChange={(e) => setbookingtype(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6 ">
                                    <label>Status</label>
                                    <select className='form-control' value={status} onChange={(e) => setstatus(e.target.value)}>
                                        <option value="Active">Active</option>
                                        <option value="Deactive">Deactive</option>
                                    </select>
                                </div>

                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={() => handleSubmit()} className="btn btn-primary" data-dismiss="modal">Save </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="text-modal1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Booking Type</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>  Booking Type </label>
                                    <input type="text" value={upbookingtype} onChange={(e) => setupbookingtype(e.target.value)} className="form-control" />
                                </div>

                                <div className="col-sm-6 ">
                                    <label>Status</label>
                                    <select className='form-control' value={upstatus} onChange={(e) => setupstatus(e.target.value)}>
                                        <option value="Active">Active</option>
                                        <option value="Deactive">Deactive</option>
                                    </select>
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={() => handleUpdate()} className="btn btn-primary" data-dismiss="modal">Update </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
