import React, { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2';
import { addDoc, collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import "../../App.css";
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import Menu from '../../panel/Menu';

function Paymodewise() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
        });
    });
    const printRef = useRef();
    const [loading, setloading] = useState(false);
    const [fromDate, setfromDate] = useState("");
    const [toDate, settoDate] = useState("");
    const [total, settotal] = useState(0);
    const [paymodes, setpaymodes] = useState([]);



    const getReport = async () => {

        if (!fromDate || !toDate) {
            Toast.fire({ icon: "error", title: 'Please select From Date & To Date & Receptionist' });
            return
        }
        setloading(false)
        setpaymodes([])
        const today = new Date(fromDate);
        today.setHours(0, 0, 0, 0);

        const tomorrow = new Date(toDate);
        tomorrow.setDate(tomorrow.getDate() + 1);


        const q2 = query(collection(db, 'paymentmodes'));
        onSnapshot(q2, async (querySnapshot1) => {
            const combinedData = [];
            let total = 0;
            for (const doc1 of querySnapshot1.docs) {
                const q1 = query(collection(db, 'payhistory'), where('paymode1', '==', doc1.id), where('createdAt', '>=', Timestamp.fromDate(today)), where('createdAt', '<', Timestamp.fromDate(tomorrow)));
                const querySnapshot = await getDocs(q1);
                const total1 = querySnapshot.docs.reduce((sum, doc) => sum + (doc.data().amount1 || 0), 0);

                const q2 = query(collection(db, 'payhistory'), where('paymode2', '==', doc1.id), where('createdAt', '>=', Timestamp.fromDate(today)), where('createdAt', '<', Timestamp.fromDate(tomorrow)));
                const querySnapshot1 = await getDocs(q2);
                const total2 = querySnapshot1.docs.reduce((sum, doc) => sum + (doc.data().amount2 || 0), 0);

                let newTotal = parseFloat(total1) + parseFloat(total2)
                total = parseFloat(newTotal) + parseFloat(total);

                combinedData.push({
                    id: doc1.id,
                    paymode: doc1.data().paymode,
                    totalamount: newTotal
                });
            }
            settotal(total)
            setpaymodes(combinedData);
        });

        setloading(true)
    }

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    })

    const handleFromDate = (e) => {
        setfromDate(e.target.value)
        setloading(false)
    }
    const handleToDate = (e) => {
        settoDate(e.target.value)
        setloading(false)
    }

    return (
        <div>
            <Menu />
            <div className="content-wrapper">
                <section className="content mt-2">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " >|| Paymode Wise Report ||</button><br /><br />
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary card-outline">
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-sm-3'>
                                                <label>From Date</label>
                                                <input type='date' value={fromDate} onChange={handleFromDate} className='form-control' />
                                            </div>
                                            <div className='col-sm-3'>
                                                <label>To Date</label>
                                                <input type='date' value={toDate} onChange={handleToDate} className='form-control' />
                                            </div>
                                            <div className='col-sm-3'>
                                                <br />
                                                <button className='btn btn-info' onClick={getReport}>Get Report</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    loading &&
                                    <>
                                        <div className="card card-primary card-outline" ref={printRef}>
                                            <div className="card-body">
                                                <div className="row invoice-info">
                                                    <div className="col-sm-12 invoice-col text-center">
                                                        <address>
                                                            <h4>The Sky Land Hotel & Restaurant.</h4>
                                                            Near New Bus Stand, Latur Road, Tuljapur, Dist - Dharashiv, MH - 413601<br />
                                                            Phone: +91 70777779163 |
                                                            Email:  theskylandhotel@gmail.com<br />
                                                            Website:  www.theskylandhotel.com
                                                        </address>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <table className='customers2 '>
                                                        <tr>
                                                            <th style={{ textAlign: "center" }}>From Date : {moment(fromDate).format('DD/MM/YYYY')}  </th>
                                                            <th style={{ textAlign: "center" }}><h5 style={{ textTransform: "uppercase" }}>PAYMODE WISE  REPORT </h5></th>
                                                            <th style={{ textAlign: "center" }}>To Date : {moment(toDate).format('DD/MM/YYYY')}</th>
                                                        </tr>
                                                    </table>

                                                    <table className='customers2'>
                                                        <thead>
                                                          
                                                            <tr>
                                                                <th>Paymodes</th>
                                                                <th>Amount</th>
                                                            </tr>
                                                            {
                                                                paymodes.map((item, index) =>
                                                                    <tr key={index}>
                                                                        <td>{item.paymode}</td>
                                                                        <td>{item.totalamount}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                            <tr>
                                                                <td>Total</td>
                                                                <td>{total}</td>
                                                            </tr>
                                                        </thead>
                                                    </table><br />

                                                </div>
                                            </div>

                                        </div>
                                        <center>
                                            <button className='btn btn-success' onClick={handlePrint}>Print Report</button>
                                        </center>
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </section >




            </div>



        </div >

    )
}

export default Paymodewise
