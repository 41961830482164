import React, { useState, useEffect } from 'react'
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

function Menu() {
    const [userData, setUserData] = useState();
    const [loadincount, setloadincount] = useState(true);
    const [username, setusername] = useState("");
    const [role, setrole] = useState("");

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };


    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
            fetchData1()
        });
    });

    const fetchData1 = async () => {
        try {
            if (userData) {
                if (loadincount) {
                    const docRef = doc(db, "users", userData.uid)
                    const docSnap = await getDoc(docRef)
                    if (docSnap.exists()) {
                        setrole(docSnap.data().role);
                        setusername(docSnap.data().username);
                        setloadincount(false)
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <div>
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                {/* Brand Logo */}
                <a href="index3.html" className="brand-link">
                    <img src={window.location.origin + '/user.jpg'} alt="A" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                    <span className="brand-text font-weight-light">{username} </span>
                </a>
                <div className="sidebar">
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            <li className="nav-item">
                                <a href="/home" className="nav-link">
                                    <i className="nav-icon fa fa-home" />
                                    <p>Home</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/approvals" className="nav-link">
                                    <i className="nav-icon fa fa-home" />
                                    <p>Approvals</p>
                                </a>
                            </li>
                            <li className="nav-header" style={{ fontWeight: "700" }}>Report</li>
                            <li className="nav-item">
                                <a href="/room-type-wise-online-bookings" className="nav-link">
                                    <i className="nav-icon fa fa-file" />
                                    <p>Room Type Wise Online </p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/receptionist-report" className="nav-link">
                                    <i className="nav-icon fa fa-file" />
                                    <p>Receptionist Report</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/all-booking-report" className="nav-link">
                                    <i className="nav-icon fa fa-file" />
                                    <p>All Booking Report</p>
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                <a href="/paymode-wise-report" className="nav-link">
                                    <i className="nav-icon fa fa-file" />
                                    <p>Payment Mode Wise</p>
                                </a>
                            </li> */}
                            <li className="nav-item">
                                <a href="/booking-type-report" className="nav-link">
                                    <i className="nav-icon fa fa-file" />
                                    <p>Booking Type Wise</p>
                                </a>
                            </li>

                            <li className="nav-item">
                                <a href="/user-logs" className="nav-link">
                                    <i className="nav-icon fa fa-file" />
                                    <p>User Logs</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/login-details" className="nav-link">
                                    <i className="nav-icon fa fa-file" />
                                    <p>Login Details</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#0" className="nav-link">
                                    <i className="nav-icon fa fa-file" />
                                    <p>Events Report</p>
                                </a>
                            </li>
                            <li className="nav-header" style={{ fontWeight: "700" }}>Reservations</li>
                            <li className="nav-item">
                                <a href="/live-bookings" className="nav-link">
                                    <i className="nav-icon fa fa-file" />
                                    <p>Live Bookings</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/checkout-history" className="nav-link">
                                    <i className="nav-icon fa fa-file" />
                                    <p>Checkout History</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/damage-notes" className="nav-link">
                                    <i className="nav-icon fa fa-file" />
                                    <p>Damage Notes</p>
                                </a>
                            </li>

                            <li className="nav-item">
                                <a href="/pending-bookings" className="nav-link">
                                    <i className="nav-icon fa fa-file" />
                                    <p>Pending Bokings Notes</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/room-cleaning" className="nav-link">
                                    <i className="nav-icon fa fa-remove" />
                                    <p>Cleaning Rooms</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/room-cleaned" className="nav-link">
                                    <i className="nav-icon fa fa-check" />
                                    <p>Cleaned Rooms</p>
                                </a>
                            </li>

                            <li className="nav-header" style={{ fontWeight: "700" }}>OTA Mgmt</li>
                            <li className="nav-item">
                                <a href="/website-rooms" className="nav-link">
                                    <i className="nav-icon fa fa-globe" />
                                    <p>Website Rooms</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/website-gallery" className="nav-link">
                                    <i className="nav-icon fa fa-globe" />
                                    <p>Website Gallery</p>
                                </a>
                            </li>
                            <li className="nav-header" style={{ fontWeight: "700" }}>Master</li>
                            <li className="nav-item">
                                <a href="/room-types" className="nav-link">
                                    <i className="nav-icon fa fa-book" />
                                    <p>Room Types</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/room-type-images" className="nav-link">
                                    <i className="nav-icon fa fa-book" />
                                    <p>Room Types Images</p>
                                </a>
                            </li>

                            <li className="nav-item">
                                <a href="/room-details" className="nav-link">
                                    <i className="nav-icon fa fa-table" />
                                    <p> Room Details</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/rate-plans" className="nav-link">
                                    <i className="nav-icon fa fa-pencil" />
                                    <p> Rate Plans</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/room-tarrif" className="nav-link">
                                    <i className="nav-icon fa fa-money" />
                                    <p> Room Tarrifs</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/room-services" className="nav-link">
                                    <i className="nav-icon fa fa-cube" />
                                    <p> Room Services</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/payment-modes" className="nav-link">
                                    <i className="nav-icon fa fa-credit-card" />
                                    <p> Payment Modes</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/booking-types" className="nav-link">
                                    <i className="nav-icon fa fa-globe" />
                                    <p> Booking Types</p>
                                </a>
                            </li>
                            <li className="nav-header" style={{ fontWeight: "700" }}>User Mgmt</li>
                            <li className="nav-item">
                                <a href="/user-mgmt" className="nav-link">
                                    <i className="nav-icon fa fa-file" />
                                    <p>User Mgmt</p>
                                </a>
                            </li>
                            <li className="nav-header" style={{ fontWeight: "700" }}>Event Mgmt</li>
                            <li className="nav-item">
                                <a href="#0" className="nav-link">
                                    <i className="nav-icon fa fa-bullseye" />
                                    <p>Event Details</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#0" className="nav-link">
                                    <i className="nav-icon fa fa-bullseye" />
                                    <p>Upcoming Events</p>
                                </a>
                            </li>


                        </ul>
                    </nav>
                </div>
            </aside>
        </div>
    )
}
export default Menu