import React, { useEffect, useState, } from 'react'
import Swal from 'sweetalert2';
import { addDoc, collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import "../../App.css";
import Menu from '../../panel/Menu';
import moment from 'moment';
import { useParams } from 'react-router-dom';

function Pendingbookings() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const { type } = useParams();
    const [webbookings, setwebbookings] = useState([]);
    const [userData, setUserData] = useState();
    const [loadincount, setloadincount] = useState(true);
    const [searchText, setsearchText] = useState("");
    const [note, setnote] = useState("");
    const [bookingId, setbookingId] = useState("");


    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
            fethUsers()
        });
    });

    const fethUsers = async () => {
        try {
            if (userData) {
                if (loadincount) {
                    const docRef = doc(db, "users", userData.uid)
                    const docSnap = await getDoc(docRef)
                    if (docSnap.exists()) {
                        setloadincount(false)
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (searchText === '') {
            fetchData1()
        } else {
            fetchData2()
        }
    }, [searchText]);



    const fetchData1 = async () => {
        try {
            const q1 = query(collection(db, 'websitebookings'), where('status', '==', 'Cancled'));
            onSnapshot(q1, (querySnapshot) => {
                setwebbookings(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });


        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const fetchData2 = async () => {
        try {
            const filteredData = webbookings.filter((item) =>
                item.data.name.toUpperCase().includes(searchText.toUpperCase())
            );
            setwebbookings(filteredData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const transferPaid = async (webid) => {
        const docRef = doc(db, "websitebookings", webid)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            let noofrooms = parseFloat(docSnap.data().noofroom)
            for (let i = 0; i < noofrooms; i++) {
                await addDoc(collection(db, 'checkindetails'), {
                    webid: webid,
                    checkindate: docSnap.data().checkindate,
                    checkoutdate: docSnap.data().checkoutdate,
                    roomtypeid: docSnap.data().rtypeid,
                    status: 'Paid',
                });
            }

            await updateDoc(doc(db, "websitebookings", webid), {
                status: "Paid",
            });
            Toast.fire({ icon: "success", title: 'Room Successfully Transfered to Paid' });
            fetchData1()
        }

    }

    const storeNote = async () => {
        if (bookingId || note) {
            await updateDoc(doc(db, "websitebookings", bookingId), {
                status: "Cancled",
                note: note,
            });
            Toast.fire({ icon: "success", title: 'Booking Note added Successfully' });
            fetchData1()
        }
        else {
            Toast.fire({ icon: "error", title: 'Please enter Note' });
        }
    }

    return (
        <div>
            <Menu />
            <div className="content-wrapper" style={{ backgroundColor: '#fff' }}>
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ textTransform: "uppercase" }} >||  Pending Bookings ||</button>
                        <div className="card card-primary card-outline mt-2">
                            <div className="card-body table-responsive" >
                                <input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)} className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th> Name </th>
                                            <th>Mobile </th>
                                            <th>Checkin  </th>
                                            <th>Checkout  </th>
                                            <th>Rooms </th>
                                            <th>Adults </th>
                                            <th>Room Type  </th>
                                            <th style={{ textAlign: "right" }}>Total</th>
                                            <th style={{ textAlign: "right" }}>Discount</th>
                                            <th style={{ textAlign: "right" }}>Paid</th>
                                            <th style={{ textAlign: "right" }}>Remaining</th>
                                            <th>Note</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            webbookings.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.data.name}</td>
                                                    <td>{item.data.phone} </td>
                                                    <td>{moment(item.data.checkindate).format('DD/MM/YYYY')}</td>
                                                    <td>{moment(item.data.checkoutdate).format('DD/MM/YYYY')}</td>
                                                    <td>{item.data.noofroom} Rooms</td>
                                                    <td>{item.data.adult} Persons</td>
                                                    <td>{item.data.roomtype} <br /> {item.data.planname} </td>
                                                    <td style={{ textAlign: "right" }}>{item.data.total}</td>
                                                    <td style={{ textAlign: "right" }}>{item.data.discountamount} ({item.data.discount}%)</td>
                                                    <td style={{ textAlign: "right" }}>{item.data.advance}</td>
                                                    <td style={{ textAlign: "right" }}>{item.data.remaining}</td>
                                                    <td>{item.data.note} </td>

                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>


                    </div>
                </section>
            </div>


            <div className="modal fade" id="approvalModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add Note</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-sm-8'>
                                    <label>Note</label>
                                    <textarea className='form-control' value={note} onChange={(e) => setnote(e.target.value)} placeholder='Enter note..'></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" data-dismiss="modal" className="btn btn-success" onClick={storeNote}>Add Note</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pendingbookings
