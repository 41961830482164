import React, { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2';
import { auth, db } from '../../firebase';
import "../../App.css";
import { collection, Timestamp, query, onSnapshot, getDoc, deleteDoc, doc, setDoc, updateDoc, where } from 'firebase/firestore'
import { createUserWithEmailAndPassword, EmailAuthProvider, reauthenticateWithCredential, signInWithEmailAndPassword, updatePassword } from 'firebase/auth';

export default function Usermgmt() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [loadingCount, setloadingCount] = useState(true);
    const [userData, setUserData] = useState();
    const [propusers, setpropusers] = useState([]);
    const [searchText, setsearchText] = useState("");

    const [loginemail, setloginemail] = useState("");
    const [loginpass, setloginpass] = useState("");

    const [username, setusername] = useState("");
    const [mobile, setmobile] = useState("");
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [role, setrole] = useState("Receptionist");
    const [status, setstatus] = useState("Active");

    const [updocid, setupdocid] = useState("");
    const [upusername, setupusername] = useState("");
    const [upmobile, setupmobile] = useState("");
    const [uprole, setuprole] = useState("");
    const [upstatus, setupstatus] = useState("");

    const [useremail, setuseremail] = useState("");
    const [userpass, setuserpass] = useState("");
    const [upPassword, setupPassword] = useState("");


    useEffect(() => {
        if (searchText === '') {
            fetchData1()
        } else {
            fetchData2()
        }
    }, [searchText]);



    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
            fetchData1();
        });
    });

    const fetchData1 = async () => {
        try {
            if (loadingCount) {
                if (userData) {
                    const docRef = doc(db, "users", userData.uid)
                    const docSnap = await getDoc(docRef)
                    if (docSnap.exists()) {
                        setloginemail(docSnap.data().email);
                        setloginpass(docSnap.data().password);
                    }
                    setloadingCount(false)
                }
            }

            const q1 = query(collection(db, 'users'))
            onSnapshot(q1, async (querySnapshot1) => {
                const combinedData = [];
                for (const doc1 of querySnapshot1.docs) {
                    combinedData.push({
                        id: doc1.id,
                        data: doc1.data(),
                    });
                }
                setpropusers(combinedData);
            });



        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const fetchData2 = async () => {
        try {
            const filteredData = propusers.filter((item) =>
                item.data.username.toUpperCase().includes(searchText.toUpperCase())
            );
            setpropusers(filteredData);
            console.log(filteredData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleSubmit = async () => {
        try {
            // Ensure that required fields are not empty
            if (!username || !email || !password) {
                Toast.fire({
                    icon: "error",
                    title: 'Please fill all the fields!!'
                });
                return;
            }

            // Create user with email and password
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user; // Newly created user

            if (user) {
                // Store user information in Firestore (without storing the password)
                await setDoc(doc(db, "users", user.uid), {
                    uid: user.uid,
                    username: username,
                    mobile: mobile || "", // Optional field
                    email: email,
                    password: password,
                    role: role || "Receptionist", // Default to "Receptionist" if role not specified
                    type: "Hotel",
                    status: status || "active", // Default status
                    loginid: userData?.uid || "", // Ensure userData exists
                    created: Timestamp.now()
                });

                // Optional: sign out the current user and sign in back with admin credentials
                await auth.signOut();
                await signInWithEmailAndPassword(auth, loginemail, loginpass); // Ensure loginemail/loginpass are available
            }

            // Reset form fields
            setusername("");
            setmobile("");
            setemail("");
            setpassword("");
            setrole("Receptionist");

            // Success notification
            Toast.fire({
                icon: "success",
                title: 'User Added Successfully!!'
            });

        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    };


    const handleDelete = async (docid) => {
        const taskDocRef = doc(db, 'propusers', docid)
        await deleteDoc(taskDocRef)
        Toast.fire({
            icon: "success",
            title: 'User Deleted Successfully!!'
        });
        fetchData1();
    }

    const handleEdit = (id, propid, username, mobile, role, status) => {
        setupdocid(id);
        setupusername(username);
        setupmobile(mobile);
        setuprole(role);
        setupstatus(status);
    }



    const handleUpdate = async () => {
        try {
            await updateDoc(doc(db, 'users', updocid), {
                username: upusername,
                mobile: upmobile,
                role: uprole,
                status: upstatus,
            });

            Toast.fire({
                icon: "success",
                title: 'User Updated Successfully!!'
            });

            fetchData1();
        } catch (error) {
            console.log(error.message);
        }

    }

    const handlePass = (email, pass) => {
        setuseremail(email);
        setuserpass(pass);

    }

    const updatePass = () => {
        if (upPassword) {
            reauthenticate(userpass)
                .then(() => {
                    return updateUserPassword(upPassword);
                })
                .catch((error) => {
                    console.error("Error re-authenticating:", error);
                });
        }
    }

    const updateUserPassword = (newPassword) => {
        const user = auth.currentUser;

        return updatePassword(user, newPassword)
            .then(async () => {
                console.log(newPassword);

                await updateDoc(doc(db, "users", user.uid), {
                    password: newPassword,
                });
                Toast.fire({
                    icon: "success",
                    title: 'Password Updated Successfully!!'
                });
            })
            .catch((error) => {
                console.error("Error updating password:", error);
            });
    };

    const reauthenticate = (currentPassword) => {
        const user = auth.currentUser;
        const credential = EmailAuthProvider.credential(
            user.email,
            currentPassword
        );

        return reauthenticateWithCredential(user, credential);
    };



    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ float: "right" }} >||  User Mgmt ||</button>
                        <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#insert-modal">Add  Users</button><br /><br />
                        <div className="card card-primary card-outline ex1">
                            <div className=" p-1">
                                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                                    className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
                            </div>
                            <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>User Name</th>
                                            <th>Mobile</th>
                                            <th>Email</th>
                                            {/* <th>Password</th> */}
                                            <th>Role</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            propusers.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.data.username}</td>
                                                    <td>{item.data.mobile}</td>
                                                    <td>{item.data.email}</td>
                                                    {/* <td>{item.data.password}</td> */}
                                                    <td>{item.data.role}</td>
                                                    <td>{item.data.status}</td>
                                                    <td>
                                                        <a onClick={() => handleEdit(item.id, item.data.propid, item.data.username, item.data.mobile, item.data.role, item.data.status)} href='#0' data-toggle="modal" data-target="#text-modal1" className="btn btn-sm btn-success" > <i className="fa fa-edit"></i></a>&nbsp;
                                                        <a onClick={() => handlePass(item.data.email, item.data.password)} href='#0' className="btn btn-sm btn-info" data-toggle="modal" data-target="#text-modal2" > <i className="fa fa-key"></i></a>&nbsp;
                                                        {/* <a onClick={() => handleDelete(item.id)} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a> */}
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}

            <div className="modal fade" id="insert-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add  Users</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Role</label>
                                    <select value={role} onChange={(e) => setrole(e.target.value)} className="form-control" >
                                        <option value="Receptionist">Receptionist</option>
                                        <option value="General Manager">General Manager</option>
                                        <option value="Desk Manager">Desk Manager</option>
                                        <option value="Cleaning Manager ">Cleaning Manager </option>
                                        <option value="Housekeeping Manager">Housekeeping Manager</option>
                                        <option value="Checkout Manager">Checkout Manager</option>
                                        <option value="Store Manager">Store Manager</option>
                                        <option value="Account Manager">Account Manager</option>
                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <label>User Name</label>
                                    <input type="text" value={username} onChange={(e) => setusername(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6">
                                    <label>Mobile </label>
                                    <input type="text" value={mobile} onChange={(e) => setmobile(e.target.value)} maxLength={10} minLength={10} className="form-control" />
                                </div>
                                <div className="col-sm-6">
                                    <label>Email </label>
                                    <input type="text" value={email} onChange={(e) => setemail(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6">
                                    <label>Password </label>
                                    <input type="text" value={password} onChange={(e) => setpassword(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6">
                                    <label>Status</label>
                                    <select value={status} onChange={(e) => setstatus(e.target.value)} className="form-control" >
                                        <option value="Active">Active</option>
                                        <option value="Deactive">Deactive</option>
                                    </select>
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="submit" onClick={() => handleSubmit()} className="btn btn-primary" data-dismiss="modal">Save </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="text-modal1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit  Users</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">

                                <div className="col-sm-6">
                                    <label>Role</label>
                                    <select value={uprole} onChange={(e) => setuprole(e.target.value)} className="form-control" >
                                        <option value="Receptionist">Receptionist</option>
                                        <option value="General Manager">General Manager</option>
                                        <option value="Desk Manager">Desk Manager</option>
                                        <option value="Cleaning Manager ">Cleaning Manager </option>
                                        <option value="Housekeeping Manager">Housekeeping Manager</option>
                                        <option value="Checkout Manager">Checkout Manager</option>
                                        <option value="Store Manager">Store Manager</option>
                                        <option value="Account Manager">Account Manager</option>
                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <label>User Name</label>
                                    <input type="text" value={upusername} onChange={(e) => setupusername(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6">
                                    <label>Mobile </label>
                                    <input type="text" value={upmobile} onChange={(e) => setupmobile(e.target.value)} maxLength={10} minLength={10} className="form-control" />
                                </div>
                                <div className="col-sm-6">
                                    <label>Status</label>
                                    <select value={upstatus} onChange={(e) => setupstatus(e.target.value)} className="form-control" >
                                        <option value="Active">Active</option>
                                        <option value="Deactive">Deactive</option>
                                    </select>
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={() => handleUpdate()} className="btn btn-primary" data-dismiss="modal">Update </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="text-modal2">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Update Password</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Password</label>
                                    <input type="text" value={upPassword} onChange={(e) => setupPassword(e.target.value)} className="form-control" />
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={() => updatePass()} className="btn btn-primary" data-dismiss="modal">Update </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
