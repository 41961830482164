import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where, limit, updateDoc } from 'firebase/firestore'
import moment from 'moment';

export default function Roomtarrif() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();
    const [roomTypes, setroomTypes] = useState([]);
    const [ratePlans, setratePlans] = useState([]);
    const [searchText, setsearchText] = useState("");
    const [roomtarrifs, setroomtarrifs] = useState([]);

    const [rtypeid, setrtypeid] = useState("");
    const [amount, setamount] = useState(0);
    const [discount, setdiscount] = useState(0);
    const [planid, setplanid] = useState("");

    const [updocid, setupdocid] = useState("");
    const [uprtypeid, setuprtypeid] = useState("");
    const [upamount, setupamount] = useState("");
    const [updiscount, setupdiscount] = useState("");
    const [upplanid, setupplanid] = useState("");


    useEffect(() => {
        if (searchText === '') {
            fetchData1()
        } else {
            fetchData2()
        }
    }, [searchText]);



    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
        });
    });

    const fetchData1 = async () => {
        try {
            const q = query(collection(db, 'roomtypes'), orderBy("createdAt", "asc"))
            onSnapshot(q, (querySnapshot) => {
                setroomTypes(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });

            const q2 = query(collection(db, 'rateplans'), orderBy("createdAt", "asc"))
            onSnapshot(q2, (querySnapshot) => {
                setratePlans(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });


            const q1 = query(collection(db, 'roomtarrifs'), orderBy("created", "asc"))
            onSnapshot(q1, async (querySnapshot1) => {
                const combinedData = [];
                let roomtype = "";
                let rateplan = "";
                for (const doc1 of querySnapshot1.docs) {
                    // const docRef = doc(db, "roomtypes", doc1.data().rtypeid)
                    // const docSnap = await getDoc(docRef)
                    // if (docSnap.exists()) {
                    //     roomtype = docSnap.data().roomtype;
                    // } else {
                    //     roomtype = ""
                    // }

                    // const docRef1 = doc(db, "rateplans", doc1.data().planid)
                    // const docSnap1 = await getDoc(docRef1)
                    // if (docSnap1.exists()) {
                    //     rateplan = docSnap1.data().rateplan;
                    // } else {
                    //     rateplan = ""
                    // }
                    combinedData.push({
                        id: doc1.id,
                        data: doc1.data(),
                        roomtype: roomtype,
                        rateplan: rateplan,
                    });
                }
                setroomtarrifs(combinedData);
            });


        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const fetchData2 = async () => {
        try {
            const filteredData = roomtarrifs.filter((item) =>
                item.data.amount.toUpperCase().includes(searchText.toUpperCase())
            );
            setroomtarrifs(filteredData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    const handleSubmit = async () => {

        if (!amount || !rtypeid || !planid) {
            Toast.fire({
                icon: "error",
                title: 'Please fill all the fields!!'
            });
            return;
        }

        let roomtype;
        const docRef = doc(db, "roomtypes", rtypeid)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            roomtype = docSnap.data().roomtype;
        } else {
            roomtype = ""
        }


        let rateplan;
        const docRef1 = doc(db, "rateplans", planid)
        const docSnap1 = await getDoc(docRef1)
        if (docSnap1.exists()) {
            rateplan = docSnap1.data().rateplan;
        } else {
            rateplan = ""
        }

        await addDoc(collection(db, 'roomtarrifs'), {
            roomtype: roomtype,
            rtypeid: rtypeid,
            amount: parseFloat(amount),
            discount: discount,
            planid: planid,
            rateplan: rateplan,
            uid: userData.uid,
            created: Timestamp.now()
        });

        const roomTariffsRef = collection(db, 'roomtarrifs');
        const q = query(roomTariffsRef, where('rtypeid', '==', rtypeid), orderBy('amount', 'asc'), limit(1));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const smallestTariffDoc = querySnapshot.docs[0];
            const smallestAmount = smallestTariffDoc.data().amount;
            await updateDoc(doc(db, "roomtypes", rtypeid), {
                amount: parseFloat(smallestAmount),
            });
        }

        // setrtypeid("");
        setplanid("");
        setamount(0);
        Toast.fire({
            icon: "success",
            title: 'Room Tarrifs Added Successfully!!'
        });
    }

    const handleDelete = async (docid, rtypeid) => {
        const taskDocRef = doc(db, 'roomtarrifs', docid)
        await deleteDoc(taskDocRef);

        const roomTariffsRef = collection(db, 'roomtarrifs');
        const q = query(roomTariffsRef, where('rtypeid', '==', rtypeid), orderBy('amount', 'asc'), limit(1));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const smallestTariffDoc = querySnapshot.docs[0];
            const smallestAmount = smallestTariffDoc.data().amount;
            await updateDoc(doc(db, "roomtypes", rtypeid), {
                amount: parseFloat(smallestAmount),
            });
        }



        Toast.fire({
            icon: "success",
            title: 'Room Tarrifs Deleted Successfully!!'
        });
        fetchData1();
    }

    const handleEdit = (id, rtypeid, amount, planid, discount) => {
        setupdocid(id);
        setupamount(amount);
        setuprtypeid(rtypeid);
        setupplanid(planid)
        setupdiscount(discount)
    }


    const handleUpdate = async () => {
        if (!upamount || !upamount || !upplanid) {
            Toast.fire({
                icon: "error",
                title: 'Please fill all the fields!!'
            });
            return;
        }

        try {

            let roomtype;
            const docRef = doc(db, "roomtypes", uprtypeid)
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                roomtype = docSnap.data().roomtype;
            } else {
                roomtype = ""
            }


            let rateplan;
            const docRef1 = doc(db, "rateplans", upplanid)
            const docSnap1 = await getDoc(docRef1)
            if (docSnap1.exists()) {
                rateplan = docSnap1.data().rateplan;
            } else {
                rateplan = ""
            }


            await updateDoc(doc(db, "roomtarrifs", updocid), {
                roomtype: roomtype,
                rtypeid: uprtypeid,
                amount: parseFloat(upamount),
                discount: updiscount,
                rateplan: rateplan,
                planid: upplanid,
            });

            const q = query(collection(db, 'roomtarrifs'), where('rtypeid', '==', uprtypeid), orderBy('amount', 'asc'), limit(1));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const smallestTariffDoc = querySnapshot.docs[0];
                const smallestAmount = smallestTariffDoc.data().amount;

                await updateDoc(doc(db, "roomtypes", uprtypeid), {
                    amount: parseFloat(smallestAmount),
                });
            }


            Toast.fire({
                icon: "success",
                title: 'Room Tarrifs Updated Successfully!!'
            });

            fetchData1();
        } catch (error) {
            console.log(error.message);
        }

    }


    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ float: "right" }} >|| Room Tarrifs ||</button>
                        <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#insert-modal">Add Room Tarrifs</button><br /><br />
                        <div className="card card-primary card-outline ex1">
                            <div className=" p-1">
                                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                                    className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
                            </div>
                            <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Room Type</th>
                                            <th>Plan Name</th>
                                            <th>Amount</th>
                                            <th>Discount</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            roomtarrifs.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.data.roomtype} </td>
                                                    <td>{item.data.rateplan} </td>
                                                    <td>Rs. {item.data.amount} /-</td>
                                                    <td> {item.data.discount} %</td>
                                                    <td>
                                                        <a onClick={() => handleEdit(item.id, item.data.rtypeid, item.data.amount, item.data.planid, item.data.discount)} href='#0' data-toggle="modal" data-target="#text-modal1" className="btn btn-sm btn-success" > <i className="fa fa-edit"></i></a>&nbsp;
                                                        <a onClick={() => { if (window.confirm('Are u sure?')) { handleDelete(item.id, item.data.rtypeid) }; }} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a>&nbsp;
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}

            <div className="modal fade" id="insert-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Room Tarrifs</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Room Type</label>
                                    <select value={rtypeid} onChange={(e) => setrtypeid(e.target.value)} className="form-control" >
                                        <option value=""></option>
                                        {
                                            roomTypes.map((item, index) =>
                                                <option key={index} value={item.id}>{item.data.roomtype}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <label>Plan Name</label>
                                    <select value={planid} onChange={(e) => setplanid(e.target.value)} className="form-control" >
                                        <option value=""></option>
                                        {
                                            ratePlans.map((item, index) =>
                                                <option key={index} value={item.id}>{item.data.rateplan}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <label>  Amount</label>
                                    <input type="number" value={amount} onChange={(e) => setamount(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6">
                                    <label>  Discount</label>
                                    <input type="number" value={discount} onChange={(e) => setdiscount(e.target.value)} className="form-control" />
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="submit" onClick={() => handleSubmit()} className="btn btn-primary" >Save </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="text-modal1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Room Tarrifs</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Room Type</label>
                                    <select value={uprtypeid} onChange={(e) => setuprtypeid(e.target.value)} className="form-control" >
                                        <option value=""></option>
                                        {
                                            roomTypes.map((item, index) =>
                                                <option key={index} value={item.id}>{item.data.roomtype}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <label>Plan Name</label>
                                    <select value={upplanid} onChange={(e) => setupplanid(e.target.value)} className="form-control" >
                                        <option value=""></option>
                                        {
                                            ratePlans.map((item, index) =>
                                                <option key={index} value={item.id}>{item.data.rateplan}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <label>  Amount</label>
                                    <input type="number" value={upamount} onChange={(e) => setupamount(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6">
                                    <label>  Discount</label>
                                    <input type="number" value={updiscount} onChange={(e) => setupdiscount(e.target.value)} className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={() => handleUpdate()} className="btn btn-primary" data-dismiss="modal">Update </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
