import React, { useEffect, useState, } from 'react'
import Swal from 'sweetalert2';
import { auth, db } from '../../firebase';
import "../../App.css";
import { collection, query, orderBy, onSnapshot, getDoc, doc, limit } from 'firebase/firestore'

export default function Logindetails() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();
    const [loadincount, setloadincount] = useState(true);

    const [logindetails, setlogindetails] = useState([]);
    const [searchText, setsearchText] = useState("");


    useEffect(() => {
        if (searchText === '') {
            fetchData1()
        } else {
            fetchData2()
        }
    }, [searchText]);



    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
            fetchPropid()
        });
    });

    const fetchPropid = async () => {
        if (userData) {
            if (loadincount) {
                const docRef = doc(db, "users", userData.uid)
                const docSnap = await getDoc(docRef)
                if (docSnap.exists()) {
                    fetchData1();
                    setloadincount(false)
                }
            }
        }
    }

    const fetchData1 = async () => {
        try {
            const q = query(collection(db, 'logindetails'), orderBy('createdTime', 'desc'), limit(200))
            onSnapshot(q, (querySnapshot) => {
                setlogindetails(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });
        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const fetchData2 = async () => {
        try {
            const filteredData = logindetails.filter((item) =>
                item.data.username.toUpperCase().includes(searchText.toUpperCase())
            );
            setlogindetails(filteredData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }



    return (
        <div>
            <div className="content-wrapper">
                <br />

                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ float: "right", textTransform: "uppercase" }} >|| Login Details ||</button><br /><br /><br />
                        <div className="card card-primary card-outline ex1">
                            <div className=" p-1">
                                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                                    className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
                            </div>
                            <div className="card-body table-responsive p-0" >

                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>User Name </th>
                                            <th>Login Time </th>
                                            <th>Platform </th>
                                            <th>Screen Ratio </th>
                                            <th>User Agent </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            logindetails.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.data.username}</td>
                                                    <td>{item.data.createdTime}</td>
                                                    <td>{item.data.systemDetails.platform}</td>
                                                    <td>{item.data.systemDetails.screenHeight} X {item.data.systemDetails.screenWidth}</td>
                                                    <td>{item.data.systemDetails.userAgent}</td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </div>
    )
}
