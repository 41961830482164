import React, { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2';
import { addDoc, collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import "../../App.css";
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import Menu from '../../panel/Menu';

function Roomtypewiseonline() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
        });
    });
    const printRef = useRef();
    const [loading, setloading] = useState(false);
    const [userData, setUserData] = useState();
    const [fromDate, setfromDate] = useState("");
    const [toDate, settoDate] = useState("");
    const [bookingData, setbookingData] = useState([]);



    const getReport = async () => {

        if (!fromDate || !toDate) {
            Toast.fire({ icon: "error", title: 'Please select From Date & To Date & Receptionist' });
            return
        }
        setloading(false)
        setbookingData([])
        const today = new Date(fromDate);
        today.setHours(0, 0, 0, 0);

        const tomorrow = new Date(toDate);
        tomorrow.setDate(tomorrow.getDate() + 1);

        const roomtypesQuery = query(collection(db, 'roomtypes'));
        const roomtypesSnapshot = await getDocs(roomtypesQuery);
        const roomtypesData = roomtypesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        const rTypeId = roomtypesData.map((item) => item.id);

        const webBookingsQuery = query(collection(db, 'websitebookings'), where('rtypeid', 'in', rTypeId), where('status', '==', 'Paid'), where('checkindate', '>=', fromDate), where('checkindate', '<=', toDate));
        const webBookingsSnapshot = await getDocs(webBookingsQuery);
        const webBookingsData = webBookingsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        const combinedData = roomtypesData.map((rtype) => {
            const bookingdetails = webBookingsData.filter((item) => item.rtypeid === rtype.id);
            return { ...rtype, bookingDetails: bookingdetails };
        });

        setbookingData(combinedData);
        console.log(combinedData);
        setloading(true)
    }

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    })

    const handleFromDate = (e) => {
        setfromDate(e.target.value)
        setloading(false)
    }
    const handleToDate = (e) => {
        settoDate(e.target.value)
        setloading(false)
    }

    return (
        <div>
            <Menu />
            <div className="content-wrapper">
                <section className="content mt-2">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " >|| Room Type Wise Website Bookings ||</button><br /><br />
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary card-outline">
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-sm-3'>
                                                <label>From Date</label>
                                                <input type='date' value={fromDate} onChange={handleFromDate} className='form-control' />
                                            </div>
                                            <div className='col-sm-3'>
                                                <label>To Date</label>
                                                <input type='date' value={toDate} onChange={handleToDate} className='form-control' />
                                            </div>
                                            <div className='col-sm-3'>
                                                <br />
                                                <button className='btn btn-info' onClick={getReport}>Get Report</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    loading &&
                                    <>
                                        <div className="card card-primary card-outline" ref={printRef}>
                                            <div className="card-body">
                                                <div className="row invoice-info">
                                                    <div className="col-sm-12 invoice-col text-center">
                                                        <address>
                                                            <h4>The Sky Land Hotel & Restaurant.</h4>
                                                            Near New Bus Stand, Latur Road, Tuljapur, Dist - Dharashiv, MH - 413601<br />
                                                            Phone: +91 70777779163 |
                                                            Email:  theskylandhotel@gmail.com<br />
                                                            Website:  www.theskylandhotel.com
                                                        </address>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <table className='customers2 '>
                                                        <tr>
                                                            <td style={{ textAlign: "center" }}>From Date : {moment(fromDate).format('DD/MM/YYYY')}  </td>
                                                            <td style={{ textAlign: "center" }}><h5 style={{ textTransform: "uppercase" }}>WEBSITE BOOKINGS  REPORT </h5></td>
                                                            <td style={{ textAlign: "center" }}>To Date : {moment(toDate).format('DD/MM/YYYY')}</td>
                                                        </tr>
                                                    </table>
                                                    <table className="customers2">
                                                        {
                                                            bookingData.map((item, index) =>
                                                                <thead key={index}>
                                                                    <tr>
                                                                        <th style={{ backgroundColor: "#daff9a", }} colSpan={12}>{item.roomtype}</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Sr No</th>
                                                                        <th> Name </th>
                                                                        <th>Mobile </th>
                                                                        <th>Checkin  </th>
                                                                        <th>Checkout  </th>
                                                                        <th>Rooms </th>
                                                                        <th>Adults </th>
                                                                        <th>Room Type  </th>
                                                                        <th style={{ textAlign: "right" }}>Total</th>
                                                                        <th style={{ textAlign: "right" }}>Discount</th>
                                                                        <th style={{ textAlign: "right" }}>Paid</th>
                                                                        <th style={{ textAlign: "right" }}>Remaining</th>
                                                                    </tr>
                                                                    {
                                                                        item.bookingDetails.map((item1, num = 0) =>
                                                                            <tr>
                                                                                <td width="80">{num + 1}</td>
                                                                                <td>{item1.name}</td>
                                                                                <td>{item1.phone} </td>
                                                                                <td>{moment(item1.checkindate).format('DD/MM/YYYY')}</td>
                                                                                <td>{moment(item1.checkoutdate).format('DD/MM/YYYY')}</td>
                                                                                <td>{item1.noofroom} Rooms</td>
                                                                                <td>{item1.adult} Persons</td>
                                                                                <td>{item1.roomtype} <br /> {item1.planname} </td>
                                                                                <td style={{ textAlign: "right" }}>{item1.total}</td>
                                                                                <td style={{ textAlign: "right" }}>{item1.discountamount} ({item1.discount}%)</td>
                                                                                <td style={{ textAlign: "right" }}>{item1.advance}</td>
                                                                                <td style={{ textAlign: "right" }}>{item1.remaining}</td>
                                                                            </tr>

                                                                        )
                                                                    }
                                                                </thead>
                                                            )
                                                        }
                                                        {/* <thead>
                                                            <tr>
                                                                <th>Sr No</th>
                                                                <th>Customer Name </th>
                                                                <th>Mobile </th>
                                                                <th>Checkin Date </th>
                                                                <th>Checkout Date </th>
                                                                <th style={{ width: 200 }}>Rooms </th>
                                                                <th>Adults </th>
                                                                <th>Booking Type </th>
                                                                <th style={{ textAlign: "right" }}>Total</th>
                                                                <th style={{ textAlign: "right" }}>Paid</th>
                                                                <th style={{ textAlign: "right" }}>Remaining</th>
                                                            </tr>
                                                        </thead> */}
                                                        {/* <tbody>
                                                            {
                                                                bookingData.map((item, num = 1) =>
                                                                    <tr key={num + 1}>
                                                                        <td width="80">{num + 1}</td>
                                                                        <td>{item.data.customers.custname}</td>
                                                                        <td>{item.data.customers.mobile} </td>
                                                                        <td>{moment(item.data.checkindate).format('DD/MM/YYYY')}</td>
                                                                        <td>{moment(item.data.checkoutdate).format('DD/MM/YYYY')}</td>
                                                                        <td>
                                                                            <div className='row'>
                                                                                {item.data.rooms.map((item1, index) =>
                                                                                    <span key={index}>{item1.roomname} &nbsp;  </span>
                                                                                )}
                                                                            </div>
                                                                        </td>
                                                                        <td>{item.data.adults} Persons</td>
                                                                        <td>{item.data.bookingsource} </td>
                                                                        <td style={{ textAlign: "right" }}>{item.data.totalamount.toFixed(2)}</td>
                                                                        <td style={{ textAlign: "right" }}>{item.data.advance.toFixed(2)}</td>
                                                                        <td style={{ textAlign: "right" }}>{item.data.remaining.toFixed(2)}</td>

                                                                    </tr>
                                                                )}
                                                        </tbody> */}
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                        <center>
                                            <button className='btn btn-success' onClick={handlePrint}>Print Report</button>
                                        </center>
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </section >




            </div>



        </div >

    )
}

export default Roomtypewiseonline
