import React, { useEffect, useState, } from 'react'
import Swal from 'sweetalert2';
import { addDoc, collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import "../../App.css";
import Menu from '../../panel/Menu';
import moment from 'moment';
import { useParams } from 'react-router-dom';

function Onlinebookings() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const { type } = useParams();
    const [webbookings, setwebbookings] = useState([]);
    const [advancebookings, setadvancebookings] = useState([]);
    const [userData, setUserData] = useState();
    const [loadincount, setloadincount] = useState(true);
    const [searchText, setsearchText] = useState("");
    const [searchText1, setsearchText1] = useState("");

    const [dateType, setdateType] = useState("");

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
            fethUsers()
        });
    });

    const fethUsers = async () => {
        try {
            if (userData) {
                if (loadincount) {
                    const docRef = doc(db, "users", userData.uid)
                    const docSnap = await getDoc(docRef)
                    if (docSnap.exists()) {
                        setloadincount(false)
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (searchText === '') {
            fetchData1()
        } else {
            fetchData2()
        }
    }, [searchText]);

    useEffect(() => {
        if (searchText1 === '') {
            fetchData3()

        } else {
            fetchData4()
        }
    }, [searchText1]);


    const fetchData1 = async () => {
        try {
            const today = new Date().toISOString().split('T')[0];
            let toommorrow = moment().add(1, 'days').format('YYYY-MM-DD');
            const firstDateOfMonth = moment().startOf('month').format('YYYY-MM-DD');
            const lastDateOfMonth = moment().endOf('month').format('YYYY-MM-DD');
            const firstDayOfNextMonth = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
            const lastDayOfNextMonth = moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');

            if (type === 'todays') {
                setdateType("Todays");
                const q1 = query(collection(db, 'websitebookings'), where('checkindate', '==', today), where('status', '==', 'Paid'));
                onSnapshot(q1, (querySnapshot) => {
                    setwebbookings(querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        data: doc.data()
                    })));
                });
            }

            if (type === 'tommorrows') {
                setdateType("Tommorrows");
                const q1 = query(collection(db, 'websitebookings'), where('checkindate', '==', toommorrow), where('status', '==', 'Paid'));
                onSnapshot(q1, (querySnapshot) => {
                    setwebbookings(querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        data: doc.data()
                    })));
                });
            }

            if (type === 'thismonth') {
                setdateType("This Months")
                const q1 = query(collection(db, 'websitebookings'), where('checkindate', '>=', firstDateOfMonth), where('checkindate', '<=', lastDateOfMonth), where('status', '==', 'Paid'));
                onSnapshot(q1, (querySnapshot) => {
                    setwebbookings(querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        data: doc.data()
                    })));
                });
            }

            if (type === 'nextmonth') {
                setdateType("Next Months");
                const q8 = query(collection(db, 'websitebookings'), where('checkindate', '>=', firstDayOfNextMonth), where('checkindate', '<=', lastDayOfNextMonth), where('status', '==', 'Paid'));
                onSnapshot(q8, (querySnapshot) => {
                    setwebbookings(querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        data: doc.data()
                    })));
                });
            }
        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }


    const fetchData3 = async () => {
        try {
            const today = new Date().toISOString().split('T')[0];
            let toommorrow = moment().add(1, 'days').format('YYYY-MM-DD');
            const firstDateOfMonth = moment().startOf('month').format('YYYY-MM-DD');
            const lastDateOfMonth = moment().endOf('month').format('YYYY-MM-DD');
            const firstDayOfNextMonth = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
            const lastDayOfNextMonth = moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');

            if (type === 'todays') {
                setdateType("Todays");
                const q1 = query(collection(db, 'advancebookings'), where('checkindate', '==', today), where('status', '==', 'Paid'));
                onSnapshot(q1, (querySnapshot) => {
                    setadvancebookings(querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        data: doc.data()
                    })));
                });
            }

            if (type === 'tommorrows') {
                setdateType("Tommorrows");
                const q1 = query(collection(db, 'advancebookings'), where('checkindate', '==', toommorrow), where('status', '==', 'Paid'));
                onSnapshot(q1, (querySnapshot) => {
                    setadvancebookings(querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        data: doc.data()
                    })));
                });
            }

            if (type === 'thismonth') {
                setdateType("This Months")
                const q1 = query(collection(db, 'advancebookings'), where('checkindate', '>=', firstDateOfMonth), where('checkindate', '<=', lastDateOfMonth), where('status', '==', 'Paid'));
                onSnapshot(q1, (querySnapshot) => {
                    setadvancebookings(querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        data: doc.data()
                    })));
                });
            }

            if (type === 'nextmonth') {
                setdateType("Next Months");
                const q8 = query(collection(db, 'advancebookings'), where('checkindate', '>=', firstDayOfNextMonth), where('checkindate', '<=', lastDayOfNextMonth), where('status', '==', 'Paid'));
                onSnapshot(q8, (querySnapshot) => {
                    setadvancebookings(querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        data: doc.data()
                    })));
                });
            }
        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const fetchData2 = async () => {
        try {
            const filteredData = webbookings.filter((item) =>
                item.data.name.toUpperCase().includes(searchText.toUpperCase())
            );
            setwebbookings(filteredData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const fetchData4 = async () => {
        try {
            const filteredData = advancebookings.filter((item) =>
                item.data.customers.custname.toUpperCase().includes(searchText1.toUpperCase())
            );
            console.log(filteredData);
            setadvancebookings(filteredData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    return (
        <div>
            <Menu />
            <div className="content-wrapper" style={{ backgroundColor: '#fff' }}>
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ textTransform: "uppercase" }} >|| {dateType} Online Bookings ||</button>
                        <div className="card card-primary card-outline mt-2">
                            <div className="card-body table-responsive" >
                                <h5>Website Bookings</h5>
                                <input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)} className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th> Name </th>
                                            <th>Mobile </th>
                                            <th>Checkin  </th>
                                            <th>Checkout  </th>
                                            <th>Rooms </th>
                                            <th>Adults </th>
                                            <th>Room Type  </th>
                                            <th style={{ textAlign: "right" }}>Total</th>
                                            <th style={{ textAlign: "right" }}>Discount</th>
                                            <th style={{ textAlign: "right" }}>Paid</th>
                                            <th style={{ textAlign: "right" }}>Remaining</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            webbookings.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.data.name}</td>
                                                    <td>{item.data.phone} </td>
                                                    <td>{moment(item.data.checkindate).format('DD/MM/YYYY')}</td>
                                                    <td>{moment(item.data.checkoutdate).format('DD/MM/YYYY')}</td>
                                                    <td>{item.data.noofroom} Rooms</td>
                                                    <td>{item.data.adult} Persons</td>
                                                    <td>{item.data.roomtype} <br /> {item.data.planname} </td>
                                                    <td style={{ textAlign: "right" }}>{item.data.total}</td>
                                                    <td style={{ textAlign: "right" }}>{item.data.discountamount} ({item.data.discount}%)</td>
                                                    <td style={{ textAlign: "right" }}>{item.data.advance}</td>
                                                    <td style={{ textAlign: "right" }}>{item.data.remaining}</td>
                                                    
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="card card-primary card-outline mt-2">
                            <div className="card-body table-responsive" >
                                <h5>Advance Bookings</h5>
                                <input type="search" value={searchText1} onChange={(e) => setsearchText1(e.target.value)} className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th> Name </th>
                                            <th>Mobile </th>
                                            <th>Checkin  </th>
                                            <th>Checkout  </th>
                                            <th >Rooms </th>
                                            <th>Adults </th>
                                            <th style={{ width: 200 }}>Room Type  </th>
                                            <th style={{ textAlign: "right" }}>Total</th>
                                            <th style={{ textAlign: "right" }}>Paid</th>
                                            <th style={{ textAlign: "right" }}>Remaining</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            advancebookings.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.data.customers.custname}</td>
                                                    <td>{item.data.customers.mobile} </td>
                                                    <td>{moment(item.data.checkindate).format('DD/MM/YYYY')}</td>
                                                    <td>{moment(item.data.checkoutdate).format('DD/MM/YYYY')}</td>
                                                    <td>{item.data.noofrooms} Rooms</td>
                                                    <td>{item.data.adults} Persons</td>
                                                    <td>
                                                        <div className='row'>
                                                            {item.data.rooms.map((item1, index) =>
                                                                <span key={index}>{item1.roomtypename}, &nbsp;  </span>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td style={{ textAlign: "right" }}>{item.data.totalamount}</td>
                                                    <td style={{ textAlign: "right" }}>{item.data.advance}</td>
                                                    <td style={{ textAlign: "right" }}>{item.data.remaining}</td>
                                                    
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Onlinebookings
