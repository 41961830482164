import React, { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2';
import { auth, db } from '../../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, deleteDoc, doc, getCountFromServer, where, getDocs, setDoc, updateDoc } from 'firebase/firestore'
import moment from 'moment';

export default function Websiterooms() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();
    const [roomTypes, setroomTypes] = useState([]);
    const [daywiserooms, setdaywiserooms] = useState([]);
    const [datewiserroms, setdatewiserroms] = useState([]);

    const [rtypeid, setrtypeid] = useState("");
    const [monday, setmonday] = useState(0);
    const [tuesday, settuesday] = useState(0);
    const [wednesday, setwednesday] = useState(0);
    const [thursday, setthursday] = useState(0);
    const [friday, setfriday] = useState(0);
    const [saturday, setsaturday] = useState(0);
    const [sunday, setsunday] = useState(0);
    const [maxRoom, setmaxRoom] = useState(0);

    const [updayDocid, setupdayDocid] = useState(0);
    const [upmonday, setupmonday] = useState(0);
    const [uptuesday, setuptuesday] = useState(0);
    const [upwednesday, setupwednesday] = useState(0);
    const [upthursday, setupthursday] = useState(0);
    const [upfriday, setupfriday] = useState(0);
    const [upsaturday, setupsaturday] = useState(0);
    const [upsunday, setupsunday] = useState(0);

    const [roomDate, setroomDate] = useState(0);
    const [roomDateNo, setroomDateNo] = useState(0);


    useEffect(() => {
        fetchDaywise();
        fetchRoomtypes();
        fetchDatewise()
    }, []);


    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
        });
    });

    const fetchRoomtypes = () => {

        const q1 = query(collection(db, 'roomtypes'), orderBy("createdAt", "asc"))
        onSnapshot(q1, async (querySnapshot1) => {
            const combinedData = [];
            for (const doc1 of querySnapshot1.docs) {
                combinedData.push({
                    id: doc1.id,
                    data: doc1.data(),
                });
            }
            setroomTypes(combinedData);
        });


    }

    const fetchDaywise = async () => {
        try {
            const q1 = query(collection(db, 'daywiserooms'), orderBy("createdAt", "asc"))
            onSnapshot(q1, async (querySnapshot1) => {
                const combinedData = [];
                let roomtype = "";
                for (const doc1 of querySnapshot1.docs) {
                    const docRef = doc(db, "roomtypes", doc1.data().rtypeid)
                    const docSnap = await getDoc(docRef)
                    if (docSnap.exists()) {
                        roomtype = docSnap.data().roomtype;
                    } else {
                        roomtype = ""
                    }
                    combinedData.push({
                        id: doc1.id,
                        data: doc1.data(),
                        roomtype: roomtype
                    });
                }
                setdaywiserooms(combinedData);
            });

        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const fetchDatewise = async () => {
        try {
            const currentDate = moment().format('YYYY-MM-DD');

            const q1 = query(collection(db, 'datewiserooms'), orderBy("createdAt", "asc"), where('roomDate', ">=", currentDate))
            onSnapshot(q1, async (querySnapshot1) => {
                const combinedData = [];
                let roomtype = "";
                for (const doc1 of querySnapshot1.docs) {
                    const docRef = doc(db, "roomtypes", doc1.data().rtypeid)
                    const docSnap = await getDoc(docRef)
                    if (docSnap.exists()) {
                        roomtype = docSnap.data().roomtype;
                    } else {
                        roomtype = ""
                    }
                    combinedData.push({
                        id: doc1.id,
                        data: doc1.data(),
                        roomtype: roomtype
                    });
                }
                setdatewiserroms(combinedData);
            });

        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }


    const handleSubmitDaywise = async () => {
        try {
            if (!rtypeid) {
                Toast.fire({
                    icon: "error",
                    title: 'Please select Room Type!!'
                });
            }
            const q = query(collection(db, 'daywiserooms'), where('rtypeid', '==', rtypeid));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                await setDoc(doc(db, "daywiserooms", querySnapshot.docs[0].id), {
                    rtypeid: rtypeid,
                    monday: parseFloat(monday),
                    tuesday: parseFloat(tuesday),
                    wednesday: parseFloat(wednesday),
                    thursday: parseFloat(thursday),
                    friday: parseFloat(friday),
                    saturday: parseFloat(saturday),
                    sunday: parseFloat(sunday),
                    uid: userData.uid,
                    createdAt: Timestamp.now(),
                });

                fetchDaywise();
            } else {
                await addDoc(collection(db, "daywiserooms"), {
                    rtypeid: rtypeid,
                    monday: parseFloat(monday),
                    tuesday: parseFloat(tuesday),
                    wednesday: parseFloat(wednesday),
                    thursday: parseFloat(thursday),
                    friday: parseFloat(friday),
                    saturday: parseFloat(saturday),
                    sunday: parseFloat(sunday),
                    uid: userData.uid,
                    createdAt: Timestamp.now(),
                });

                fetchDaywise();
            }

            Toast.fire({
                icon: "success",
                title: 'Website Rooms Added Successfully!!'
            });


        } catch (err) {
            Toast.fire({
                icon: "error",
                title: 'Error in Uploading!!'
            });

            console.log(err.message);
        }
    }

    const handleDeleteDaywise = async (docid) => {
        const taskDocRef = doc(db, 'daywiserooms', docid)
        await deleteDoc(taskDocRef)
        Toast.fire({
            icon: "success",
            title: 'Daywise Rooms Deleted Successfully!!'
        });
        fetchDaywise();

    }

    const handleRoomtypechange = async (e) => {
        if (e.target.value) {
            setrtypeid(e.target.value)
            const q = query(collection(db, "roomdetails"), where("rtypeid", "==", e.target.value));
            try {
                const snapshot = await getCountFromServer(q);
                setmaxRoom(snapshot.data().count);
            } catch (error) {
                console.error("Error counting documents with condition: ", error);
            }
            setmonday(0); settuesday(0); setwednesday(0); setthursday(0); setfriday(0); setsaturday(0); setsunday(0);
        }
    }


    const handleEditDay = async (id, rtypeid, monday, tuesday, wednesday, thursday, friday, saturday, sunday) => {
        const q = query(collection(db, "roomdetails"), where("rtypeid", "==", rtypeid));
        try {
            const snapshot = await getCountFromServer(q);
            setmaxRoom(snapshot.data().count);
        } catch (error) {
            console.error("Error counting documents with condition: ", error);
        }

        setupdayDocid(id);
        setupmonday(monday);
        setuptuesday(tuesday);
        setupwednesday(wednesday);
        setupthursday(thursday);
        setupfriday(friday);
        setupsaturday(saturday);
        setupsunday(sunday);
    }

    const handleupdateDaywise = async () => {
        try {
            await updateDoc(doc(db, "daywiserooms", updayDocid), {
                monday: parseFloat(upmonday),
                tuesday: parseFloat(uptuesday),
                wednesday: parseFloat(upwednesday),
                thursday: parseFloat(upthursday),
                friday: parseFloat(upfriday),
                saturday: parseFloat(upsaturday),
                sunday: parseFloat(upsunday),
            });
            Toast.fire({
                icon: "success",
                title: 'Day wise room successfully updated!'
            });
            fetchDaywise()
        } catch (error) {
            Toast.fire({
                icon: "error",
                title: 'Something was wrong!'
            });
        }
    }

    const handleSubmitDatewise = async () => {
        try {
            if (!rtypeid || !roomDate || !roomDateNo) {
                Toast.fire({
                    icon: "success",
                    title: 'Required all fields!'
                });

                return;
            }
            await addDoc(collection(db, "datewiserooms"), {
                rtypeid: rtypeid,
                roomDate: roomDate,
                roomDateNo: parseFloat(roomDateNo),
                uid: userData.uid,
                createdAt: Timestamp.now(),
            });
            fetchDatewise();
            setrtypeid("");
            setroomDate("");
            setroomDateNo("");
            Toast.fire({
                icon: "success",
                title: 'Date wise room successfully updated!'
            });

        } catch (error) {
            Toast.fire({
                icon: "error",
                title: 'Something was wrong!'
            });
        }
    }

    const handleDeleteDatewise = async (docid) => {
        const taskDocRef = doc(db, 'datewiserooms', docid)
        await deleteDoc(taskDocRef)
        Toast.fire({
            icon: "success",
            title: 'Datewise Rooms Deleted Successfully!!'
        });
        fetchDatewise();
    }

    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ float: "right" }} >|| Datewise Rooms ||</button>
                        <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#date-modal">Add Datewise Rooms</button><br /><br />
                        <div className="card card-primary card-outline ex1">
                            <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Room Type</th>
                                            <th>Date</th>
                                            <th>Available Rooms</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            datewiserroms.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.roomtype} </td>
                                                    <td>{moment(item.data.roomDate).format('DD-MM-YYYY')} </td>
                                                    <td>{item.data.roomDateNo}  Rooms</td>
                                                    <td>
                                                        <a onClick={() => { if (window.confirm('Are u sure?')) { handleDeleteDatewise(item.id) }; }} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a>&nbsp;
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ float: "right" }} >|| Daywise Rooms ||</button>
                        <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#daywise-modal">Add Daywise Rooms</button><br /><br />
                        <div className="card card-primary card-outline ex1">

                            <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Room Type</th>
                                            <th>Mon</th>
                                            <th>Tue</th>
                                            <th>Wed</th>
                                            <th>Thu</th>
                                            <th>Fri</th>
                                            <th>Sat</th>
                                            <th>Sun</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            daywiserooms.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.roomtype} </td>
                                                    <td>{item.data.monday} Rooms </td>
                                                    <td>{item.data.tuesday} Rooms </td>
                                                    <td>{item.data.wednesday} Rooms </td>
                                                    <td>{item.data.thursday} Rooms </td>
                                                    <td>{item.data.friday} Rooms </td>
                                                    <td>{item.data.saturday} Rooms </td>
                                                    <td>{item.data.sunday} Rooms </td>
                                                    <td>
                                                        <a onClick={() => handleEditDay(item.id, item.data.rtypeid, item.data.monday, item.data.tuesday,
                                                            item.data.wednesday, item.data.thursday, item.data.friday, item.data.saturday, item.data.sunday)}
                                                            href='#0' data-toggle="modal" data-target="#updateday-modal" className="btn btn-sm btn-success" > <i className="fa fa-edit"></i></a>&nbsp;
                                                        <a onClick={() => { if (window.confirm('Are u sure?')) { handleDeleteDaywise(item.id, item.data.imageurl) }; }} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a>&nbsp;
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section> */}
            </div>

            {/* ============================================= Daywise Modal start ================================================= */}

            <div className="modal fade" id="date-modal">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Datewise Website Rooms </h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>Room Type</label>
                                    <select value={rtypeid} onChange={handleRoomtypechange} className="form-control" >
                                        <option value=""></option>
                                        {
                                            roomTypes.map((item, index) =>
                                                <option key={index} value={item.id}>{item.data.roomtype}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-sm-4">
                                    <label>  Date</label>
                                    <input type='date' className='form-control' value={roomDate} onChange={(e) => setroomDate(e.target.value)} />
                                </div>

                                <div className="col-sm-4">
                                    <label>  Available Rooms</label>
                                    <input type='text' className='form-control' value={roomDateNo}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value > maxRoom) {
                                                alert('No of Rooms should not be greater than ' + maxRoom);
                                                setroomDateNo(String(Number(maxRoom)));
                                            }
                                            else {
                                                setroomDateNo(String(Number(value)));
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="submit" onClick={() => handleSubmitDatewise()} className="btn btn-primary">Save </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ============================================= Daywise Modal start ================================================= */}

            <div className="modal fade" id="daywise-modal">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Daywise Website Rooms </h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>Room Type</label>
                                    <select value={rtypeid} onChange={handleRoomtypechange} className="form-control" >
                                        <option value=""></option>
                                        {
                                            roomTypes.map((item, index) =>
                                                <option key={index} value={item.id}>{item.data.roomtype}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-sm-1">
                                    <label>  Mon</label>
                                    <input type='text' className='form-control' value={monday}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value > maxRoom) {
                                                alert('No of Rooms should not be greater than ' + maxRoom);
                                                setmonday(String(Number(maxRoom)));
                                            }
                                            else {
                                                setmonday(String(Number(value)));
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-sm-1">
                                    <label>  Tue</label>
                                    <input type='text' className='form-control' value={tuesday}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value > maxRoom) {
                                                alert('No of Rooms should not be greater than ' + maxRoom);
                                                settuesday(String(Number(maxRoom)));
                                            }
                                            else {
                                                settuesday(String(Number(value)));
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-sm-1">
                                    <label>  Wed</label>
                                    <input type='text' className='form-control' value={wednesday}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value > maxRoom) {
                                                alert('No of Rooms should not be greater than ' + maxRoom);
                                                setwednesday(String(Number(maxRoom)));
                                            }
                                            else {
                                                setwednesday(String(Number(value)));
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-sm-1">
                                    <label>  Thu</label>
                                    <input type='text' className='form-control' value={thursday}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value > maxRoom) {
                                                alert('No of Rooms should not be greater than ' + maxRoom);
                                                setthursday(String(Number(maxRoom)));
                                            }
                                            else {
                                                setthursday(String(Number(value)));
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-sm-1">
                                    <label>  Fri</label>
                                    <input type='text' className='form-control' value={friday}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value > maxRoom) {
                                                alert('No of Rooms should not be greater than ' + maxRoom);
                                                setfriday(String(Number(maxRoom)));
                                            }
                                            else {
                                                setfriday(String(Number(value)));
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-sm-1">
                                    <label>  Sat</label>
                                    <input type='text' className='form-control' value={saturday}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value > maxRoom) {
                                                alert('No of Rooms should not be greater than ' + maxRoom);
                                                setsaturday(String(Number(maxRoom)));
                                            }
                                            else {
                                                setsaturday(String(Number(value)));
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-sm-1">
                                    <label>  Sun</label>
                                    <input type='text' className='form-control' value={sunday}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value > maxRoom) {
                                                alert('No of Rooms should not be greater than ' + maxRoom);
                                                setsunday(String(Number(maxRoom)));
                                            }
                                            else {
                                                setsunday(String(Number(value)));
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="submit" onClick={() => handleSubmitDaywise()} className="btn btn-primary">Save </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="updateday-modal">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Update Daywise Website Rooms </h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-1">
                                    <label>  Mon</label>
                                    <input type='text' className='form-control' value={upmonday}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value > maxRoom) {
                                                alert('No of Rooms should not be greater than ' + maxRoom);
                                                setupmonday(String(Number(maxRoom)));
                                            }
                                            else {
                                                setupmonday(String(Number(value)));
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-sm-1">
                                    <label>  Tue</label>
                                    <input type='text' className='form-control' value={uptuesday}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value > maxRoom) {
                                                alert('No of Rooms should not be greater than ' + maxRoom);
                                                setuptuesday(String(Number(maxRoom)));
                                            }
                                            else {
                                                setuptuesday(String(Number(value)));
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-sm-1">
                                    <label>  Wed</label>
                                    <input type='text' className='form-control' value={upwednesday}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value > maxRoom) {
                                                alert('No of Rooms should not be greater than ' + maxRoom);
                                                setupwednesday(String(Number(maxRoom)));
                                            }
                                            else {
                                                setupwednesday(String(Number(value)));
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-sm-1">
                                    <label>  Thu</label>
                                    <input type='text' className='form-control' value={upthursday}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value > maxRoom) {
                                                alert('No of Rooms should not be greater than ' + maxRoom);
                                                setupthursday(String(Number(maxRoom)));
                                            }
                                            else {
                                                setupthursday(String(Number(value)));
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-sm-1">
                                    <label>  Fri</label>
                                    <input type='text' className='form-control' value={upfriday}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value > maxRoom) {
                                                alert('No of Rooms should not be greater than ' + maxRoom);
                                                setupfriday(String(Number(maxRoom)));
                                            }
                                            else {
                                                setupfriday(String(Number(value)));
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-sm-1">
                                    <label>  Sat</label>
                                    <input type='text' className='form-control' value={upsaturday}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value > maxRoom) {
                                                alert('No of Rooms should not be greater than ' + maxRoom);
                                                setupsaturday(String(Number(maxRoom)));
                                            }
                                            else {
                                                setupsaturday(String(Number(value)));
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-sm-1">
                                    <label>  Sun</label>
                                    <input type='text' className='form-control' value={upsunday}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value > maxRoom) {
                                                alert('No of Rooms should not be greater than ' + maxRoom);
                                                setupsunday(String(Number(maxRoom)));
                                            }
                                            else {
                                                setupsunday(String(Number(value)));
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="submit" onClick={() => handleupdateDaywise()} className="btn btn-primary">Save </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
