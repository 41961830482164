import React, { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, orderBy, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import "../../App.css";
import Menu from '../../panel/Menu';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { ClipLoader } from 'react-spinners';


function Viewdetails() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
            fethUsers()
        });
    });

    const fethUsers = async () => {
        try {
            if (userData) {
                if (loadincount) {
                    const docRef = doc(db, "users", userData.uid)
                    const docSnap = await getDoc(docRef)
                    if (docSnap.exists()) {
                        setusername(docSnap.data().username);
                        setloadincount(false)
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    const [isLoading, setIsLoading] = useState(false);
    const [loadincount, setloadincount] = useState(true);
    const [userData, setUserData] = useState();
    const { documentId } = useParams();
    const [services, setservices] = useState([]);

    const [bookingrooms, setbookingrooms] = useState([]);
    const [roomtypes, setroomtypes] = useState([]);
    const [rooms, setrooms] = useState([]);
    const [plans, setplans] = useState([]);
    const [itemDetails, setitemDetails] = useState([]);
    const [username, setusername] = useState("");

    const [extendRoom, setextendRoom] = useState(0)

    const [checkindetails, setcheckindetails] = useState([]);
    const [bookingData, setbookingData] = useState([]);
    const [roomservices, setroomservices] = useState([]);
    const [payhistory, setpayhistory] = useState([]);
    const [paymodes, setpaymodes] = useState([]);
    const [paymode1, setpaymode1] = useState("");
    const [amount1, setamount1] = useState(0);
    const [paytime1, setpaytime1] = useState(moment().format('YYYY-MM-DD HH:mm A'));
    const [paymode2, setpaymode2] = useState("");
    const [amount2, setamount2] = useState(0);
    const [paytime2, setpaytime2] = useState("");
    const [serviceamount, setserviceamount] = useState(0);
    const [rooamount, setrooamount] = useState(0);
    const [totalamount, settotalamount] = useState(0);
    const [advance, setadvance] = useState(0);
    const [remaining, setremaining] = useState(0)
    const [note, setnote] = useState("")

    const [serviceid, setserviceid] = useState("");
    const [roomid, setroomid] = useState("");
    const [servamount, setservamount] = useState("");
    const [quantity, setquantity] = useState(1);
    const [discountupto, setdiscountupto] = useState(10);
    const [discount, setdiscount] = useState(0);
    const [servicerate, setservicerate] = useState(0);

    const [custname, setcustname] = useState("");
    const [mobile, setmobile] = useState("");
    const [email, setemail] = useState("");
    const [address, setaddress] = useState("");
    const [city, setcity] = useState("");
    const [state, setstate] = useState("");
    const [gender, setgender] = useState("Male");
    const [visitpurpose, setvisitpurpose] = useState("Darshan");
    const [gstname, setgstname] = useState("");
    const [gstno, setgstno] = useState("");
    const [bookingsource, setbookingsource] = useState("Walking");
    const [documenttype, setdocumenttype] = useState("");
    const [idno, setidno] = useState("");
    const [adults, setadults] = useState("");
    const [childs, setchilds] = useState("");

    const [rtypeid, setrtypeid] = useState("");
    const [planid, setplanid] = useState("");
    const [amount, setamount] = useState(0);
    const [roomrate, setroomrate] = useState(0);

    const [damageamount, setdamageamount] = useState(0);
    const [damagenote, setdamagenote] = useState("");
    const [anydamage, setanydamage] = useState("No");

    const [exchangeNote, setexchangeNote] = useState("");
    const [durtyRoom, setdurtyRoom] = useState("Yes");




    useEffect(() => {
        fetchData1()
    }, []);


    const fetchData1 = async () => {
        try {
            const docRef = doc(db, "bookings", documentId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setbookingData(docSnap.data());
            }

            const q = query(collection(db, 'roomservices'), where('status', '==', 'Active'))
            onSnapshot(q, (querySnapshot) => {
                setroomservices(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });

            const q22 = query(collection(db, 'roomtypes'), orderBy('createdAt', 'asc'))
            onSnapshot(q22, (querySnapshot) => {
                setroomtypes(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });

            const q2 = query(collection(db, 'paymentmodes'), orderBy('createdAt', 'asc'))
            onSnapshot(q2, (querySnapshot) => {
                setpaymodes(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });

            const q3 = query(collection(db, 'checkindetails'), where('bookingid', '==', documentId), orderBy('createdAt', 'asc'))
            onSnapshot(q3, (querySnapshot) => {
                setcheckindetails(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });

            const q1 = query(collection(db, 'payhistory'), orderBy("createdAt", "asc"), where('bookingid', '==', documentId))
            onSnapshot(q1, async (querySnapshot1) => {
                const combinedData = [];
                let paymode1 = ""
                let paymode2 = ""
                for (const doc1 of querySnapshot1.docs) {
                    const docRef1 = doc(db, "paymentmodes", doc1.data().paymode1)
                    const docSnap1 = await getDoc(docRef1)
                    if (docSnap1.exists()) {
                        paymode1 = docSnap1.data().paymode;
                    } else {
                        paymode1 = ""
                    }

                    const docRef2 = doc(db, "paymentmodes", doc1.data().paymode2)
                    const docSnap2 = await getDoc(docRef2)
                    if (docSnap2.exists()) {
                        paymode2 = docSnap2.data().paymode;
                    } else {
                        paymode2 = ""
                    }

                    const date = doc1.data().createdAt.toDate();
                    const day = date.getDate().toString().padStart(2, '0');
                    const month = (date.getMonth() + 1).toString().padStart(2, '0');
                    const year = date.getFullYear();
                    const hours = date.getHours().toString().padStart(2, '0');
                    const minutes = date.getMinutes().toString().padStart(2, '0');
                    const seconds = date.getSeconds().toString().padStart(2, '0');

                    const createdat = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

                    combinedData.push({
                        id: doc1.id,
                        data: doc1.data(),
                        paymode1: paymode1,
                        paymode2: paymode2,
                        createdat: createdat,
                    });

                }
                setpayhistory(combinedData);
            });




        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }


    const handleamount1 = (e) => {
        let value = e.target.value;
        let advance = parseFloat(value) + parseFloat(amount2);
        if (parseFloat(advance) > parseFloat(serviceamount)) {
            Toast.fire({ icon: "error", title: 'Advance amount should be greater than ' + serviceamount });
            setadvance(0);
            setamount1(0)
            let rem = parseFloat(serviceamount) - parseFloat(amount2);
            setremaining(rem.toFixed(2))
            return;
        }
        setamount1(parseFloat(value, 10))
        setadvance(advance.toFixed(2));
        let rem = parseFloat(serviceamount) - parseFloat(advance);
        setremaining(rem.toFixed(2))

    }

    const handleamount2 = (e) => {
        let value = e.target.value;
        let advance = parseFloat(amount1) + parseFloat(value);
        if (parseFloat(advance) > parseFloat(serviceamount)) {
            Toast.fire({ icon: "error", title: 'Advance amount should be greater than ' + serviceamount });
            setadvance(0);
            setamount2(0)
            let rem = parseFloat(serviceamount) - parseFloat(amount1);
            setremaining(rem.toFixed(2))
            return;
        }

        setamount2(parseFloat(value, 10))
        setadvance(advance.toFixed(2));
        let rem = parseFloat(serviceamount) - parseFloat(advance);
        setremaining(rem.toFixed(2))
    }

    const handleamountx1 = (e) => {
        let value = e.target.value;
        let advance = parseFloat(value) + parseFloat(amount2);

        if (!value || isNaN(remaining) || isNaN(advance)) {
            setamount1(parseFloat(totalamount) - parseFloat(amount2))
            setadvance(parseFloat(totalamount) - parseFloat(amount2));
            let rem = 0;
            setremaining(rem.toFixed(2))
        }
        else {
            setamount1(value)
            setadvance(parseFloat(amount2) + parseFloat(value));
            let rem = parseFloat(totalamount) - (parseFloat(amount2) + parseFloat(value));
            setremaining(rem.toFixed(2))
        }

        // } else {
        //     if (parseFloat(advance) > parseFloat(totalamount)) {
        //         // Toast.fire({ icon: "error", title: 'Advance amount should be greater than ' + totalamount });
        //         setadvance(0);
        //         setremaining(totalamount)
        //         return;
        //     }
        //     setamount1(value)
        //     setadvance(advance);
        //     let rem = parseFloat(totalamount) - parseFloat(advance);
        //     setremaining(rem.toFixed(2))
        // }

    }

    const handleamountx2 = (e) => {
        let value = e.target.value;
        let advance = parseFloat(amount1) + parseFloat(value);
        if (!value || isNaN(remaining) || isNaN(advance)) {
            setamount2(totalamount)
            setadvance(advance.toFixed(2));
            let rem = parseFloat(totalamount) - parseFloat(advance);
            setremaining(rem.toFixed(2))
        }
        if (parseFloat(remaining) < 0) {
            setamount2(parseFloat(totalamount) - parseFloat(amount1))
            setadvance(advance);
            let rem = parseFloat(totalamount) - parseFloat(advance);
            setremaining(rem.toFixed(2))
        } else {
            if (parseFloat(advance) > parseFloat(totalamount)) {
                // Toast.fire({ icon: "error", title: 'Advance amount should be greater than ' + totalamount });
                setadvance(0);
                setremaining(totalamount)
                return;
            }
            setamount2(value)
            setadvance(advance);
            let rem = parseFloat(totalamount) - parseFloat(amount1);
            setremaining(rem.toFixed(2))
        }

    }

    const handleamount3 = (e) => {
        let value = e.target.value;
        let advance = parseFloat(value) + parseFloat(amount2);
        if (parseFloat(advance) > parseFloat(rooamount)) {
            Toast.fire({ icon: "error", title: 'Advance amount should be greater than ' + rooamount });
            setadvance(0);
            setamount1(0)
            let rem = parseFloat(rooamount) - parseFloat(amount2);
            setremaining(rem.toFixed(2))
            return;
        }
        setamount1(parseFloat(value, 10))
        setadvance(advance.toFixed(2));
        let rem = parseFloat(rooamount) - parseFloat(advance) + parseFloat(bookingData.remaining);
        setremaining(rem.toFixed(2))
    }

    const handleamount4 = (e) => {
        let value = e.target.value;
        let advance = parseFloat(amount1) + parseFloat(value);
        if (parseFloat(advance) > parseFloat(rooamount)) {
            Toast.fire({ icon: "error", title: 'Advance amount should be greater than ' + rooamount });
            setadvance(0);
            setamount2(0)
            let rem = parseFloat(rooamount) - parseFloat(amount1);
            setremaining(rem.toFixed(2))
            return;
        }

        setamount2(parseFloat(value, 10))
        setadvance(advance.toFixed(2));
        let rem = parseFloat(rooamount) - parseFloat(advance) + parseFloat(bookingData.remaining);
        setremaining(rem.toFixed(2))
    }

    const handleamount5 = (e) => {
        let value = e.target.value;
        let advance = parseFloat(value) + parseFloat(amount2);
        if (parseFloat(advance) > parseFloat(totalamount)) {
            Toast.fire({ icon: "error", title: 'Advance amount should be greater than ' + totalamount });
            setadvance(0);
            setamount1(0)
            let rem = parseFloat(totalamount) - parseFloat(amount2);
            setremaining(rem.toFixed(2))
            return;
        }
        setamount1(parseFloat(value, 10))
        setadvance(advance.toFixed(2));
        let rem = parseFloat(totalamount) - parseFloat(advance);
        setremaining(rem.toFixed(2))

    }

    const handleamount6 = (e) => {
        let value = e.target.value;
        let advance = parseFloat(amount1) + parseFloat(value);
        if (parseFloat(advance) > parseFloat(totalamount)) {
            Toast.fire({ icon: "error", title: 'Advance amount should be greater than ' + totalamount });
            setadvance(0);
            setamount2(0)
            let rem = parseFloat(totalamount) - parseFloat(amount1);
            setremaining(rem.toFixed(2))
            return;
        }

        setamount2(parseFloat(value, 10))
        setadvance(advance.toFixed(2));
        let rem = parseFloat(totalamount) - parseFloat(advance);
        setremaining(rem.toFixed(2))
    }

    const removeItem = (itemToRemove) => {
        if (window.confirm(`Are you sure you want to remove`)) {
            const updatedItems = services.filter((item) => item !== itemToRemove);
            setservices(updatedItems);

            let tamount = 0;
            for (let i = 0; i < updatedItems.length; i++) {
                tamount = parseFloat(tamount) + parseFloat(updatedItems[i].servicerate);
            }
            let rxamount = parseFloat(bookingData.noofdays) * parseFloat(tamount);
            setserviceamount(rxamount.toFixed(2))
            let rem = parseFloat(rxamount) - parseFloat(advance)
            setremaining(rem.toFixed(2))
        }
    };

    const removeItem1 = (itemToRemove) => {
        if (window.confirm(`Are you sure you want to remove1`)) {
            const updatedItems = bookingrooms.filter((item) => item !== itemToRemove);
            setbookingrooms(updatedItems);

            let tamount = 0;
            for (let i = 0; i < updatedItems.length; i++) {
                tamount = parseFloat(tamount) + parseFloat(updatedItems[i].roomrate);
            }
            let rxamount = parseFloat(bookingData.noofdays) * parseFloat(tamount);
            setrooamount(rxamount.toFixed(2))
            let rem = parseFloat(rxamount) - parseFloat(advance)
            setremaining(rem.toFixed(2))
        }
    };

    const handleServiceArray = async () => {

        let num1 = quantity.toString();
        let num2 = discount.toString();

        if (!roomid || !serviceid || !servamount || !num1 || !num2 || !servicerate) {
            Toast.fire({ icon: "error", title: 'Required all fields' });
            return;
        }

        let roomname;
        let servicename;

        const docRef = doc(db, "roomdetails", roomid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            roomname = docSnap.data().roomno;
        } else {
            roomname = "";
        }

        const docRef1 = doc(db, "roomservices", serviceid);
        const docSnap1 = await getDoc(docRef1);
        if (docSnap1.exists()) {
            servicename = docSnap1.data().roomservice;
        } else {
            servicename = "";
        }

        const newItem = { roomid, serviceid, servamount, quantity, discount, servicerate, roomname, servicename, username: username };
        setservices([...services, newItem]);
        setserviceid("");
        setroomid("");
        setservamount(0);
        setquantity(1);
        setdiscountupto(10);
        setdiscount(0);
        setservicerate(0);

        let tamount = 0;
        for (let i = 0; i < services.length; i++) {
            tamount = parseFloat(tamount) + parseFloat(services[i].servicerate);
        }
        let rxamount = parseFloat(tamount) + parseFloat(servicerate);
        let newamount = parseFloat(bookingData.noofdays) * parseFloat(rxamount)
        setserviceamount(newamount.toFixed(2))
        let rem = parseFloat(newamount) - parseFloat(advance)
        setremaining(rem.toFixed(2))
    }


    function caltotal(qty, dis, amnt) {
        let rtot = parseFloat(qty) * parseFloat(amnt);
        let discount = parseFloat(rtot) * parseFloat(dis) / 100;
        let dirate = parseFloat(rtot) - parseFloat(discount);
        dirate = parseFloat(dirate)
        setservicerate(dirate.toFixed(2));

    }


    const handleServiceid = async (e) => {
        setserviceid(e.target.value);
        const docRef1 = doc(db, "roomservices", e.target.value);
        const docSnap1 = await getDoc(docRef1);
        if (docSnap1.exists()) {
            setservamount(docSnap1.data().amount);
            caltotal(quantity, discount, docSnap1.data().amount)
        } else {
            setservamount(0);
            caltotal(quantity, discount, 0)
        }
    }

    const handleQuantity = (e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, '');
        if (parseFloat(value) < 1 || !value) {
            caltotal(1, discount, servamount)
            setquantity(1);
        } else {
            caltotal(value, discount, servamount)
            setquantity(value)
        }
    }

    const handleDiscount = (e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, '');
        if (parseFloat(value < 0) || !value) {
            setdiscount(0)
        }
        if (parseFloat(value) > parseFloat(discountupto)) {
            Toast.fire({ icon: "error", title: 'Discount should be greater than ' + discountupto });
            setdiscount(discountupto);
            caltotal(quantity, discountupto, servamount)
            return;
        }
        else {
            setdiscount(value);
            caltotal(quantity, value, servamount)
        }
    }

    const storeService = async () => {
        if (!services) {
            Toast.fire({ icon: "error", title: 'Please Select at least 1 Service' });
            return
        }

        let amt1 = amount1.toString();
        let amt2 = amount2.toString();

        if (isNaN(advance)) {
            Toast.fire({ icon: "error", title: 'Please fill required fields in payment details' });
            return
        }
        if (advance !== 0) {
            if (!paymode1 || !amt1 || !paymode2 || !amt2) {
                Toast.fire({ icon: "error", title: 'Please fill required fields in payment details' });
                return
            }
        }

        setIsLoading(true)

        const docRef = doc(db, "bookings", documentId);
        const docSnap = await getDoc(docRef);
        let oldservices = []
        if (docSnap.exists()) {
            let totalt = parseFloat(serviceamount) + parseFloat(docSnap.data().totalamount);
            let totals = parseFloat(serviceamount) + parseFloat(docSnap.data().serviceamount);
            let totalp = parseFloat(advance) + parseFloat(docSnap.data().advance);
            let totalr = parseFloat(remaining) + parseFloat(docSnap.data().remaining);

            for (let i = 0; i < docSnap.data().services.length; i++) {
                oldservices.push(docSnap.data().services[i]);
            }

            for (let i = 0; i < services.length; i++) {
                oldservices.push(services[i]);
            }

            await updateDoc(doc(db, "bookings", documentId), {
                services: oldservices,
                serviceamount: parseFloat(totals),
                totalamount: parseFloat(totalt),
                advance: parseFloat(totalp),
                remaining: parseFloat(totalr),
            });

            await addDoc(collection(db, 'bookingservices'), {
                bookingid: documentId,
                customerId: docSnap.data().customerId,
                customers: docSnap.data().customers,
                uid: userData.uid,
                createdAt: Timestamp.now(),
                oldservices
            });

            if (advance !== 0) {
                await addDoc(collection(db, 'payhistory'), {
                    bookingid: documentId,
                    customerId: docSnap.data().customerId,
                    customers: docSnap.data().customers,
                    paymode1: paymode1,
                    amount1: parseFloat(amount1),
                    paytime1: paytime1,
                    paymode2: paymode2,
                    amount2: parseFloat(amount2),
                    paytime2: paytime2,
                    note: note,
                    uid: userData.uid,
                    username: username,
                    type: 'Extra Service',
                    status: 'Working',
                    createdAt: Timestamp.now(),
                });
            }

            setserviceid(""); setroomid(""); setservamount(""); setquantity(1); setdiscountupto(10);
            setdiscount(0); setservicerate(0); fetchData1(); setservices([]); setpaymode1(""); setamount1(0); setpaytime1(moment().format('YYYY-MM-DD HH:mm A')); setpaymode2("");
            setamount2(0); setpaytime2(moment().format('YYYY-MM-DD HH:mm A')); setserviceamount(0); setadvance(0); setremaining(0); setnote("")
            Toast.fire({ icon: "success", title: 'Extra services successfully added' });
        }
        setIsLoading(false)
    }

    const getCustData = () => {
        setcustname(bookingData.customers.custname);
        setmobile(bookingData.customers.mobile);
        setemail(bookingData.customers.email);
        setaddress(bookingData.customers.address);
        setcity(bookingData.customers.city);
        setstate(bookingData.customers.state);
        setgender(bookingData.customers.gender);
        setgstname(bookingData.customers.gstname);
        setgstno(bookingData.customers.gstno);
        setdocumenttype(bookingData.customers.documenttype);
        setidno(bookingData.customers.idno);
        setvisitpurpose(bookingData.visitpurpose);
        setbookingsource(bookingData.bookingsource);
        setadults(bookingData.adults);
        setchilds(bookingData.childs);
    }

    const updateCust = async () => {
        const customerData = {
            custname: custname,
            mobile: mobile,
            email: email,
            address: address,
            city: city,
            state: state,
            gender: gender,
            gstname: gstname,
            gstno: gstno,
            documenttype: documenttype,
            idno: idno,
            uid: userData.uid,
            createdAt: Timestamp.now(),
        };

        await updateDoc(doc(db, "bookings", documentId), {
            customers: customerData,
            bookingsource: bookingsource,
            visitpurpose: visitpurpose,
            adults: adults,
            childs: childs,
        });

        await updateDoc(doc(db, "customers", bookingData.customerId), {
            customers: customerData,
            bookingsource: bookingsource,
            visitpurpose: visitpurpose,
        });
        fetchData1()
        Toast.fire({ icon: "success", title: 'Customer Details Successfully Updated' });

    }

    const handlemobile = (e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, '');
        if (value.length <= 10) {
            setmobile(value);
        }

    }


    const handleroomtypeChange = (value) => {
        setrtypeid(value);
        setamount(0);
        setroomrate(0);
        setdiscount(0);
        setdiscountupto(0);
        settotalamount(0);
        setrooamount(0)
        setadvance(0)
        setremaining(0)

        const q = query(collection(db, 'roomdetails'), where('rtypeid', '==', value), where('status', '==', 'Available'))
        onSnapshot(q, (querySnapshot) => {
            setrooms(querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })));
        });

        const q1 = query(collection(db, 'roomtarrifs'), where('rtypeid', '==', value))
        onSnapshot(q1, (querySnapshot) => {
            setplans(querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })));
        });
    }

    const handleplanChange = async (value) => {
        setplanid(value);
        try {
            const userDocRef = doc(db, 'roomtarrifs', value);
            const userDocSnap = await getDoc(userDocRef);

            if (userDocSnap.exists()) {
                setamount(userDocSnap.data().amount);
                setroomrate(userDocSnap.data().amount);
                setdiscount(0);
                setdiscountupto(userDocSnap.data().discount);
            } else {
                Toast.fire({
                    icon: "error",
                    title: 'No Data Found'
                });

            }
        } catch (error) {
            console.error('Error fetching document:', error);
        }
    }

    const handleplanChange1 = async (value) => {
        setamount(0);
        setroomrate(0);
        setrooamount(0);
        setremaining(0);
        setdiscount(0);
        setdiscountupto(0);
        setplanid(value);
        try {
            const userDocRef = doc(db, 'roomtarrifs', value);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
                let xAmount = parseFloat(userDocSnap.data().amount) - parseFloat(itemDetails.roomrate);
                setamount(userDocSnap.data().amount);
                setroomrate(userDocSnap.data().amount);
                setrooamount(xAmount.toFixed(2));
                setremaining(xAmount.toFixed(2));
                setdiscount(0);
                setdiscountupto(userDocSnap.data().discount);
            } else {

            }
        } catch (error) {
            console.error('Error fetching document:', error);
        }
    }

    const handleRoomArray = async () => {
        if (!roomid || !rtypeid || !planid || !amount || !roomrate) {
            Toast.fire({ icon: "error", title: 'Required all fields' });
            return;
        }

        let roomname;
        let roomtypename;
        let plananme;

        const docRef = doc(db, "roomdetails", roomid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            roomname = docSnap.data().roomno;
        } else {
            roomname = "";
        }

        const docRef1 = doc(db, "roomtypes", rtypeid);
        const docSnap1 = await getDoc(docRef1);
        if (docSnap1.exists()) {
            roomtypename = docSnap1.data().roomtype;
        } else {
            roomtypename = "";
        }

        const docRef2 = doc(db, "roomtarrifs", planid);
        const docSnap2 = await getDoc(docRef2);
        if (docSnap2.exists()) {
            plananme = docSnap2.data().rateplan;
        } else {
            plananme = "";
        }

        const newItem = { roomid, rtypeid, planid, amount, discount, roomrate, roomname, roomtypename, plananme, username: username };

        setbookingrooms([...bookingrooms, newItem]);
        setrtypeid("");
        setroomid("");
        setplanid("");
        setamount("");
        setdiscountupto(0);
        setdiscount("");
        setroomrate("");
        let tamount = 0;
        for (let i = 0; i < bookingrooms.length; i++) {
            tamount = parseFloat(tamount) + parseFloat(bookingrooms[i].roomrate);
        }
        let rxamount = parseFloat(tamount) + parseFloat(roomrate)
        let newamount = parseFloat(bookingData.noofdays) * parseFloat(rxamount)

        calFinalTotal(bookingData.noofdays, rxamount);
        setrooamount(newamount.toFixed(2))

        let rem = parseFloat(newamount) - parseFloat(advance)
        setremaining(rem.toFixed(2))
    }

    const calFinalTotal = (days, roomamountxx) => {
        let total = parseFloat(days) * parseFloat(roomamountxx)
        settotalamount(total.toFixed(2));
    }

    const emptyRooms = () => {
        setpaymode1(""); setamount1(0); setpaytime1(moment().format('YYYY-MM-DD HH:mm A')); setpaymode2(""); setamount2(0); setpaytime2(moment().format('YYYY-MM-DD HH:mm A'));
        setserviceamount(0); setrooamount(0); settotalamount(0); setadvance(0); setremaining(0);
        setbookingrooms([]); setservices([]); setextendRoom(0)
    }
    const emptyRooms1 = () => {
        setpaymode1(""); setamount1(0); setpaytime1(moment().format('YYYY-MM-DD HH:mm A')); setpaymode2(""); setamount2(0); setpaytime2(moment().format('YYYY-MM-DD HH:mm A'));
        setserviceamount(0); setrooamount(0); setadvance(0); setbookingrooms([]); setservices([]);
        settotalamount(bookingData.remaining.toFixed(2)); setremaining(bookingData.remaining.toFixed(2));
    }
    const emptyRooms2 = (item) => {
        setitemDetails(item); setrtypeid(""); setroomid(""); setplanid(""); setamount(0); setdiscountupto(0); setdiscount(0); setroomrate(0); setpaymode1(""); setamount1(0);
        setpaytime1(moment().format('YYYY-MM-DD HH:mm A')); setpaymode2(""); setamount2(0); setpaytime2(moment().format('YYYY-MM-DD HH:mm A')); setserviceamount(0); setrooamount(0); settotalamount(0); setadvance(0); setremaining(0);
        setbookingrooms([]); setservices([]); setextendRoom(0)
    }

    function caltotal1(value) {
        setdiscount(value);
        if (value) {
            if (parseFloat(value) > parseFloat(discountupto)) {
                Toast.fire({ icon: "error", title: 'Discount should not be greater than ' + discountupto });
                setdiscount(discountupto);
                let roomr = parseFloat(amount) * parseFloat(discountupto) / 100;
                let xamount = (parseFloat(amount) - parseFloat(roomr));
                setroomrate(xamount.toFixed(2));
            } else {
                let roomr = parseFloat(amount) * parseFloat(value) / 100;
                let xamount = (parseFloat(amount) - parseFloat(roomr));
                setroomrate(xamount.toFixed(2));
            }
        }
        else {
            setdiscount(0)
        }
    }


    function caltotal2(value) {
        setdiscount(value);
        if (value) {
            if (parseFloat(value) > parseFloat(discountupto)) {
                Toast.fire({ icon: "error", title: 'Discount should not be greater than ' + discountupto });
                setdiscount(discountupto);
                let roomr = parseFloat(amount) * parseFloat(discountupto) / 100;
                let xamount = (parseFloat(amount) - parseFloat(roomr));
                setroomrate(xamount.toFixed(2));
                let rem = parseFloat(xamount) - parseFloat(itemDetails.roomrate);
                setrooamount(rem.toFixed(2));
            } else {
                let roomr = parseFloat(amount) * parseFloat(value) / 100;
                let xamount = (parseFloat(amount) - parseFloat(roomr));
                setroomrate(xamount.toFixed(2));
                let rem = parseFloat(xamount) - parseFloat(itemDetails.roomrate);
                setrooamount(rem.toFixed(2));
            }
        }
        else {
            setdiscount(0)
        }
    }


    const storeRoom = async () => {
        if (!rooms) {
            Toast.fire({ icon: "error", title: 'Please Select at least 1 Service' });
            return
        }

        let amt1 = amount1.toString();
        let amt2 = amount2.toString();

        if (isNaN(advance)) {
            Toast.fire({ icon: "error", title: 'Please fill required fields in payment details' });
            return
        }

        if (advance !== 0) {
            if (!paymode1 || !amt1 || !paymode2 || !amt2) {
                if (amount1 < 0 || amount2 < 0) {
                    Toast.fire({ icon: "error", title: 'Please fill required fields in payment details' });
                    return
                }
                Toast.fire({ icon: "error", title: 'Please fill required fields in payment details' });
                return
            }
        }

        setIsLoading(true)

        const docRef = doc(db, "bookings", documentId);
        const docSnap = await getDoc(docRef);
        let oldrooms = []
        if (docSnap.exists()) {
            let totals = (parseFloat(rooamount) / parseFloat(bookingData.noofdays)) + parseFloat(docSnap.data().rooamount);
            let totalt = parseFloat(rooamount) + parseFloat(docSnap.data().totalamount);
            let totalp = parseFloat(advance) + parseFloat(docSnap.data().advance);
            let totalr = parseFloat(remaining) + parseFloat(docSnap.data().remaining);


            for (let i = 0; i < docSnap.data().noofdays; i++) {
                for (let j = 0; j < bookingrooms.length; j++) {
                    await addDoc(collection(db, 'checkindetails'), {
                        bookingid: documentId,
                        customerId: docSnap.data().customerId,
                        checkindate: moment(docSnap.data().checkindate).add(i, 'days').format('YYYY-MM-DD'),
                        checkoutdate: moment(docSnap.data().checkindate).add(i + 1, 'days').format('YYYY-MM-DD'),
                        roomid: bookingrooms[j].roomid,
                        roomtypeid: bookingrooms[j].rtypeid,
                        planid: bookingrooms[j].planid,
                        roomname: bookingrooms[j].roomname,
                        planname: bookingrooms[j].plananme,
                        roomtype: bookingrooms[j].roomtypename,
                        uid: userData.uid,
                        username: username,
                        status: 'Booked',
                        createdAt: Timestamp.now(),
                    });
                }
            }

            for (let i = 0; i < docSnap.data().rooms.length; i++) {
                oldrooms.push(docSnap.data().rooms[i]);
            }
            for (let i = 0; i < bookingrooms.length; i++) {
                oldrooms.push(bookingrooms[i]);
            }


            await updateDoc(doc(db, "bookings", documentId), {
                rooms: oldrooms,
                rooamount: parseFloat(totals),
                totalamount: parseFloat(totalt),
                advance: parseFloat(totalp),
                remaining: parseFloat(totalr),
                noofrooms: oldrooms.length
            });

            await addDoc(collection(db, 'bookingrooms'), {
                bookingid: documentId,
                customerId: docSnap.data().customerId,
                customers: docSnap.data().customers,
                uid: userData.uid,
                createdAt: Timestamp.now(),
                bookingrooms,
            });

            if (advance !== 0) {
                await addDoc(collection(db, 'payhistory'), {
                    bookingid: documentId,
                    customerId: docSnap.data().customerId,
                    customers: docSnap.data().customers,
                    paymode1: paymode1,
                    amount1: parseFloat(amount1),
                    paytime1: paytime1,
                    paymode2: paymode2,
                    amount2: parseFloat(amount2),
                    paytime2: paytime2,
                    note: note,
                    uid: userData.uid,
                    username: username,
                    type: 'New Room Payments',
                    status: 'Working',
                    createdAt: Timestamp.now(),
                });
            }

            for (let i = 0; i < oldrooms.length; i++) {
                await updateDoc(doc(db, "roomdetails", oldrooms[i].roomid), {
                    status: "Booked",
                });
            }


            setrtypeid(""); setplanid(""); setamount(0); setroomrate(0); setserviceid(""); setroomid(""); setservamount("");
            setquantity(1); setdiscountupto(10); setdiscount(0); setservicerate(0); fetchData1(); setservices([]);
            setpaymode1(""); setamount1(0); setpaytime1(moment().format('YYYY-MM-DD HH:mm A')); setpaymode2(""); setrooms([]); setbookingrooms([])
            setamount2(0); setpaytime2(moment().format('YYYY-MM-DD HH:mm A')); setserviceamount(0); setadvance(0); setremaining(0); setnote(""); setrooamount(0); settotalamount(0)
            Toast.fire({ icon: "success", title: 'New Rooms successfully added' });
        }

        setIsLoading(false)
    }

    const storePayment = async () => {
        let amt1 = amount1.toString();
        let amt2 = amount2.toString();

        if (isNaN(advance)) {
            Toast.fire({ icon: "error", title: 'Please fill required fields in payment details' });
            return
        }

        if (advance !== 0) {
            if (!paymode1 || !amt1 || !paymode2 || !amt2) {
                if (amount1 < 0 || amount2 < 0) {
                    Toast.fire({ icon: "error", title: 'Please fill required fields in payment details' });
                    return
                }
                Toast.fire({ icon: "error", title: 'Please fill required fields in payment details' });
                return
            }
        }
        const docRef = doc(db, "bookings", documentId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            let totalp = parseFloat(advance) + parseFloat(docSnap.data().advance);
            let totalr = parseFloat(docSnap.data().totalamount) - parseFloat(totalp);

            await updateDoc(doc(db, "bookings", documentId), {
                advance: parseFloat(totalp),
                remaining: parseFloat(totalr),
            });
            if (advance !== 0) {
                await addDoc(collection(db, 'payhistory'), {
                    bookingid: documentId,
                    customerId: docSnap.data().customerId,
                    customers: docSnap.data().customers,
                    paymode1: paymode1,
                    amount1: parseFloat(amount1),
                    paytime1: paytime1,
                    paymode2: paymode2,
                    amount2: parseFloat(amount2),
                    paytime2: paytime2,
                    note: note,
                    uid: userData.uid,
                    username: username,
                    status: 'Working',
                    type: 'Payments Taken from Customers',
                    createdAt: Timestamp.now(),
                });
            }
        }

        setrtypeid(""); setplanid(""); setamount(0); setroomrate(0); setserviceid(""); setroomid(""); setservamount("");
        setquantity(1); setdiscountupto(10); setdiscount(0); setservicerate(0); fetchData1(); setservices([]);
        setpaymode1(""); setamount1(0); setpaytime1(moment().format('YYYY-MM-DD HH:mm A')); setpaymode2(""); setrooms([]); setbookingrooms([])
        setamount2(0); setpaytime2(moment().format('YYYY-MM-DD HH:mm A')); setserviceamount(0); setadvance(0); setremaining(0); setnote(""); setrooamount(0); settotalamount(0)
        Toast.fire({ icon: "success", title: 'Payment successfully added' });
    }

    const storePayment1 = async () => {
        let amt1 = amount1.toString();
        let amt2 = amount2.toString();

        if (isNaN(advance)) {
            Toast.fire({ icon: "error", title: 'Please fill required fields in payment details' });
            return
        }

        if (advance !== 0) {
            if (!paymode1 || !amt1 || !paymode2 || !amt2) {
                if (amount1 < 0 || amount2 < 0) {
                    Toast.fire({ icon: "error", title: 'Please fill required fields in payment details' });
                    return
                }
                Toast.fire({ icon: "error", title: 'Please fill required fields in payment details' });
                return
            }
        }
        const docRef = doc(db, "bookings", documentId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            let totalp = parseFloat(docSnap.data().advance) + parseFloat(advance);
            let totalr = parseFloat(docSnap.data().totalamount.toFixed(2)) - parseFloat(totalp.toFixed(2));


            await updateDoc(doc(db, "bookings", documentId), {
                advance: parseFloat(totalp),
                remaining: parseFloat(totalr),
            });
            if (advance !== 0) {
                await addDoc(collection(db, 'payhistory'), {
                    bookingid: documentId,
                    customerId: docSnap.data().customerId,
                    customers: docSnap.data().customers,
                    paymode1: paymode1,
                    amount1: parseFloat(amount1),
                    paytime1: paytime1,
                    paymode2: paymode2,
                    amount2: parseFloat(amount2),
                    paytime2: paytime2,
                    note: note,
                    uid: userData.uid,
                    username: username,
                    status: 'Working',
                    type: 'Payments given to Customers',
                    createdAt: Timestamp.now(),
                });
            }
        }

        setrtypeid(""); setplanid(""); setamount(0); setroomrate(0); setserviceid(""); setroomid(""); setservamount("");
        setquantity(1); setdiscountupto(10); setdiscount(0); setservicerate(0); fetchData1(); setservices([]);
        setpaymode1(""); setamount1(0); setpaytime1(moment().format('YYYY-MM-DD HH:mm A')); setpaymode2(""); setrooms([]); setbookingrooms([])
        setamount2(0); setpaytime2(moment().format('YYYY-MM-DD HH:mm A')); setserviceamount(0); setadvance(0); setremaining(0); setnote(""); setrooamount(0); settotalamount(0)
        Toast.fire({ icon: "success", title: 'Payment successfully added' });
    }

    const handleExtendRoom = (e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, '');
        if (value.length <= 2 || value) {
            setextendRoom(value);
            let tot = parseFloat(value) * (parseFloat(bookingData.rooamount) + parseFloat(bookingData.serviceamount));
            settotalamount(tot.toFixed(2));
        }
    }

    const extendRoomStore = async () => {
        let amt1 = amount1.toString();
        let amt2 = amount2.toString();

        if (isNaN(advance)) {
            Toast.fire({ icon: "error", title: 'Please fill required fields in payment details' });
            return
        }
        if (extendRoom < 1 || !extendRoom) {
            Toast.fire({ icon: "error", title: 'Please Enter No of day ' });
            return
        }

        if (advance !== 0) {
            if (!paymode1 || !amt1 || !paymode2 || !amt2) {
                if (amount1 < 0 || amount2 < 0) {
                    Toast.fire({ icon: "error", title: 'Please fill required fields in payment details' });
                    return
                }
                Toast.fire({ icon: "error", title: 'Please fill required fields in payment details' });
                return
            }
        }
        const docRef = doc(db, "bookings", documentId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            let totala = parseFloat(totalamount) + parseFloat(docSnap.data().totalamount);
            let totalp = parseFloat(advance) + parseFloat(docSnap.data().advance);
            let totalr = parseFloat(totala) - parseFloat(totalp)


            let checkout = moment(bookingData.checkoutdate).add(extendRoom, 'days').format('YYYY-MM-DD')
            await updateDoc(doc(db, "bookings", documentId), {
                totalamount: parseFloat(totala.toFixed(2)),
                advance: parseFloat(totalp.toFixed(2)),
                remaining: parseFloat(totalr.toFixed(2)),
                checkoutdate: checkout,
                noofdays: parseFloat(bookingData.noofdays) + parseFloat(extendRoom),
            });
            if (advance !== 0) {
                await addDoc(collection(db, 'payhistory'), {
                    bookingid: documentId,
                    customerId: docSnap.data().customerId,
                    customers: docSnap.data().customers,
                    paymode1: paymode1,
                    amount1: parseFloat(amount1),
                    paytime1: paytime1,
                    paymode2: paymode2,
                    amount2: parseFloat(amount2),
                    paytime2: paytime2,
                    note: note,
                    uid: userData.uid,
                    status: 'Working',
                    type: 'Room Extension Payment',
                    username: username,
                    createdAt: Timestamp.now(),
                });
            }

            for (let i = 0; i < extendRoom; i++) {
                for (let j = 0; j < bookingData.rooms.length; j++) {
                    await addDoc(collection(db, 'checkindetails'), {
                        bookingid: documentId,
                        customerId: bookingData.customerId,
                        checkindate: moment(bookingData.checkoutdate).add(i, 'days').format('YYYY-MM-DD'),
                        checkoutdate: moment(bookingData.checkoutdate).add(i + 1, 'days').format('YYYY-MM-DD'),
                        roomid: bookingData.rooms[j].roomid,
                        roomtypeid: bookingData.rooms[j].rtypeid,
                        planid: bookingData.rooms[j].planid,
                        roomname: bookingData.rooms[j].roomname,
                        planname: bookingData.rooms[j].plananme,
                        roomtype: bookingData.rooms[j].roomtypename,
                        uid: userData.uid,
                        username: username,
                        status: 'Booked',
                        createdAt: Timestamp.now(),
                    });
                }
            }
        }

        setrtypeid(""); setplanid(""); setamount(0); setroomrate(0); setserviceid(""); setroomid(""); setservamount("");
        setquantity(1); setdiscountupto(10); setdiscount(0); setservicerate(0); fetchData1(); setservices([]);
        setpaymode1(""); setamount1(0); setpaytime1(moment().format('YYYY-MM-DD HH:mm A')); setpaymode2(""); setrooms([]); setbookingrooms([])
        setamount2(0); setpaytime2(moment().format('YYYY-MM-DD HH:mm A')); setserviceamount(0); setadvance(0); setremaining(0); setnote(""); setrooamount(0); settotalamount(0)
        Toast.fire({ icon: "success", title: 'Room Successfully Extended  ' });
    }

    const storeRoomExchange = async () => {
        if (!roomid || !rtypeid || !planid || !amount || !roomrate) {
            Toast.fire({ icon: "error", title: 'Please select another room with amount' });
            return;
        }

        let roomname;
        let roomtypename;
        let plananme;

        const docRef = doc(db, "roomdetails", roomid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            roomname = docSnap.data().roomno;
        } else {
            roomname = "";
        }

        const docRef1 = doc(db, "roomtypes", rtypeid);
        const docSnap1 = await getDoc(docRef1);
        if (docSnap1.exists()) {
            roomtypename = docSnap1.data().roomtype;
        } else {
            roomtypename = "";
        }

        const docRef2 = doc(db, "roomtarrifs", planid);
        const docSnap2 = await getDoc(docRef2);
        if (docSnap2.exists()) {
            plananme = docSnap2.data().rateplan;
        } else {
            plananme = "";
        }
        let newArray = [];
        const newItem1X = { roomid, rtypeid, planid, amount, discount, roomrate, roomname, roomtypename, plananme, username: username, status: "Exchanged", exchangeNote: exchangeNote };
        for (let i = 0; i < bookingData.rooms.length; i++) {
            if (bookingData.rooms[i].roomid !== itemDetails.roomid) {
                newArray.push(bookingData.rooms[i])
            }
        }
        newArray.push(newItem1X);

        let newroomamount = parseFloat(bookingData.rooamount) - parseFloat(itemDetails.roomrate)

        let tttttttttttt = (parseFloat(newroomamount) + parseFloat(bookingData.serviceamount) + parseFloat(roomrate)) * parseFloat(bookingData.noofdays);
        let rem = parseFloat(tttttttttttt) - parseFloat(bookingData.advance)

        await updateDoc(doc(db, "bookings", documentId), {
            totalamount: parseFloat(tttttttttttt),
            rooamount: parseFloat(newroomamount) + parseFloat(roomrate),
            remaining: parseFloat(rem),
            rooms: newArray,
        });

        let bookingrooms = newArray;

        await addDoc(collection(db, 'bookingrooms'), {
            bookingid: documentId,
            customerId: bookingData.customerId,
            customers: bookingData.customers,
            uid: userData.uid,
            createdAt: Timestamp.now(),
            bookingrooms,
        });

        await updateDoc(doc(db, "roomdetails", roomid), {
            status: "Booked",
        });

        await updateDoc(doc(db, "bookings", documentId), {
            approval1: "No",
        });

        if (durtyRoom === 'Yes') {
            await updateDoc(doc(db, "roomdetails", itemDetails.roomid), {
                status: "Cleaning",
            });
        } else {
            await updateDoc(doc(db, "roomdetails", itemDetails.roomid), {
                status: "Available",
            });
        }

        await addDoc(collection(db, 'logs'), {
            bookingid: documentId,
            customerId: bookingData.customerId,
            customers: bookingData.customers,
            rooms: bookingData.rooms,
            uid: userData.uid,
            username: username,
            createdAt: Timestamp.now(),
            type: 'Room Exchanged',
        });


        const q1 = query(collection(db, 'checkindetails'), where('bookingid', '==', documentId), where('roomid', '==', itemDetails.roomid), where('checkindate', '>=', moment().format('YYYY-MM-DD')))
        onSnapshot(q1, async (querySnapshot1) => {
            for (const doc1 of querySnapshot1.docs) {
                await updateDoc(doc(db, "checkindetails", doc1.id), {
                    roomid: newItem1X.roomid,
                    roomtypeid: newItem1X.rtypeid,
                    planid: newItem1X.planid,
                    roomname: newItem1X.roomname,
                    planname: newItem1X.plananme,
                    roomtype: newItem1X.roomtypename,
                });
            }
        });


        setrtypeid(""); setplanid(""); setamount(0); setroomrate(0); setserviceid(""); setroomid(""); setservamount("");
        setquantity(1); setdiscountupto(10); setdiscount(0); setservicerate(0); fetchData1(); setservices([]);
        setpaymode1(""); setamount1(0); setpaytime1(moment().format('YYYY-MM-DD HH:mm A')); setpaymode2(""); setrooms([]); setbookingrooms([]);
        setamount2(0); setpaytime2(moment().format('YYYY-MM-DD HH:mm A')); setserviceamount(0); setadvance(0); setremaining(0); setnote(""); setrooamount(0); settotalamount(0)
        Toast.fire({ icon: "success", title: 'Room Successfully Exchanged  ' })
    }

    const cancleExtraService = async (item) => {

        if (window.confirm(`Are you sure you want to Cancle Extra Service`)) {
            let newArray = [];
            for (let i = 0; i < bookingData.services.length; i++) {
                if (bookingData.services[i].serviceid !== item.serviceid) {
                    newArray.push(bookingData.services[i])
                }
            }

            let newservicerate = parseFloat(item.servicerate) * parseFloat(bookingData.noofdays)
            let newrserviceamount = parseFloat(bookingData.serviceamount) - parseFloat(newservicerate)
            let newrtotamount = parseFloat(bookingData.totalamount) - parseFloat(newservicerate)
            let newRemaining = parseFloat(newrtotamount) - parseFloat(bookingData.advance)

            await updateDoc(doc(db, "bookings", documentId), {
                totalamount: parseFloat(newrtotamount),
                serviceamount: parseFloat(newrserviceamount),
                remaining: parseFloat(newRemaining),
                services: newArray,
            });

            let oldservices = newArray;
            await addDoc(collection(db, 'bookingservices'), {
                bookingid: documentId,
                customerId: bookingData.customerId,
                customers: bookingData.customers,
                uid: userData.uid,
                createdAt: Timestamp.now(),
                oldservices
            });

            await addDoc(collection(db, 'logs'), {
                bookingid: documentId,
                customerId: bookingData.customerId,
                customers: bookingData.customers,
                services: item,
                uid: userData.uid,
                username: username,
                createdAt: Timestamp.now(),
                type: 'Extra Service Cancled',
            });

            await updateDoc(doc(db, "bookings", documentId), {
                approval3: "No",
            });


            setrtypeid(""); setplanid(""); setamount(0); setroomrate(0); setserviceid(""); setroomid(""); setservamount("");
            setquantity(1); setdiscountupto(10); setdiscount(0); setservicerate(0); fetchData1(); setservices([]);
            setpaymode1(""); setamount1(0); setpaytime1(moment().format('YYYY-MM-DD HH:mm A')); setpaymode2(""); setrooms([]); setbookingrooms([]);
            setamount2(0); setpaytime2(moment().format('YYYY-MM-DD HH:mm A')); setserviceamount(0); setadvance(0); setremaining(0); setnote(""); setrooamount(0); settotalamount(0)
            Toast.fire({ icon: "success", title: 'Extra Service Successfully Cancled ' })
        }

    }

    const handleDamageAmount = (e) => {
        if (e.target.value) {
            setdamageamount(parseFloat(e.target.value, 10));
            let tot = parseFloat(bookingData.remaining) + parseFloat(e.target.value);
            setremaining(parseFloat(tot));
            settotalamount(parseFloat(tot))
        }
        else {
            setdamageamount(0);
            setremaining(parseFloat(bookingData.remaining));
            settotalamount(parseFloat(bookingData.remaining))
        }

    }

    const handleCheckout = async () => {

        setIsLoading(true)

        if (anydamage === 'Yes') {
            await addDoc(collection(db, 'damages'), {
                bookingid: documentId,
                customerId: bookingData.customerId,
                customers: bookingData.customers,
                rooms: bookingData.rooms,
                damageamount: damageamount,
                damagenote: damagenote,
                uid: userData.uid,
                username: username,
                createdAt: Timestamp.now(),
            });
        }

        let amt1 = amount1.toString();
        let amt2 = amount2.toString();

        if (isNaN(advance)) {
            Toast.fire({ icon: "error", title: 'Please fill required fields in payment details' });
            setIsLoading(false)
            return
        }

        if (advance !== 0) {
            if (!paymode1 || !amt1 || !paymode2 || !amt2) {
                if (amount1 < 0 || amount2 < 0) {
                    Toast.fire({ icon: "error", title: 'Please fill required fields in payment details' });
                    setIsLoading(false)
                    return
                }
                Toast.fire({ icon: "error", title: 'Please fill required fields in payment details' });
                setIsLoading(false)
                return
            }
        }
        const docRef = doc(db, "bookings", documentId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            let totalt = parseFloat(bookingData.totalamount) + parseFloat(damageamount);
            let totalp = parseFloat(advance) + parseFloat(docSnap.data().advance);
            let totalr = parseFloat(totalt) - parseFloat(totalp);


            await updateDoc(doc(db, "bookings", documentId), {
                totalamount: parseFloat(totalt),
                advance: parseFloat(totalp),
                remaining: parseFloat(totalr),
                damageamount: parseFloat(damageamount),
                status: 'Checkout',
            });

            if (advance !== 0) {
                await addDoc(collection(db, 'payhistory'), {
                    bookingid: documentId,
                    customerId: docSnap.data().customerId,
                    customers: docSnap.data().customers,
                    paymode1: paymode1,
                    amount1: parseFloat(amount1),
                    paytime1: paytime1,
                    paymode2: paymode2,
                    amount2: parseFloat(amount2),
                    paytime2: paytime2,
                    note: note,
                    uid: userData.uid,
                    username: username,
                    status: 'Working',
                    type: 'Payments Taken from Customers',
                    createdAt: Timestamp.now(),
                });
            }

            const q = query(collection(db, "checkindetails"), where("bookingid", "==", documentId));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (document) => {
                await updateDoc(doc(db, "checkindetails", document.id), {
                    status: "Checkout",
                });
            });

            for (let i = 0; i < bookingData.rooms.length; i++) {
                await updateDoc(doc(db, "roomdetails", bookingData.rooms[i].roomid), {
                    status: "Cleaning",
                });

                await addDoc(collection(db, 'cleaningrooms'), {
                    bookingid: documentId,
                    roomid: bookingData.rooms[i].roomid,
                    roomname: bookingData.rooms[i].roomname,
                    uid: userData.uid,
                    username: username,
                    status: 'Cleaning',
                    cleanedby: "",
                    cleanedtime: "",
                    createdAt: Timestamp.now(),
                });
            }

            await addDoc(collection(db, 'checkouthistory'), {
                bookingid: documentId,
                invoiceno: docSnap.data().invoiceno,
                customerId: docSnap.data().customerId,
                customers: docSnap.data().customers,
                checkindate: bookingData.checkindate,
                checkoutdate: bookingData.checkoutdate,
                rooms: docSnap.data().rooms,
                uid: userData.uid,
                username: username,
                totalamount: parseFloat(totalt),
                advance: parseFloat(totalp),
                remaining: parseFloat(totalr),
                checkoutime: moment().format('DD/MM/YYYY HH:mm:ss A'),
                createdAt: Timestamp.now(),
            });
        }


        await addDoc(collection(db, 'logs'), {
            bookingid: documentId,
            customerId: bookingData.customerId,
            customers: bookingData.customers,
            uid: userData.uid,
            username: username,
            createdAt: Timestamp.now(),
            type: 'Room Checkouts',
        });

        setIsLoading(false)

        Toast.fire({ icon: "success", title: 'Room Successfully Checkout' });
        window.location.href = "/checkout-history"


    }

    return (
        <div>
            {isLoading && (
                <div style={overlayStyle}>
                    <ClipLoader color="#36d7b7" size={60} />
                </div>
            )}
            <Menu />
            {
                bookingData.customerId &&
                <div className="content-wrapper">
                    <section className="content mt-2">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="card card-primary card-outline">
                                        <div className="card-body box-profile">
                                            <h3 className="profile-username text-center">{bookingData.customers.custname} </h3>
                                            <p className="text-muted text-center">{bookingData.customers.mobile}</p>
                                            <ul className="list-group list-group-unbordered mb-3">
                                                <li className="list-group-item">
                                                    <b>Email</b> <span className="float-right">{bookingData.customers.email}</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Address</b> <span className="float-right">{bookingData.customers.city} {bookingData.customers.address} {bookingData.customers.state}</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Gender</b> <span className="float-right">{bookingData.customers.gender}</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>GST Name</b> <span className="float-right">{bookingData.customers.gstname}</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>GST No</b> <span className="float-right">{bookingData.customers.gstno}</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Document Type</b> <span className="float-right">{bookingData.customers.documenttype}</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>ID No</b> <span className="float-right">{bookingData.customers.idno}</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Visit Purpose</b> <span className="float-right">{bookingData.visitpurpose} </span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Booking Source</b> <span className="float-right">{bookingData.bookingsource} </span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Note</b> <span className="float-right">{bookingData.note} </span>
                                                </li>
                                            </ul>
                                           
                                        </div>
                                    </div>
                                    <div className='card'>
                                        <div className='card-body text-center'>
                                            <h6>Visitor Photo</h6>
                                            {
                                                bookingData.visitorPhotoUrl &&
                                                <img src={bookingData.visitorPhotoUrl} alt='img' width="100%" />
                                            }
                                        </div>
                                    </div>
                                    <div className='card'>
                                        <div className='card-body text-center'>
                                            <h6>ID Proof Front</h6>
                                            {
                                                bookingData.idFrontUrl &&
                                                <img src={bookingData.idFrontUrl} alt='img' width="100%" />
                                            }
                                        </div>
                                    </div>
                                    <div className='card'>
                                        <div className='card-body text-center'>
                                            <h6>ID Proof Back</h6>
                                            {
                                                bookingData.idBackUrl &&
                                                <img src={bookingData.idBackUrl} alt='img' width="100%" />
                                            }
                                        </div>
                                    </div>
                                    <div className='card'>
                                        <div className='card-body text-center'>
                                            <h6>Couple Photo</h6>
                                            {
                                                bookingData.couplePhotoUrl &&
                                                <img src={bookingData.couplePhotoUrl} alt='img' width="100%" />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="card card-primary card-outline">
                                        <div className="card-body">
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <div className='row'>
                                                        <div className='col-sm-6'>
                                                            <ul className="list-group ">
                                                                <li className="list-group-item">
                                                                    <b>Checkin Date</b> <span className="float-right">
                                                                        {moment(bookingData.checkindate).format('DD/MM/YYYY')} {bookingData.checkintime}
                                                                    </span>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <b>Checkout Date</b> <span className="float-right">
                                                                        {moment(bookingData.checkoutdate).format('DD/MM/YYYY')} {bookingData.checkouttime}
                                                                    </span>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <b>No Of Days</b> <span className="float-right">{bookingData.noofdays} Day</span>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <b>Adults</b> <span className="float-right">{bookingData.adults} Adults</span>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <b>Childs</b> <span className="float-right">{bookingData.childs} Childs</span>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <b>No of Rooms</b> <span className="float-right">{bookingData.noofrooms} Rooms</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <ul className="list-group ">
                                                                <li className="list-group-item">
                                                                    <b>Room Amount</b> <span className="float-right">{bookingData.rooamount.toFixed(2)} </span>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <b>Extra Service</b> <span className="float-right">{bookingData.serviceamount.toFixed(2)} </span>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <b>Restaurent Amount</b> <span className="float-right">{bookingData.restoamount.toFixed(2)} </span>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <b>Total Amount</b> <span className="float-right">{bookingData.totalamount.toFixed(2)} </span>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <b>Paid </b> <span className="float-right">{bookingData.advance.toFixed(2)}</span>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <b style={{ color: "red" }}>Remaining</b> <b className="float-right" style={{ color: "red" }}>{bookingData.remaining.toFixed(2)}</b>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-content">
                                                <div className="active tab-pane" id="activity">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className='row'>
                                                                <div className='col-sm-8'>
                                                                    <h6 style={{ textTransform: "uppercase" }}>Room Details</h6>
                                                                </div>
                                                                <div className='col-sm-4'>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <table className='table'>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>Room Type</th>
                                                                            <th>Room No</th>
                                                                            <th>Plan Name</th>
                                                                            <th>Amount</th>
                                                                            <th>Discount</th>
                                                                            <th>Room Rate</th>
                                                                            <th>Username</th>
                                                                            <th>Action</th>
                                                                        </tr>

                                                                        {
                                                                            bookingData.rooms.map((item, index) =>
                                                                                <tr key={index}>
                                                                                    <td>{item.roomtypename}</td>
                                                                                    <td>
                                                                                        {item.roomname}
                                                                                        {item.status === 'Exchanged' && " (Exchanged)"}
                                                                                    </td>
                                                                                    <td>{item.plananme}</td>
                                                                                    <td>{item.amount}</td>
                                                                                    <td>{item.discount} %</td>
                                                                                    <td>{item.roomrate}</td>
                                                                                    <td>{item.username}</td>
                                                                                    <td>
                                                                                       
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className='row'>
                                                                <div className='col-sm-6'>
                                                                    <h6 style={{ textTransform: "uppercase" }}>Extra Services</h6>
                                                                </div>
                                                                <div className='col-sm-6'>
                                                                  
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <table className='table'>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>Service Name</th>
                                                                            <th>Room No</th>
                                                                            <th>Amount</th>
                                                                            <th>Quantity</th>
                                                                            <th>Discount</th>
                                                                            <th>Service Amount</th>
                                                                            <th>Username</th>
                                                                            <th>Action</th>
                                                                        </tr>

                                                                        {
                                                                            bookingData.services.map((item, index) =>
                                                                                <tr key={index}>
                                                                                    <td>{item.servicename}</td>
                                                                                    <td>{item.roomname}</td>
                                                                                    <td>{item.servamount}</td>
                                                                                    <td>{item.quantity}</td>
                                                                                    <td>{item.discount}</td>
                                                                                    <td>{item.servicerate}</td>
                                                                                    <td>{item.username}</td>
                                                                                    <td>
                                                                                       
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className='row'>
                                                                <div className='col-sm-6'>
                                                                    <h6 style={{ textTransform: "uppercase" }}>Payment History</h6>
                                                                </div>
                                                                <div className='col-sm-6'>
                                                                   
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <table className='table'>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>Paymode 1</th>
                                                                            <th>Amount 1</th>
                                                                            <th>Paytime 1</th>
                                                                            <th>Paymode 2</th>
                                                                            <th>Amount 2</th>
                                                                            <th>Paytime 2</th>
                                                                            <th>Datetime</th>
                                                                            <th>Note</th>
                                                                            <th>Username</th>
                                                                        </tr>

                                                                        {
                                                                            payhistory.map((item, index) =>
                                                                                <tr key={index}>
                                                                                    <td>{item.paymode1}</td>
                                                                                    <td>{item.data.amount1}</td>
                                                                                    <td>{item.data.paytime1}</td>
                                                                                    <td>{item.paymode2}</td>
                                                                                    <td>{item.data.amount2}</td>
                                                                                    <td>{item.data.paytime2}</td>
                                                                                    <td>{item.createdat}</td>
                                                                                    <td>{item.data.note}</td>
                                                                                    <td>{item.data.username}</td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className='row'>
                                                                <div className='col-sm-6'>
                                                                    <h6 style={{ textTransform: "uppercase" }}>Check-In Details Datewise</h6>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <table className='table'>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>Checkin Date</th>
                                                                            <th>Checkout Date</th>
                                                                            <th>Room Type</th>
                                                                            <th>Room No</th>
                                                                            <th>Plan Name</th>
                                                                            <th>Username</th>
                                                                        </tr>
                                                                        {
                                                                            checkindetails.map((item, index) =>
                                                                                <tr key={index}>
                                                                                    <td> {moment(item.data.checkindate).format('DD/MM/YYYY')}</td>
                                                                                    <td> {moment(item.data.checkoutdate).format('DD/MM/YYYY')}</td>
                                                                                    <td>{item.data.roomtype}</td>
                                                                                    <td>{item.data.roomname}</td>
                                                                                    <td>{item.data.planname}</td>
                                                                                    <td>{item.data.username}</td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>



                    <div className="modal fade" id="serviceModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Add Extra Service</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <table width="100%">
                                        <tbody>
                                            <tr>
                                                <th style={{ padding: 10, width: 180 }}>Service Name</th>
                                                <th style={{ padding: 10, width: 180 }}>Room No</th>
                                                <th style={{ padding: 10, width: 180 }}>Amount</th>
                                                <th style={{ padding: 10, width: 180 }}>Quantity</th>
                                                <th style={{ padding: 10, width: 180 }}>Discount</th>
                                                <th style={{ padding: 10, width: 180 }}>Total</th>
                                                <th style={{ padding: 10 }}>Action</th>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: 10 }}>
                                                    <select className="form-control" value={serviceid} onChange={handleServiceid}>
                                                        <option> Select </option>
                                                        {
                                                            roomservices.map((item, index) =>
                                                                <option key={index} value={item.id}>{item.data.roomservice}</option>

                                                            )
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <select className="form-control" value={roomid} onChange={(e) => setroomid(e.target.value)}>
                                                        <option> Select </option>
                                                        {
                                                            bookingData.rooms.map((item, index) =>
                                                                <option key={index} value={item.roomid}>{item.roomname}</option>
                                                            )
                                                        }

                                                    </select>
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <input type="text" value={servamount} readOnly className="form-control" />
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <input type="text" value={quantity} onChange={handleQuantity} className="form-control" />
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <input type="text" value={discount} onChange={handleDiscount} className="form-control" />
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <input type="text" value={servicerate} readOnly className="form-control" />
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <button className='btn btn-sm btn-info' onClick={() => handleServiceArray()}>
                                                        <i className='fa fa-plus'></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            {
                                                services.map((item, index) =>
                                                    <tr key={index}>
                                                        <td style={{ padding: 10 }}>
                                                            <input type="text" readOnly defaultValue={item.servicename} className="form-control" />
                                                        </td>
                                                        <td style={{ padding: 10 }}>
                                                            <input type="text" readOnly defaultValue={item.roomname} className="form-control" />
                                                        </td>
                                                        <td style={{ padding: 10 }}>
                                                            <input type="text" readOnly defaultValue={item.servamount} className="form-control" />
                                                        </td>
                                                        <td style={{ padding: 10 }}>
                                                            <input type="text" readOnly defaultValue={item.quantity} className="form-control" />
                                                        </td>
                                                        <td style={{ padding: 10 }}>
                                                            <input type="text" readOnly defaultValue={item.discount} className="form-control" />
                                                        </td>
                                                        <td style={{ padding: 10 }}>
                                                            <input type="text" readOnly defaultValue={item.servicerate} className="form-control" />
                                                        </td>
                                                        <td style={{ padding: 10 }}>
                                                            <button onClick={() => removeItem(item)} className='btn btn-sm btn-danger'>
                                                                <i className='fa fa-trash'></i>
                                                            </button>&nbsp;&nbsp;
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table><hr />
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <FormControl className='form-control'>
                                                <InputLabel id="paymode1" style={{ backgroundColor: "white" }}>Pay Mode 1</InputLabel>
                                                <Select labelId='paymode1' size='small' value={paymode1} onChange={(e) => setpaymode1(e.target.value)} label="Age">
                                                    <MenuItem></MenuItem>
                                                    {
                                                        paymodes.map((item, index) =>
                                                            <MenuItem key={index} value={item.id}>{item.data.paymode}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={amount1} label="Amount 1" onChange={handleamount1} type='number' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={paytime1} onChange={(e) => setpaytime1(e.target.value)} label="Payment Time 1" type='datetime-local' size='small' />
                                        </div>
                                        <div className='col-sm-4'>
                                            <FormControl className='form-control'>
                                                <InputLabel id="paymode2" style={{ backgroundColor: "white" }}>Pay Mode 2</InputLabel>
                                                <Select labelId='paymode2' size='small' value={paymode2} onChange={(e) => setpaymode2(e.target.value)} label="Age">
                                                    <MenuItem></MenuItem>
                                                    {
                                                        paymodes.map((item, index) =>
                                                            <MenuItem key={index} value={item.id}>{item.data.paymode}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={amount2} label="Amount 2" onChange={handleamount2} type='number' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={paytime2} onChange={(e) => setpaytime2(e.target.value)} label="Payment Time 2" type='datetime-local' size='small' />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={serviceamount} readOnly label="Total Amount" type='text' size='small' />
                                            <span>Room Service Amount for {bookingData.noofdays} Days</span>
                                            <br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={advance} readOnly label="Paid Amount" type='text' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={remaining} readOnly label="Remaining" type='text' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={note} onChange={(e) => setnote(e.target.value)} label="Note" type='text' size='small' /><br /><br />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-success" onClick={storeService} >Submit</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="custModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Update Customer Details</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' color="secondary" value={custname} onChange={(e) => setcustname(e.target.value)} label="Customer Name" type='text' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' color="secondary" value={mobile} onChange={handlemobile} label="Mobile" type='tel' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' value={email} onChange={(e) => setemail(e.target.value)} label="Email" type='email' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' color="secondary" value={address} onChange={(e) => setaddress(e.target.value)} label="Address" type='text' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' color="secondary" value={city} onChange={(e) => setcity(e.target.value)} label="City" type='text' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' value={state} onChange={(e) => setstate(e.target.value)} label="State" type='text' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <FormControl className='form-control'>
                                                <InputLabel id="gender" style={{ backgroundColor: "white" }}>Gender</InputLabel>
                                                <Select labelId='gender' size='small' value={gender} onChange={(e) => setgender(e.target.value)} label="Age">
                                                    <MenuItem value='Male'>Male</MenuItem>
                                                    <MenuItem value='Female'>Female</MenuItem>
                                                    <MenuItem value='Other'>Other</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <FormControl className='form-control'>
                                                <InputLabel id="visitpurpose" style={{ backgroundColor: "white" }}>Visit Purpose</InputLabel>
                                                <Select labelId='visitpurpose' size='small' value={visitpurpose} onChange={(e) => setvisitpurpose(e.target.value)} label="Age">
                                                    <MenuItem value='Darshan'>Darshan</MenuItem>
                                                    <MenuItem value='Tourist'>Tourist</MenuItem>
                                                    <MenuItem value='Business'>Business</MenuItem>
                                                    <MenuItem value='Official'>Official</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' value={gstname} onChange={(e) => setgstname(e.target.value)} label="GST Name" type='text' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' value={gstno} onChange={(e) => setgstno(e.target.value)} label="GST No" type='text' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <FormControl className='form-control'>
                                                <InputLabel id="bookingsource" style={{ backgroundColor: "white" }}>Booking Source</InputLabel>
                                                <Select labelId='bookingsource' size='small' value={bookingsource} onChange={(e) => setbookingsource(e.target.value)} label="Age">
                                                    <MenuItem value='Walking'>Walking</MenuItem>
                                                    <MenuItem value='Website'>Website</MenuItem>
                                                    <MenuItem value='OTA'>OTA</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' color="secondary" value={documenttype} onChange={(e) => setdocumenttype(e.target.value)} label="Document Type" type='text' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' color="secondary" value={idno} onChange={(e) => setidno(e.target.value)} label="ID No" type='text' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' color="secondary" value={adults} onChange={(e) => setadults(e.target.value)} label="Adults" type='text' size='small' />
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <TextField className='form-control' color="secondary" value={childs} onChange={(e) => setchilds(e.target.value)} label="Childs" type='text' size='small' />
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-success" onClick={updateCust} >Submit</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="roomModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Add New Rooms</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <table width="100%">
                                        <tbody>
                                            <tr>
                                                <th style={{ padding: 10, width: 180 }}>Room Type</th>
                                                <th style={{ padding: 10, width: 180 }}>Room No</th>
                                                <th style={{ padding: 10, width: 180 }}>Plan Name</th>
                                                <th style={{ padding: 10, width: 180 }}>Amount</th>
                                                <th style={{ padding: 10, width: 180 }}>Discount</th>
                                                <th style={{ padding: 10, width: 180 }}>Discount Rate</th>
                                                <th style={{ padding: 10 }}>Action</th>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: 10 }}>
                                                    <select className="form-control" value={rtypeid} onChange={(e) => handleroomtypeChange(e.target.value)}>
                                                        <option> Select </option>
                                                        {
                                                            roomtypes.map((item, index) =>
                                                                <option key={index} value={item.id}>{item.data.roomtype}</option>

                                                            )
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <select className="form-control" value={roomid} onChange={(e) => setroomid(e.target.value)}>
                                                        <option> Select </option>
                                                        {
                                                            rooms.map((item, index) =>
                                                                <option key={index} value={item.id}>{item.data.roomno}</option>

                                                            )
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <select className="form-control" value={planid} onChange={(e) => handleplanChange(e.target.value)}>
                                                        <option> Select </option>
                                                        {
                                                            plans.map((item, index) =>
                                                                <option key={index} value={item.id}>{item.data.rateplan}</option>

                                                            )
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <input type="text" value={amount} readOnly className="form-control" />
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <input type="text" value={discount} onChange={(e) => caltotal1(e.target.value)} className="form-control" />
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <input type="text" value={roomrate} readOnly className="form-control" />
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <button className='btn btn-sm btn-info' onClick={() => handleRoomArray()}>
                                                        <i className='fa fa-plus'></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            {
                                                bookingrooms.map((item, index) =>
                                                    <tr key={index}>
                                                        <td style={{ padding: 10 }}>
                                                            <input type="text" defaultValue={item.roomtypename} className="form-control" />
                                                        </td>
                                                        <td style={{ padding: 10 }}>
                                                            <input type="text" defaultValue={item.roomname} className="form-control" />
                                                        </td>
                                                        <td style={{ padding: 10 }}>
                                                            <input type="text" defaultValue={item.plananme} className="form-control" />
                                                        </td>
                                                        <td style={{ padding: 10 }}>
                                                            <input type="text" defaultValue={item.amount} className="form-control" />
                                                        </td>
                                                        <td style={{ padding: 10 }}>
                                                            <input type="text" defaultValue={item.discount} className="form-control" />
                                                        </td>
                                                        <td style={{ padding: 10 }}>
                                                            <input type="text" defaultValue={item.roomrate} className="form-control" />
                                                        </td>
                                                        <td style={{ padding: 10 }}>
                                                            <button onClick={() => removeItem1(item)} className='btn btn-sm btn-danger'>
                                                                <i className='fa fa-trash'></i>
                                                            </button>&nbsp;&nbsp;
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table><hr />
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <FormControl className='form-control'>
                                                <InputLabel id="paymode1" style={{ backgroundColor: "white" }}>Pay Mode 1</InputLabel>
                                                <Select labelId='paymode1' size='small' value={paymode1} onChange={(e) => setpaymode1(e.target.value)} label="Age">
                                                    <MenuItem></MenuItem>
                                                    {
                                                        paymodes.map((item, index) =>
                                                            <MenuItem key={index} value={item.id}>{item.data.paymode}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={amount1} label="Amount 1" onChange={handleamount3} type='number' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={paytime1} onChange={(e) => setpaytime1(e.target.value)} label="Payment Time 1" type='datetime-local' size='small' />
                                        </div>
                                        <div className='col-sm-4'>
                                            <FormControl className='form-control'>
                                                <InputLabel id="paymode2" style={{ backgroundColor: "white" }}>Pay Mode 2</InputLabel>
                                                <Select labelId='paymode2' size='small' value={paymode2} onChange={(e) => setpaymode2(e.target.value)} label="Age">
                                                    <MenuItem></MenuItem>
                                                    {
                                                        paymodes.map((item, index) =>
                                                            <MenuItem key={index} value={item.id}>{item.data.paymode}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={amount2} label="Amount 2" onChange={handleamount4} type='number' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={paytime2} onChange={(e) => setpaytime2(e.target.value)} label="Payment Time 2" type='datetime-local' size='small' />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={rooamount} readOnly label="Total Amount" type='text' size='small' />
                                            <span>Room Amount for {bookingData.noofdays} Days</span>
                                            <br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={advance} readOnly label="Paid Amount" type='text' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={remaining} readOnly label="Remaining" type='text' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={note} onChange={(e) => setnote(e.target.value)} label="Note" type='text' size='small' /><br /><br />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-success" onClick={storeRoom} >Submit</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="payModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Add Payment</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <FormControl className='form-control'>
                                                <InputLabel id="paymode1" style={{ backgroundColor: "white" }}>Pay Mode 1</InputLabel>
                                                <Select labelId='paymode1' size='small' value={paymode1} onChange={(e) => setpaymode1(e.target.value)} label="Age">
                                                    <MenuItem></MenuItem>
                                                    {
                                                        paymodes.map((item, index) =>
                                                            <MenuItem key={index} value={item.id}>{item.data.paymode}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={amount1} label="Amount 1" onChange={handleamount5} type='number' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={paytime1} onChange={(e) => setpaytime1(e.target.value)} label="Payment Time 1" type='datetime-local' size='small' />
                                        </div>
                                        <div className='col-sm-4'>
                                            <FormControl className='form-control'>
                                                <InputLabel id="paymode2" style={{ backgroundColor: "white" }}>Pay Mode 2</InputLabel>
                                                <Select labelId='paymode2' size='small' value={paymode2} onChange={(e) => setpaymode2(e.target.value)} label="Age">
                                                    <MenuItem></MenuItem>
                                                    {
                                                        paymodes.map((item, index) =>
                                                            <MenuItem key={index} value={item.id}>{item.data.paymode}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={amount2} label="Amount 2" onChange={handleamount6} type='number' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={paytime2} onChange={(e) => setpaytime2(e.target.value)} label="Payment Time 2" type='datetime-local' size='small' />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={totalamount} readOnly label="Total Amount" type='text' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={advance} readOnly label="Paid Amount" type='text' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={remaining} readOnly label="Remaining" type='text' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={note} onChange={(e) => setnote(e.target.value)} label="Note" type='text' size='small' /><br /><br />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-success" onClick={storePayment} >Submit</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="extendModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Extend Rooms</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <FormControl variant="outlined" className='form-control'>
                                                <label>Extend Room For</label>
                                                <OutlinedInput
                                                    value={extendRoom}
                                                    onChange={handleExtendRoom}
                                                    id="outlined-adornment-weight"
                                                    endAdornment={<InputAdornment position="end">Days</InputAdornment>}
                                                    aria-describedby="outlined-weight-helper-text"
                                                    size='small'
                                                />
                                            </FormControl>
                                        </div>
                                    </div><br /><br />
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <FormControl className='form-control'>
                                                <InputLabel id="paymode1" style={{ backgroundColor: "white" }}>Pay Mode 1</InputLabel>
                                                <Select labelId='paymode1' size='small' value={paymode1} onChange={(e) => setpaymode1(e.target.value)} label="Age">
                                                    <MenuItem></MenuItem>
                                                    {
                                                        paymodes.map((item, index) =>
                                                            <MenuItem key={index} value={item.id}>{item.data.paymode}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={amount1} label="Amount 1" onChange={handleamount5} type='number' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={paytime1} onChange={(e) => setpaytime1(e.target.value)} label="Payment Time 1" type='datetime-local' size='small' />
                                        </div>
                                        <div className='col-sm-4'>
                                            <FormControl className='form-control'>
                                                <InputLabel id="paymode2" style={{ backgroundColor: "white" }}>Pay Mode 2</InputLabel>
                                                <Select labelId='paymode2' size='small' value={paymode2} onChange={(e) => setpaymode2(e.target.value)} label="Age">
                                                    <MenuItem></MenuItem>
                                                    {
                                                        paymodes.map((item, index) =>
                                                            <MenuItem key={index} value={item.id}>{item.data.paymode}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={amount2} label="Amount 2" onChange={handleamount6} type='number' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={paytime2} onChange={(e) => setpaytime2(e.target.value)} label="Payment Time 2" type='datetime-local' size='small' />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={totalamount} readOnly label="Total Amount" type='text' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={advance} readOnly label="Paid Amount" type='text' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={remaining} readOnly label="Remaining" type='text' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={note} onChange={(e) => setnote(e.target.value)} label="Note" type='text' size='small' /><br /><br />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-success" onClick={extendRoomStore} >Submit</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="exchangedModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Exchange Rooms</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <table width="100%">
                                        <tbody>
                                            <tr>
                                                <td style={{ padding: 10 }} colSpan={5}>
                                                    <span><b>Old Room</b></span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: 10 }}>
                                                    <input type="text" defaultValue={itemDetails.roomtypename} className="form-control" readOnly />
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <input type="text" defaultValue={itemDetails.roomname} className="form-control" readOnly />
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <input type="text" defaultValue={itemDetails.plananme} className="form-control" readOnly />
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <input type="text" defaultValue={itemDetails.amount} className="form-control" readOnly />
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <input type="text" defaultValue={itemDetails.discount} className="form-control" readOnly />
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <input type="text" defaultValue={itemDetails.roomrate} className="form-control" readOnly />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{ padding: 10, width: 180 }}>Room Type</th>
                                                <th style={{ padding: 10, width: 180 }}>Room No</th>
                                                <th style={{ padding: 10, width: 180 }}>Plan Name</th>
                                                <th style={{ padding: 10, width: 180 }}>Amount</th>
                                                <th style={{ padding: 10, width: 180 }}>Discount</th>
                                                <th style={{ padding: 10, width: 180 }}>Discount Rate</th>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: 10 }}>
                                                    <select className="form-control" value={rtypeid} onChange={(e) => handleroomtypeChange(e.target.value)}>
                                                        <option> Select </option>
                                                        {
                                                            roomtypes.map((item, index) =>
                                                                <option key={index} value={item.id}>{item.data.roomtype}</option>

                                                            )
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <select className="form-control" value={roomid} onChange={(e) => setroomid(e.target.value)}>
                                                        <option> Select </option>
                                                        {
                                                            rooms.map((item, index) =>
                                                                <option key={index} value={item.id}>{item.data.roomno}</option>

                                                            )
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <select className="form-control" value={planid} onChange={(e) => handleplanChange1(e.target.value)}>
                                                        <option> Select </option>
                                                        {
                                                            plans.map((item, index) =>
                                                                <option key={index} value={item.id}>{item.data.rateplan}</option>

                                                            )
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <input type="text" value={amount} readOnly className="form-control" />
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <input type="text" value={discount} onChange={(e) => caltotal2(e.target.value)} className="form-control" />
                                                </td>
                                                <td style={{ padding: 10 }}>
                                                    <input type="text" value={roomrate} readOnly className="form-control" />
                                                </td>

                                            </tr>

                                        </tbody>
                                    </table><hr />
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <label>Room Durty</label>
                                            <select className='form-control' value={durtyRoom} onChange={(e) => setdurtyRoom(e.target.value)}>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                        <div className='col-sm-4'>
                                            <label>Room Exchange Note</label>
                                            <textarea className='form-control' value={exchangeNote} onChange={(e) => setexchangeNote(e.target.value)}></textarea>
                                        </div>
                                    </div><hr />
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            {
                                                rooamount < 0 ?
                                                    <h4>Amount Given To Customer  : Rs.{rooamount} /-</h4> :
                                                    <h4>Amount Taken  From Customer : Rs.{rooamount} /-</h4>
                                            }
                                            {/* <FormControl className='form-control'>
                                                <InputLabel id="paymode1" style={{ backgroundColor: "white" }}>Pay Mode 1</InputLabel>
                                                <Select labelId='paymode1' size='small' value={paymode1} onChange={(e) => setpaymode1(e.target.value)} label="Age">
                                                    <MenuItem></MenuItem>
                                                    {
                                                        paymodes.map((item, index) =>
                                                            <MenuItem key={index} value={item.id}>{item.data.paymode}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl><br /><br /> */}
                                        </div>
                                        {/* <div className='col-sm-4'>
                                            <TextField className='form-control' value={amount1} label="Amount 1" onChange={handleamountx1} type='text' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={paytime1} onChange={(e) => setpaytime1(e.target.value)} label="Payment Time 1" type='datetime-local' size='small' />
                                        </div>
                                        <div className='col-sm-4'>
                                            <FormControl className='form-control'>
                                                <InputLabel id="paymode2" style={{ backgroundColor: "white" }}>Pay Mode 2</InputLabel>
                                                <Select labelId='paymode2' size='small' value={paymode2} onChange={(e) => setpaymode2(e.target.value)} label="Age">
                                                    <MenuItem></MenuItem>
                                                    {
                                                        paymodes.map((item, index) =>
                                                            <MenuItem key={index} value={item.id}>{item.data.paymode}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={amount2} label="Amount 2" onChange={handleamountx2} type='text' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={paytime2} onChange={(e) => setpaytime2(e.target.value)} label="Payment Time 2" type='datetime-local' size='small' />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={rooamount} readOnly label="Total Payble Amount" type='text' size='small' />
                                            <span>Old Remaining Amount + Current Payble</span>
                                            <br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={advance} readOnly label="Paid Amount" type='text' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={remaining} readOnly label="Remaining" type='text' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={note} onChange={(e) => setnote(e.target.value)} label="Note" type='text' size='small' /><br /><br />
                                        </div> */}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-success" onClick={storeRoomExchange} >Exchange Room</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="payModal1" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Return Payment</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <FormControl className='form-control'>
                                                <InputLabel id="paymode1" style={{ backgroundColor: "white" }}>Pay Mode 1</InputLabel>
                                                <Select labelId='paymode1' size='small' value={paymode1} onChange={(e) => setpaymode1(e.target.value)} label="Age">
                                                    <MenuItem></MenuItem>
                                                    {
                                                        paymodes.map((item, index) =>
                                                            <MenuItem key={index} value={item.id}>{item.data.paymode}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={amount1} label="Amount 1" onChange={handleamountx1} type='text' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={paytime1} onChange={(e) => setpaytime1(e.target.value)} label="Payment Time 1" type='datetime-local' size='small' />
                                        </div>
                                        <div className='col-sm-4'>
                                            <FormControl className='form-control'>
                                                <InputLabel id="paymode2" style={{ backgroundColor: "white" }}>Pay Mode 2</InputLabel>
                                                <Select labelId='paymode2' size='small' value={paymode2} onChange={(e) => setpaymode2(e.target.value)} label="Age">
                                                    <MenuItem></MenuItem>
                                                    {
                                                        paymodes.map((item, index) =>
                                                            <MenuItem key={index} value={item.id}>{item.data.paymode}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={amount2} label="Amount 2" readOnly type='text' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={paytime2} onChange={(e) => setpaytime2(e.target.value)} label="Payment Time 2" type='datetime-local' size='small' />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={totalamount} readOnly label="Total Amount" type='text' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={advance} readOnly label="Paid Amount" type='text' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={remaining} readOnly label="Remaining" type='text' size='small' /><br /><br />
                                        </div>
                                        <div className='col-sm-4'>
                                            <TextField className='form-control' value={note} onChange={(e) => setnote(e.target.value)} label="Note" type='text' size='small' /><br /><br />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-success" onClick={storePayment1} >Submit</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="checkoutModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Checkout Room</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <label>Any Damages</label>
                                            <select className='form-control' value={anydamage} onChange={(e) => setanydamage(e.target.value)} >
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                            </select>
                                        </div>
                                        {
                                            anydamage === "Yes" &&
                                            <>
                                                <div className='col-sm-4'>
                                                    <label>Damage Amount</label>
                                                    <input className='form-control' type='number' value={damageamount} onChange={handleDamageAmount} />
                                                </div>
                                                <div className='col-sm-4'>
                                                    <label>Damage Note</label>
                                                    <input className='form-control' value={damagenote} onChange={(e) => setdamagenote(e.target.value)} />
                                                </div>
                                            </>
                                        }
                                    </div><hr />
                                    {
                                        totalamount > 0 &&
                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <FormControl className='form-control'>
                                                    <InputLabel id="paymode1" style={{ backgroundColor: "white" }}>Pay Mode 1</InputLabel>
                                                    <Select labelId='paymode1' size='small' value={paymode1} onChange={(e) => setpaymode1(e.target.value)} label="Age">
                                                        <MenuItem></MenuItem>
                                                        {
                                                            paymodes.map((item, index) =>
                                                                <MenuItem key={index} value={item.id}>{item.data.paymode}</MenuItem>
                                                            )
                                                        }
                                                    </Select>
                                                </FormControl><br /><br />
                                            </div>
                                            <div className='col-sm-4'>
                                                <TextField className='form-control' value={amount1} label="Amount 1" onChange={handleamount5} type='number' size='small' /><br /><br />
                                            </div>
                                            <div className='col-sm-4'>
                                                <TextField className='form-control' value={paytime1} onChange={(e) => setpaytime1(e.target.value)} label="Payment Time 1" type='datetime-local' size='small' />
                                            </div>
                                            <div className='col-sm-4'>
                                                <FormControl className='form-control'>
                                                    <InputLabel id="paymode2" style={{ backgroundColor: "white" }}>Pay Mode 2</InputLabel>
                                                    <Select labelId='paymode2' size='small' value={paymode2} onChange={(e) => setpaymode2(e.target.value)} label="Age">
                                                        <MenuItem></MenuItem>
                                                        {
                                                            paymodes.map((item, index) =>
                                                                <MenuItem key={index} value={item.id}>{item.data.paymode}</MenuItem>
                                                            )
                                                        }
                                                    </Select>
                                                </FormControl><br /><br />
                                            </div>
                                            <div className='col-sm-4'>
                                                <TextField className='form-control' value={amount2} label="Amount 2" onChange={handleamount6} type='number' size='small' /><br /><br />
                                            </div>
                                            <div className='col-sm-4'>
                                                <TextField className='form-control' value={paytime2} onChange={(e) => setpaytime2(e.target.value)} label="Payment Time 2" type='datetime-local' size='small' />
                                            </div>
                                            <div className='col-sm-4'>
                                                <TextField className='form-control' value={totalamount} readOnly label="Payble Amount" type='text' size='small' /><br /><br />
                                            </div>
                                            <div className='col-sm-4'>
                                                <TextField className='form-control' value={advance} readOnly label="Paid Amount" type='text' size='small' /><br /><br />
                                            </div>
                                            <div className='col-sm-4'>
                                                <TextField className='form-control' value={remaining} readOnly label="Remaining" type='text' size='small' /><br /><br />
                                            </div>
                                            <div className='col-sm-4'>
                                                <TextField className='form-control' value={note} onChange={(e) => setnote(e.target.value)} label="Note" type='text' size='small' /><br /><br />
                                            </div>
                                        </div>
                                    }

                                </div>
                                <div className="modal-footer">
                                    <button type="button" data-dismiss="modal" className="btn btn-success" onClick={handleCheckout}>Checkout</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            }



        </div>

    )
}
const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Ensure the overlay is on top
};

export default Viewdetails
