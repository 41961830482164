import React, { useEffect, useState, } from 'react'
import Swal from 'sweetalert2';
import { addDoc, collection, doc, getDoc, onSnapshot, orderBy, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import "../../App.css";
import Menu from '../../panel/Menu';
import moment from 'moment';

function Livebookings() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    const [bookings, setbookings] = useState([]);
    const [username, setusername] = useState();
    const [userData, setUserData] = useState();
    const [loadincount, setloadincount] = useState(true);
    const [searchText, setsearchText] = useState("");
    const [bookingId, setbookingId] = useState("");
    const [requestType, setrequestType] = useState("Exchange Room");
    const [requestNote, setrequestNote] = useState("");
    const [roomUsed, setroomUsed] = useState("No");

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
            fethUsers()
        });
    });

    const fethUsers = async () => {
        try {
            if (userData) {
                if (loadincount) {
                    const docRef = doc(db, "users", userData.uid)
                    const docSnap = await getDoc(docRef)
                    if (docSnap.exists()) {
                        setusername(docSnap.data().username);
                        setloadincount(false)
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (searchText === '') {
            fetchData1()
        } else {
            fetchData2()
        }
    }, [searchText]);


    const fetchData1 = async () => {
        try {
            const q = query(collection(db, 'bookings'), orderBy("createdAt", "asc"), where('status', '==', 'Booked'))
            onSnapshot(q, (querySnapshot) => {
                setbookings(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });
        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }


    const fetchData2 = async () => {
        try {
            const filteredData = bookings.filter((item) =>
                item.data.customers.custname.toUpperCase().includes(searchText.toUpperCase())
            );
            setbookings(filteredData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const storeRequest = async () => {
        if (!bookingId || !requestType || !requestNote) {
            Toast.fire({ icon: "error", title: 'Request Note or Request Type should not be blank' });
            return
        }

        await addDoc(collection(db, 'approvals'), {
            bookingid: bookingId,
            requestType: requestType,
            requestNote: requestNote,
            status: 'Pending',
            rejectedreason: '',
            updatedat: "",
            uid: userData.uid,
            username: username,
            createdAt: Timestamp.now(),
        });

        setrequestNote("");
        Toast.fire({ icon: "success", title: 'Approval Request send to the GM' });

    }

    const getRooms = (boookinif) => {
        const q = query(collection(db, 'bookings'), orderBy("createdAt", "asc"), where('status', '==', 'Booked'))
        onSnapshot(q, (querySnapshot) => {
            setbookings(querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })));
        });
    }

    const cancleBooking = async () => {

        await updateDoc(doc(db, "bookings", bookingId), {
            status: "Cancled",
        });

        const q1 = query(collection(db, 'checkindetails'), where('bookingid', '==', bookingId))
        onSnapshot(q1, (querySnapshot) => {
            querySnapshot.docs.map(async (item) => {
                await updateDoc(doc(db, "checkindetails", item.id), {
                    status: "Cancled",
                });
            })
        });

        const q2 = query(collection(db, 'payhistory'), where('bookingid', '==', bookingId))
        onSnapshot(q2, (querySnapshot) => {
            querySnapshot.docs.map(async (item) => {
                await updateDoc(doc(db, "payhistory", item.id), {
                    status: "Cancled",
                });
            })
        });

        const docRef = doc(db, "bookings", bookingId);
        const docSnap = await getDoc(docRef);

        if (roomUsed === 'Yes') {
            docSnap.data().rooms.map(async (item) => {
                await updateDoc(doc(db, "roomdetails", item.roomid), {
                    status: "Cleaning",
                });
            })
        }
        else {
            docSnap.data().rooms.map(async (item) => {
                await updateDoc(doc(db, "roomdetails", item.roomid), {
                    status: "Available",
                });
            })
        }

        await addDoc(collection(db, 'logs'), {
            bookingid: docSnap.data().id,
            customerId: docSnap.data().customerId,
            customers: docSnap.data().customers,
            rooms: docSnap.data().rooms,
            uid: userData.uid,
            username: username,
            createdAt: Timestamp.now(),
            type: 'Booking Camcelation',
        });

        Toast.fire({ icon: "success", title: 'Room Successfully Cancled' });

    }


    return (
        <div>
            <Menu />
            <div className="content-wrapper" style={{ backgroundColor: '#fff' }}>
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ textTransform: "uppercase" }} >|| Live Bookings ||</button>
                        <div className="card card-primary card-outline mt-2">
                            <div className=" p-1">
                                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                                    className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
                            </div>
                            <div className="card-body table-responsive p-0" >

                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Customer Name </th>
                                            <th>Mobile </th>
                                            <th>Checkin Date </th>
                                            <th>Checkout Date </th>
                                            <th style={{ width: 200 }}>Rooms </th>
                                            <th>Adults </th>
                                            <th style={{ textAlign: "right" }}>Total</th>
                                            <th style={{ textAlign: "right" }}>Paid</th>
                                            <th style={{ textAlign: "right" }}>Pending</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            bookings.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.data.customers.custname}</td>
                                                    <td>{item.data.customers.mobile} </td>
                                                    <td>{moment(item.data.checkindate).format('DD/MM/YYYY')}</td>
                                                    <td>{moment(item.data.checkoutdate).format('DD/MM/YYYY')}</td>
                                                    <td>
                                                        <div className='row'>
                                                            {item.data.rooms.map((item1, index) =>
                                                                <span>{item1.roomname}, &nbsp;  </span>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td>{item.data.adults} Persons</td>
                                                    <td style={{ textAlign: "right" }}>{item.data.totalamount.toFixed(2)}</td>
                                                    <td style={{ textAlign: "right" }}>{item.data.advance.toFixed(2)}</td>
                                                    <td style={{ textAlign: "right" }}>{item.data.remaining.toFixed(2)}</td>
                                                    <td>
                                                        <a href={'view-details/' + item.id} className='btn btn-info btn-sm'><i className="fa fa-eye"></i></a>&nbsp;
                                                        {
                                                            item.data.approval1 === 'Yes' &&
                                                            <a className="btn btn-sm bg-teal" href={'view-details/' + item.id} > <i className="fa fa-exchange"></i></a>
                                                        }
                                                        {
                                                            item.data.approval2 === 'Yes' &&
                                                            <a className="btn btn-sm bg-red" onClick={() => setbookingId(item.id)} data-toggle="modal" data-target="#cancleModal" href="#0" > <i className="fa fa-close"></i></a>
                                                        }
                                                        {
                                                            item.data.approval3 === 'Yes' &&
                                                            <a className="btn btn-sm bg-teal" href={'view-details/' + item.id} > <i className="fa fa-exchange"></i></a>
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
            {/* ======================== Modals  ====================================================== */}

            <div className="modal fade" id="approvalModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Send Approval Request to GM</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <select className='form-control' value={requestType} onChange={(e) => setrequestType(e.target.value)}>
                                        <option value="Exchange Room">Exchange Room</option>
                                        <option value="Cancle Booking">Cancle Booking</option>
                                        <option value="Extra Service Cancle">Extra Service Cancle</option>
                                    </select>
                                </div>
                                <div className='col-sm-8'>
                                    <textarea className='form-control' value={requestNote} onChange={(e) => setrequestNote(e.target.value)}></textarea>
                                </div>
                            </div><hr /><br />

                        </div>
                        <div className="modal-footer">
                            <button type="button" data-dismiss="modal" className="btn btn-success" onClick={storeRequest}>Send Request</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="cancleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Are u sure to Cancle Booking</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label>Rooms Durty</label>
                                    <select className='form-control' value={roomUsed} onChange={(e) => setroomUsed(e.target.value)}>
                                        <option value="No">No</option>
                                        <option value="Yes">Yes</option>
                                    </select>
                                </div>
                            </div><hr /><br />

                        </div>
                        <div className="modal-footer">
                            <button type="button" data-dismiss="modal" className="btn btn-success" onClick={cancleBooking}>YES</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">No</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Livebookings
