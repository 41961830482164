import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where, limit, updateDoc, getCountFromServer } from 'firebase/firestore'
import moment from 'moment';

export default function Roomdetails() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();
    const [roomTypes, setroomTypes] = useState([]);
    const [searchText, setsearchText] = useState("");
    const [roomdetails, setroomdetails] = useState([]);
    const [rtypeid, setrtypeid] = useState("");
    const [roomno, setroomno] = useState("");
    const [extra, setextra] = useState(0);
    const [accupanacy, setaccupanacy] = useState(0);
    const [status, setstatus] = useState("Available");

    const [updocid, setupdocid] = useState("");
    const [uprtypeid, setuprtypeid] = useState("");
    const [uproomno, setuproomno] = useState("");
    const [upstatus, setupstatus] = useState("");
    const [upextra, setupextra] = useState(0);
    const [upaccupanacy, setupaccupanacy] = useState(0);


    useEffect(() => {
        if (searchText === '') {
            fetchData1()
        } else {
            fetchData2()
        }
    }, [searchText]);



    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
        });
    });

    const fetchData1 = async () => {
        try {
            // Fetch room types in real-time
            const roomTypesQuery = query(collection(db, 'roomtypes'), orderBy("createdAt", "asc"));
            onSnapshot(roomTypesQuery, (roomTypesSnapshot) => {
                setroomTypes(roomTypesSnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });

            // Fetch room details and room types in real-time
            const roomDetailsQuery = query(collection(db, 'roomdetails'), orderBy("roomno", "asc"));
            onSnapshot(roomDetailsQuery, async (roomDetailsSnapshot) => {
                const roomDetails = roomDetailsSnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data(),
                    rtypeid: doc.data().rtypeid
                }));

                // Collect all rtypeids for batch fetching
                const rtypeIds = [...new Set(roomDetails.map(detail => detail.rtypeid))];

                // Fetch all roomtypes in parallel using Promise.all
                const docRefs = rtypeIds.map(id => doc(db, "roomtypes", id));
                const docSnaps = await Promise.all(docRefs.map(docRef => getDoc(docRef)));

                // Create a map of rtypeid to roomtype name for fast lookup
                const roomTypeMap = {};
                docSnaps.forEach((docSnap, index) => {
                    if (docSnap.exists()) {
                        roomTypeMap[rtypeIds[index]] = docSnap.data().roomtype;
                    }
                });

                // Combine room details with roomtype data
                const combinedData = roomDetails.map(detail => ({
                    id: detail.id,
                    data: detail.data,
                    roomtype: roomTypeMap[detail.rtypeid] || ""
                }));

                setroomdetails(combinedData);
            });

        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
            console.error(error.message);
        }
    };


    const fetchData2 = async () => {
        try {
            const filteredData = roomdetails.filter((item) => {
                const roomNumber = String(item.data.roomno).toUpperCase();
                return roomNumber.includes(searchText.toUpperCase());
            });

            setroomdetails(filteredData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    const handleSubmit = async () => {
        if (!roomno || !rtypeid) {
            Toast.fire({
                icon: "error",
                title: 'Please fill all the fields!!'
            });
            return;
        }

        await addDoc(collection(db, 'roomdetails'), {
            rtypeid: rtypeid,
            roomno: roomno,
            status: status,
            accupanacy: parseFloat(accupanacy),
            extra: parseFloat(extra),
            uid: userData.uid,
            created: Timestamp.now()
        });

        const q = query(collection(db, 'roomdetails'), where('rtypeid', '==', rtypeid));
        const snapshot = await getCountFromServer(q);

        await updateDoc(doc(db, "roomtypes", rtypeid), {
            noofrooms: snapshot.data().count,
        });

        // setrtypeid("");
        setroomno("");
        Toast.fire({
            icon: "success",
            title: 'Room Details Added Successfully!!'
        });
    }

    const handleDelete = async (docid, rtypeid) => {
        const taskDocRef = doc(db, 'roomdetails', docid)
        await deleteDoc(taskDocRef);

        const q = query(collection(db, 'roomdetails'), where('rtypeid', '==', rtypeid));
        const snapshot = await getCountFromServer(q);

        await updateDoc(doc(db, "roomtypes", rtypeid), {
            noofrooms: snapshot.data().count,
        });


        Toast.fire({
            icon: "success",
            title: 'Room Details Deleted Successfully!!'
        });
        fetchData1();
    }

    const handleEdit = (id, rtypeid, roomno, status, accupancy, extra) => {
        setupdocid(id);
        setuproomno(roomno);
        setuprtypeid(rtypeid);
        setupstatus(status);
        setupaccupanacy(accupancy);
        setupextra(extra);
    }


    const handleUpdate = async () => {
        if (!uproomno || !uproomno) {
            Toast.fire({
                icon: "error",
                title: 'Please fill all the fields!!'
            });
            return;
        }

        try {

            await setDoc(doc(db, "roomdetails", updocid), {
                rtypeid: uprtypeid,
                roomno: parseFloat(uproomno),
                status: upstatus,
                accupanacy: parseFloat(upaccupanacy),
                extra: parseFloat(upextra),
                uid: userData.uid,
                created: Timestamp.now()
            });

            Toast.fire({
                icon: "success",
                title: 'Room Details Updated Successfully!!'
            });

            fetchData1();
        } catch (error) {
            console.log(error.message);
        }

    }


    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ float: "right" }} >|| Room Details ||</button>
                        <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#insert-modal">Add Room Details</button><br /><br />
                        <div className="card card-primary card-outline ex1">
                            <div className=" p-1">
                                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                                    className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
                            </div>
                            <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Room Type</th>
                                            <th>Room No</th>
                                            <th>Occupancy</th>
                                            <th>Extra</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            roomdetails.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.roomtype} </td>
                                                    <td>{item.data.roomno}</td>
                                                    <td>{item.data.accupanacy}</td>
                                                    <td>{item.data.extra}</td>
                                                    <td>{item.data.status} </td>
                                                    <td>
                                                        <a onClick={() => handleEdit(item.id, item.data.rtypeid, item.data.roomno, item.data.status, item.data.accupanacy, item.data.extra, item.data.extra)} href='#0' data-toggle="modal" data-target="#text-modal1" className="btn btn-sm btn-success" > <i className="fa fa-edit"></i></a>&nbsp;
                                                        <a onClick={() => { if (window.confirm('Are u sure?')) { handleDelete(item.id, item.data.rtypeid) }; }} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a>&nbsp;
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}

            <div className="modal fade" id="insert-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Room Details</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Room Type</label>
                                    <select value={rtypeid} onChange={(e) => setrtypeid(e.target.value)} className="form-control" >
                                        <option value=""></option>
                                        {
                                            roomTypes.map((item, index) =>
                                                <option key={index} value={item.id}>{item.data.roomtype}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <label>  Room No</label>
                                    <input type="text" value={roomno} onChange={(e) => setroomno(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6 mt-2">
                                    <label>  Status</label>
                                    <select value={status} onChange={(e) => setstatus(e.target.value)} className="form-control" >
                                        <option value="Available">Available</option>
                                        <option value="Booked">Booked</option>
                                        <option value="Unavailable">Unavailable</option>
                                        <option value="Cleaning">Cleaning</option>
                                    </select>
                                </div>
                                <div className="col-sm-6 mt-2">
                                    <label> Occupanacy</label>
                                    <input type="text" value={accupanacy} onChange={(e) => setaccupanacy(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6 mt-2">
                                    <label>  Extra </label>
                                    <input type="text" value={extra} onChange={(e) => setextra(e.target.value)} className="form-control" />
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="submit" onClick={() => handleSubmit()} className="btn btn-primary" >Save </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="text-modal1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Room Details</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Room Type</label>
                                    <select value={uprtypeid} onChange={(e) => setuprtypeid(e.target.value)} className="form-control" >
                                        <option value=""></option>
                                        {
                                            roomTypes.map((item, index) =>
                                                <option key={index} value={item.id}>{item.data.roomtype}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <label>  Room No</label>
                                    <input type="text" value={uproomno} onChange={(e) => setuproomno(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6 mt-2">
                                    <label> Occupanacy</label>
                                    <input type="text" value={upaccupanacy} onChange={(e) => setupaccupanacy(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6 mt-2">
                                    <label>  Extra</label>
                                    <input type="text" value={upextra} onChange={(e) => setupextra(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6 mt-2">
                                    <label>  Status</label>
                                    <select value={upstatus} onChange={(e) => setupstatus(e.target.value)} className="form-control" >
                                        <option value="Available">Available</option>
                                        <option value="Booked">Booked</option>
                                        <option value="Unavailable">Unavailable</option>
                                        <option value="Cleaning">Cleaning</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={() => handleUpdate()} className="btn btn-primary" data-dismiss="modal">Update </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
