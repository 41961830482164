import React, { useEffect, useState, } from 'react'
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import "../App.css";
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, deleteDoc, doc, updateDoc, limit } from 'firebase/firestore'
import moment from 'moment';

export default function Approvals() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();
    const [loadincount, setloadincount] = useState(true);

    const [approvals, setapprovals] = useState([]);
    const [searchText, setsearchText] = useState("");

    const [rejectreason, setrejectreason] = useState("");
    const [upamount, setupamount] = useState("");
    const [upstatus, setupstatus] = useState("");
    const [approvalid, setapprovalid] = useState("");

    useEffect(() => {
        if (searchText === '') {
            fetchData1()
        } else {
            fetchData2()
        }
    }, [searchText]);



    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
            fetchPropid()
        });
    });

    const fetchPropid = async () => {
        if (userData) {
            if (loadincount) {
                const docRef = doc(db, "users", userData.uid)
                const docSnap = await getDoc(docRef)
                if (docSnap.exists()) {
                    fetchData1();
                    setloadincount(false)
                }
            }
        }
    }

    const fetchData1 = async () => {
        try {
            const q = query(collection(db, 'approvals'), orderBy('createdAt', 'desc'), limit(200))
            onSnapshot(q, (querySnapshot) => {
                setapprovals(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });
        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const fetchData2 = async () => {
        try {
            const filteredData = approvals.filter((item) =>
                item.data.username.toUpperCase().includes(searchText.toUpperCase())
            );
            setapprovals(filteredData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    const approvRequest = async (id, bookingId, requestType) => {
        await updateDoc(doc(db, "approvals", id), {
            status: "Approved",
            updatedat: moment().format('DD/MM/YYYY HH:mm A'),
        });

        if (requestType === 'Extra Service Cancle') {
            await updateDoc(doc(db, "bookings", bookingId), {
                approval3: "Yes",
            });
        }

        if (requestType === 'Exchange Room') {
            await updateDoc(doc(db, "bookings", bookingId), {
                approval1: "Yes",
            });
        }

        if (requestType === 'Cancle Booking') {
            await updateDoc(doc(db, "bookings", bookingId), {
                approval2: "Yes",
            });
        }

        Toast.fire({
            icon: "success",
            title: 'Request Approved Successfully'
        });

        fetchData1();
    }

    const handleUpdate = async () => {
        if (!rejectreason) {
            Toast.fire({
                icon: "error",
                title: 'Please Enter Required Fields!!'
            });
            return;
        }

        await updateDoc(doc(db, "approvals", approvalid), {
            status: "Rejected",
            rejectedreason: rejectreason,
            updatedat: moment().format('DD/MM/YYYY HH:mm A'),
        });
        Toast.fire({
            icon: "success",
            title: 'Request Rejected Successfully'
        });

        fetchData1();
    }


    return (
        <div>
            <div className="content-wrapper">
                <br />

                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ float: "right", textTransform: "uppercase" }} >|| Approvals ||</button><br /><br /><br />
                        <div className="card card-primary card-outline ex1">
                            <div className=" p-1">
                                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                                    className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
                            </div>
                            <div className="card-body table-responsive p-0" >

                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Request For </th>
                                            <th>Request Note </th>
                                            <th>Status </th>
                                            <th>Rejected Reason </th>
                                            <th>User </th>
                                            {/* <th>Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            approvals.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.data.requestType}</td>
                                                    <td>{item.data.requestNote}</td>
                                                    <td>{item.data.status}</td>
                                                    <td>{item.data.rejectedreason}</td>
                                                    <td>{item.data.username}</td>
                                                    {/* <td>
                                                        {
                                                            item.data.status === 'Pending' &&
                                                            <>
                                                                <a onClick={() => { if (window.confirm('Are u sure to Approve?')) { approvRequest(item.id, item.data.bookingid, item.data.requestType) }; }} href='#0' className="btn btn-sm btn-success" > <i className="fa fa-check"></i></a>&nbsp;
                                                                <a className="btn btn-sm btn-danger" onClick={() => setapprovalid(item.id)} href='#0' data-toggle="modal" data-target="#text-modal1"> <i className="fa fa-close"></i></a>&nbsp;
                                                                <a href={'view-details/' + item.data.bookingid} className='btn btn-info btn-sm'><i className="fa fa-eye"></i> View Booking</a>&nbsp;
                                                            </>
                                                        }
                                                    </td> */}
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}



            <div className="modal fade" id="text-modal1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Reject Approval</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>  Reject Reason </label>
                                    <input type="text" value={rejectreason} onChange={(e) => setrejectreason(e.target.value)} className="form-control" />
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={() => handleUpdate()} className="btn btn-primary" data-dismiss="modal">Update </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
