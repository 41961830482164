import React, { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2';
import { auth, db, storage } from '../firebase';
import "../App.css";
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where, updateDoc } from 'firebase/firestore'
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

export default function Roomtypes() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();
    const [loadincount, setloadincount] = useState(true);
    const [laoding, setlaoding] = useState(false);

    const [roomtypes, setroomtypes] = useState([]);
    const [searchText, setsearchText] = useState("");

    const [roomtype, setroomtype] = useState("");
    const [noofperson, setnoofperson] = useState("");
    const [status, setstatus] = useState("Active");
    const [image, setImage] = useState(null);

    const [updocid, setupdocid] = useState("");
    const [uproomtype, setuproomtype] = useState("");
    const [upnoofperson, setupnoofperson] = useState("");
    const [upimage, setupimage] = useState("");
    const [upstatus, setupstatus] = useState("");

    const [previewImg, setpreviewImg] = useState("");

    useEffect(() => {
        if (searchText == '') {
            fetchData1()
        } else {
            fetchData2()
        }
    }, [searchText]);



    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
            fetchPropid()
        });
    });

    const fetchPropid = async () => {
        if (userData) {
            if (loadincount) {
                const docRef = doc(db, "users", userData.uid)
                const docSnap = await getDoc(docRef)
                if (docSnap.exists()) {
                    fetchData1();
                    setloadincount(false)
                }
            }
        }
    }

    const fetchData1 = async () => {
        try {
            const q = query(collection(db, 'roomtypes'), orderBy('createdAt','asc'))
            onSnapshot(q, (querySnapshot) => {
                setroomtypes(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });
        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const fetchData2 = async () => {
        try {
            const filteredData = roomtypes.filter((item) =>
                item.data.roomtype.toUpperCase().includes(searchText.toUpperCase())
            );
            setroomtypes(filteredData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }



    const handleImageChange = (e) => {
        const selected = e.target.files[0];
        const types = ["image/png", "image/jpeg", "image/jpg"];

        if (selected && types.includes(selected.type)) {
            setImage(selected);
        } else {
            setImage(null);
        }
    };

    const handleImageChange1 = (e) => {
        const selected = e.target.files[0];
        const types = ["image/png", "image/jpeg", "image/jpg"];

        if (selected && types.includes(selected.type)) {
            setupimage(selected);
        } else {
            setupimage(null);
        }
    };

    const handleSubmit = async () => {
        if (!roomtype || !noofperson || !image) {
            Toast.fire({
                icon: "error",
                title: 'Please Enter Required Fields!!'
            });

            return;
        }

        const storageRef = ref(storage, `images/${image.name}-${Date.now()}`);
        const uploadTask = uploadBytesResumable(storageRef, image);


        uploadTask.on("state_changed", (snapshot) => {
            const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
            (error) => {
                console.error(error);
            },
            async () => {
                try {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

                    await addDoc(collection(db, "roomtypes"), {
                        imageurl: downloadURL,
                        roomtype: roomtype,
                        noofperson: noofperson,
                        status: status,
                        uid: userData.uid,
                        createdAt: Timestamp.now(),
                    });

                    setImage(null);
                    setroomtype("");
                    setnoofperson("");
                    setstatus("Active");
                    fetchData1();

                    Toast.fire({
                        icon: "success",
                        title: 'Room Type Added Successfully!!'
                    });


                } catch (err) {
                    Toast.fire({
                        icon: "error",
                        title: 'Error in Image Uploading!!'
                    });

                    console.log(err.message);
                }
            }
        );
    }

    const handleDelete = async (docid, imgLink) => {
        const imageRef = ref(storage, imgLink);
        await deleteObject(imageRef);

        const taskDocRef = doc(db, 'roomtypes', docid)
        await deleteDoc(taskDocRef)
        Toast.fire({
            icon: "success",
            title: 'Room Type Deleted Successfully!!'
        });
        fetchData1();
    }

    const handleEdit = (id, roomtype, noofperson, status) => {
        setupdocid(id);
        setuproomtype(roomtype);
        setupnoofperson(noofperson);
        setupstatus(status);
    }

    const handleUpdate = async () => {
        if (!uproomtype || !upnoofperson) {
            Toast.fire({
                icon: "error",
                title: 'Please Enter Required Fields!!'
            });
            return;
        }

        await updateDoc(doc(db, "roomtypes", updocid), {
            roomtype: uproomtype,
            noofperson: upnoofperson,
            status: upstatus,
        });
        Toast.fire({
            icon: "success",
            title: 'Room Type Updated Successfully!!'
        });

        fetchData1();
    }

    const imageLink = (id, imgLink) => {
        setpreviewImg(imgLink)
        setupdocid(id)
    }

    const updateImage = async () => {
        if (!upimage) {
            Toast.fire({
                icon: "error",
                title: 'Please select image!!'
            });
            return;
        }

        const imageRef = ref(storage, previewImg);
        await deleteObject(imageRef);

        const storageRef = ref(storage, `images/${upimage.name}-${Date.now()}`);
        const uploadTask = uploadBytesResumable(storageRef, upimage);

        uploadTask.on("state_changed", (snapshot) => {
            const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
            (error) => {
                console.error(error);
            },
            async () => {
                try {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    await updateDoc(doc(db, "roomtypes", updocid), {
                        imageurl: downloadURL,
                    });
                    Toast.fire({
                        icon: "success",
                        title: 'Room Type Image Updated Successfully!!'
                    });

                } catch (err) {
                    Toast.fire({
                        icon: "error",
                        title: 'Error in Image Uploading!!'
                    });
                    console.log(err.message);
                }
            }
        );
    }


    return (
        <div>
            <div className="content-wrapper">
                <br />
                
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ float: "right", textTransform: "uppercase" }} >|| Room Type  Mgmt ||</button>
                        <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#insert-modal">Add Room Type</button><br /><br />
                        <div className="card card-primary card-outline ex1">
                            <div className=" p-1">
                                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                                    className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
                            </div>
                            <div className="card-body table-responsive p-0" >

                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Room Type </th>
                                            <th>No Of Person</th>
                                            <th>Status</th>
                                            <th>Image</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            roomtypes.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.data.roomtype}</td>
                                                    <td>{item.data.noofperson} Person</td>
                                                    <td>{item.data.status}</td>
                                                    <td> <img src={item.data.imageurl} alt='img' height={100} /> </td>
                                                    <td>
                                                        <a onClick={() => handleEdit(item.id, item.data.roomtype, item.data.noofperson, item.data.status)} href='#0' data-toggle="modal" data-target="#text-modal1" className="btn btn-sm btn-success" > <i className="fa fa-edit"></i></a>&nbsp;
                                                        <a onClick={() => { if (window.confirm('Are u sure?')) { handleDelete(item.id, item.data.imageurl) }; }} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a>&nbsp;
                                                        <a href='#0' data-toggle="modal" data-target="#image-modal" onClick={() => imageLink(item.id, item.data.imageurl)} className='btn btn-info btn-sm'><i className="fa fa-photo"></i></a>&nbsp;
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}

            <div className="modal fade" id="insert-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Room Type</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>  Room Type *</label>
                                    <input type="text" value={roomtype} onChange={(e) => setroomtype(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6">
                                    <label> No Of Person</label>
                                    <input type="text" value={noofperson} onChange={(e) => setnoofperson(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6">
                                    <label>  Image</label>
                                    <input type="file" onChange={handleImageChange} className="form-control" />
                                </div>

                                <div className="col-sm-6 mt-2">
                                    <label>Status</label>
                                    <select className='form-control' value={status} onChange={(e) => setstatus(e.target.value)}>
                                        <option value="Active">Active</option>
                                        <option value="Deactive">Deactive</option>
                                        <option value="Pending">Pending</option>
                                    </select>
                                </div>

                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={() => handleSubmit()} className="btn btn-primary" data-dismiss="modal">Save </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="text-modal1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Room Type</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>  Room Type *</label>
                                    <input type="text" value={uproomtype} onChange={(e) => setuproomtype(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6">
                                    <label>  No Of Person</label>
                                    <input type="text" value={upnoofperson} onChange={(e) => setupnoofperson(e.target.value)} className="form-control" />
                                </div>

                                <div className="col-sm-6 mt-2">
                                    <label>Status</label>
                                    <select className='form-control' value={upstatus} onChange={(e) => setupstatus(e.target.value)}>
                                        <option value="Active">Active</option>
                                        <option value="Deactive">Deactive</option>
                                        <option value="Pending">Pending</option>
                                    </select>
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={() => handleUpdate()} className="btn btn-primary" data-dismiss="modal">Update </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="image-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Image</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    <label>  Image</label>
                                    <img src={previewImg} width="100%" height={400} alt='img' /><br /><br />
                                    <input type="file" onChange={handleImageChange1} className="form-control" />
                                </div>

                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={() => updateImage()} className="btn btn-primary" data-dismiss="modal">Update </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
