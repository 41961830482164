import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db, storage } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, deleteDoc, doc } from 'firebase/firestore'
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

export default function Roomimages() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();
    const [roomTypes, setroomTypes] = useState([]);
    const [roomimages, setroomimages] = useState([]);
    const [rtypeid, setrtypeid] = useState("");
    const [image, setimage] = useState("");

    useEffect(() => {
        fetchData1()
    }, []);



    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
        });
    });

    const fetchData1 = async () => {
        try {
            // Fetch room types in real-time
            const q = query(collection(db, 'roomtypes'), orderBy("createdAt", "asc"));
            onSnapshot(q, (querySnapshot) => {
                setroomTypes(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });
    
            // Fetch room type images in real-time and combine with room type data
            const q1 = query(collection(db, 'roomtypeimages'), orderBy("createdAt", "asc"));
            onSnapshot(q1, async (querySnapshot1) => {
                // Collect rtypeids from roomtypeimages
                const rtypeIds = querySnapshot1.docs.map(doc => doc.data().rtypeid);
    
                // Batch-fetch all related room types in parallel
                const docRefs = rtypeIds.map(id => doc(db, "roomtypes", id));
                const docSnaps = await Promise.all(docRefs.map(docRef => getDoc(docRef)));
    
                // Combine room type images with their corresponding room type name
                const combinedData = querySnapshot1.docs.map((doc1, index) => {
                    const roomtype = docSnaps[index].exists() ? docSnaps[index].data().roomtype : "";
                    return {
                        id: doc1.id,
                        data: doc1.data(),
                        roomtype: roomtype
                    };
                });
    
                setroomimages(combinedData);
            });
    
        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
            console.error(error.message);
        }
    };
    



    const handleImageChange = (e) => {
        const selected = e.target.files[0];
        const types = ["image/png", "image/jpeg", "image/jpg"];

        if (selected && types.includes(selected.type)) {
            setimage(selected);
        } else {
            setimage(null);
        }
    };


    const handleSubmit = async () => {

        if (!rtypeid || !image) {
            Toast.fire({
                icon: "error",
                title: 'Please Enter Required Fields!!'
            });

            return;
        }

        const storageRef = ref(storage, `images/${image.name}-${Date.now()}`);
        const uploadTask = uploadBytesResumable(storageRef, image);


        uploadTask.on("state_changed", (snapshot) => {
            const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
            (error) => {
                console.error(error);
            },
            async () => {
                try {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

                    await addDoc(collection(db, "roomtypeimages"), {
                        imageurl: downloadURL,
                        rtypeid: rtypeid,
                        uid: userData.uid,
                        createdAt: Timestamp.now(),
                    });

                    setimage(null);
                    fetchData1();

                    Toast.fire({
                        icon: "success",
                        title: 'Room Type Image Added Successfully!!'
                    });


                } catch (err) {
                    Toast.fire({
                        icon: "error",
                        title: 'Error in Image Uploading!!'
                    });

                    console.log(err.message);
                }
            }
        );
    }

    const handleDelete = async (docid, imgLink) => {
        const imageRef = ref(storage, imgLink);
        await deleteObject(imageRef);

        const taskDocRef = doc(db, 'roomtypeimages', docid)
        await deleteDoc(taskDocRef)
        Toast.fire({
            icon: "success",
            title: 'Room Type Image Deleted Successfully!!'
        });
        fetchData1();

    }



    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ float: "right" }} >|| Room Images ||</button>
                        <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#insert-modal">Add Room Images</button><br /><br />
                        <div className="card card-primary card-outline ex1">

                            <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Room Type</th>
                                            <th>Image</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            roomimages.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{item.roomtype} </td>
                                                    <td> <img src={item.data.imageurl} alt='img' height={100} /> </td>
                                                    <td>
                                                        <a onClick={() => { if (window.confirm('Are u sure?')) { handleDelete(item.id, item.data.imageurl) }; }} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a>&nbsp;
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}

            <div className="modal fade" id="insert-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Room Type Image Image</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Room Type</label>
                                    <select value={rtypeid} onChange={(e) => setrtypeid(e.target.value)} className="form-control" >
                                        <option value=""></option>
                                        {
                                            roomTypes.map((item, index) =>
                                                <option key={index} value={item.id}>{item.data.roomtype}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <label>  Image</label>
                                    <input type="file" onChange={handleImageChange} className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="submit" onClick={() => handleSubmit()} className="btn btn-primary" data-dismiss="modal">Save </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
