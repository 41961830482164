import React, { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2';
import { addDoc, collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import "../../App.css";
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import Menu from '../../panel/Menu';

function Bookingtypewise() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
            fethUsers()
        });
    });
    const printRef = useRef();
    const [loading, setloading] = useState(false);
    const [loadincount, setloadincount] = useState(true);
    const [userData, setUserData] = useState();
    const [fromDate, setfromDate] = useState("");
    const [toDate, settoDate] = useState("");
    const [bookTypeId, setbookTypeId] = useState("");
    const [username, setusername] = useState("");
    const [bookingData, setbookingData] = useState([]);
    const [bookingtypes, setbookingtypes] = useState([]);
    const [total, settotal] = useState(0);

    const fethUsers = async () => {
        try {
            if (userData) {
                if (loadincount) {
                    const q = query(collection(db, 'bookingtypes'));
                    onSnapshot(q, (querySnapshot) => {
                        setbookingtypes(querySnapshot.docs.map(doc => ({
                            id: doc.id,
                            data: doc.data()
                        })));
                    });
                    setloadincount(false);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }


    const getReport = async () => {

        if (!fromDate || !toDate || !bookTypeId) {
            Toast.fire({ icon: "error", title: 'Please select From Date & To Date & Receptionist' });
            return
        }
        setloading(false)
        setbookingData([])
        const today = new Date(fromDate);
        today.setHours(0, 0, 0, 0);

        const tomorrow = new Date(toDate);
        tomorrow.setDate(tomorrow.getDate() + 1);

        console.log(bookTypeId);
        

        const docRef = doc(db, "users", bookTypeId)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            setusername(docSnap.data().username);
        }

        const q = query(collection(db, 'bookings'), where('bookingsource', '==', bookTypeId), where('createdAt', '>=', Timestamp.fromDate(today)), where('createdAt', '<', Timestamp.fromDate(tomorrow)));
        onSnapshot(q, (querySnapshot) => {
            setbookingData(querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })));
        });
        const querySnapshot = await getDocs(q);
        const total = querySnapshot.docs.reduce((sum, doc) => sum + (doc.data().totalamount || 0), 0);
        settotal(total);


        setloading(true)
    }

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    })

    const handleFromDate = (e) => {
        setfromDate(e.target.value)
        setloading(false)
    }
    const handleToDate = (e) => {
        settoDate(e.target.value)
        setloading(false)
    }

    return (
        <div>
            <Menu />
            <div className="content-wrapper">
                <section className="content mt-2">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " >|| Booking Type Wise Report ||</button><br /><br />
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary card-outline">
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-sm-3'>
                                                <label>Select Booking Type</label>
                                                <select value={bookTypeId} onChange={(e) => setbookTypeId(e.target.value)} className='form-control'>
                                                    <option></option>
                                                    {
                                                        bookingtypes.map((item, index) =>
                                                            <option value={item.data.bookingtype}>{item.data.bookingtype}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            <div className='col-sm-3'>
                                                <label>From Date</label>
                                                <input type='date' value={fromDate} onChange={handleFromDate} className='form-control' />
                                            </div>
                                            <div className='col-sm-3'>
                                                <label>To Date</label>
                                                <input type='date' value={toDate} onChange={handleToDate} className='form-control' />
                                            </div>
                                            <div className='col-sm-3'>
                                                <br />
                                                <button className='btn btn-info' onClick={getReport}>Get Report</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    loading &&
                                    <>
                                        <div className="card card-primary card-outline" ref={printRef}>
                                            <div className="card-body">
                                                <div className="row invoice-info">
                                                    <div className="col-sm-12 invoice-col text-center">
                                                        <address>
                                                            <h4>The Sky Land Hotel & Restaurant.</h4>
                                                            Near New Bus Stand, Latur Road, Tuljapur, Dist - Dharashiv, MH - 413601<br />
                                                            Phone: +91 70777779163 |
                                                            Email:  theskylandhotel@gmail.com<br />
                                                            Website:  www.theskylandhotel.com
                                                        </address>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <table className='customers2 '>
                                                        <tr>
                                                            <th style={{ textAlign: "center" }}>From Date : {moment(fromDate).format('DD/MM/YYYY')}  </th>
                                                            <th style={{ textAlign: "center" }}><h5 style={{ textTransform: "uppercase" }}>{username}  REPORT </h5></th>
                                                            <th style={{ textAlign: "center" }}>To Date : {moment(toDate).format('DD/MM/YYYY')}</th>
                                                        </tr>
                                                    </table>
                                                    <table className="customers2">
                                                        <thead>
                                                            <tr>
                                                                <th>Sr No</th>
                                                                <th>Customer Name </th>
                                                                <th>Mobile </th>
                                                                <th>Checkin Date </th>
                                                                <th>Checkout Date </th>
                                                                <th style={{ width: 200 }}>Rooms </th>
                                                                <th>Adults </th>
                                                                <th>Booking Type </th>
                                                                <th style={{ textAlign: "right" }}>Total</th>
                                                                <th style={{ textAlign: "right" }}>Paid</th>
                                                                <th style={{ textAlign: "right" }}>Remaining</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                bookingData.map((item, num = 1) =>
                                                                    <tr key={num + 1}>
                                                                        <td width="80">{num + 1}</td>
                                                                        <td>{item.data.customers.custname}</td>
                                                                        <td>{item.data.customers.mobile} </td>
                                                                        <td>{moment(item.data.checkindate).format('DD/MM/YYYY')}</td>
                                                                        <td>{moment(item.data.checkoutdate).format('DD/MM/YYYY')}</td>
                                                                        <td>
                                                                            <div className='row'>
                                                                                {item.data.rooms.map((item1, index) =>
                                                                                    <span key={index}>{item1.roomname} &nbsp;  </span>
                                                                                )}
                                                                            </div>
                                                                        </td>
                                                                        <td>{item.data.adults} Persons</td>
                                                                        <td>{item.data.bookingsource} </td>
                                                                        <td style={{ textAlign: "right" }}>{item.data.totalamount.toFixed(2)}</td>
                                                                        <td style={{ textAlign: "right" }}>{item.data.advance.toFixed(2)}</td>
                                                                        <td style={{ textAlign: "right" }}>{item.data.remaining.toFixed(2)}</td>

                                                                    </tr>
                                                                )}
                                                        </tbody>
                                                    </table>
                                                    <table className='customers2'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ backgroundColor: "#E0FCFE" }}> <h6> Total Booking Amount :  Rs. {total} /- </h6></th>
                                                            </tr>
                                                        </thead>
                                                    </table>


                                                </div>
                                            </div>

                                        </div>
                                        <center>
                                            <button className='btn btn-success' onClick={handlePrint}>Print Report</button>
                                        </center>
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </section >




            </div>



        </div >

    )
}

export default Bookingtypewise
