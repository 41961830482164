import React, { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2';
import { addDoc, collection, doc, getCountFromServer, getDoc, onSnapshot, orderBy, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import { auth, db, storage } from '../../firebase';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import moment from 'moment';
import Webcam from 'react-webcam';
import { getDownloadURL, ref, uploadString } from 'firebase/storage';
import { ClipLoader } from 'react-spinners';
import Menu from '../../panel/Menu';

const steps = ['Room Details', 'Customer Details', 'Payment Details'];
const today = new Date().toISOString().split('T')[0];

export default function Advancebooking() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
            fethUsers()
        });
    });

    const fethUsers = async () => {
        try {
            if (userData) {
                if (loadincount) {
                    const docRef = doc(db, "users", userData.uid)
                    const docSnap = await getDoc(docRef)
                    if (docSnap.exists()) {
                        setusername(docSnap.data().username);
                        setloadincount(false)
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const [loadincount, setloadincount] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [roomtypes, setroomtypes] = useState([]);
    const [paymodes, setpaymodes] = useState([]);
    const [bookingtypes, setbookingtypes] = useState([]);
    const [rooms, setrooms] = useState([]);
    const [plans, setplans] = useState([]);

    const [bookingrooms, setbookingrooms] = useState([]);

    const [username, setusername] = useState();
    const [userData, setUserData] = useState();
    const [activeStep, setActiveStep] = useState(0);
    const [checkindate, setcheckindate] = useState(moment().format('YYYY-MM-DD'));
    const [checkoutdate, setcheckoutdate] = useState(moment().add(1, 'days').format('YYYY-MM-DD'));
    const [noofdays, setnoofdays] = useState(1);
    const [checkintime, setcheckintime] = useState(moment().format('HH:mm A'));
    const [checkouttime, setcheckouttime] = useState('12:00 PM');
    const [adults, setadults] = useState(2);
    const [childs, setchilds] = useState(0);
    const [noofrooms, setnoofrooms] = useState(0);
    const [custname, setcustname] = useState("");
    const [mobile, setmobile] = useState("");
    const [email, setemail] = useState("");
    const [address, setaddress] = useState("");
    const [city, setcity] = useState("");
    const [state, setstate] = useState("Maharashtra");
    const [gender, setgender] = useState("Male");
    const [visitpurpose, setvisitpurpose] = useState("Darshan");
    const [gstname, setgstname] = useState("");
    const [gstno, setgstno] = useState("");
    const [bookingsource, setbookingsource] = useState("Walking");
    const [documenttype, setdocumenttype] = useState("");
    const [idno, setidno] = useState("");

    const webcamRef1 = useRef(null);
    const webcamRef2 = useRef(null);
    const webcamRef3 = useRef(null);
    const webcamRef4 = useRef(null);

    const [visitorPhoto, setvisitorPhoto] = useState('');
    const [idFront, setidFront] = useState('');
    const [idBack, setidBack] = useState('');
    const [couplePhoto, setcouplePhoto] = useState('');

    const [visitorPhotoUrl, setvisitorPhotoUrl] = useState('');
    const [idFrontUrl, setidFrontUrl] = useState('');
    const [idBackUrl, setidBackUrl] = useState('');
    const [couplePhotoUrl, setcouplePhotoUrl] = useState('');


    const [paymode1, setpaymode1] = useState("");
    const [amount1, setamount1] = useState(0);
    const [paytime1, setpaytime1] = useState(moment().format('YYYY-MM-DD HH:mm A'));
    const [paymode2, setpaymode2] = useState("");
    const [amount2, setamount2] = useState(0);
    const [paytime2, setpaytime2] = useState(moment().format('YYYY-MM-DD HH:mm A'));
    const [rooamount, setrooamount] = useState(0);
    const [totalamount, settotalamount] = useState(0);
    const [advance, setadvance] = useState(0);
    const [remaining, setremaining] = useState(0);
    const [note, setnote] = useState("");

    const [rtypeid, setrtypeid] = useState("");
    const [roomid, setroomid] = useState("");
    const [planid, setplanid] = useState("");
    const [amount, setamount] = useState(0);
    const [discountupto, setdiscountupto] = useState(0);
    const [discount, setdiscount] = useState(0);
    const [roomrate, setroomrate] = useState(0);

    const [bookingType, setbookingType] = useState("Confirm");
    const [payStatus, setpayStatus] = useState("Paid");



    useEffect(() => {
        fetchData1()
    }, []);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const captureVisitor = () => {
        const imageSrc = webcamRef1.current.getScreenshot();
        setvisitorPhoto(imageSrc);
    };

    const captureIdFront = () => {
        const imageSrc = webcamRef2.current.getScreenshot();
        setidFront(imageSrc);
    };

    const captureIdBack = () => {
        const imageSrc = webcamRef3.current.getScreenshot();
        setidBack(imageSrc);
    };

    const captureCouple = () => {
        const imageSrc = webcamRef4.current.getScreenshot();
        setcouplePhoto(imageSrc);
    };

    const handlemobile = (e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, '');
        if (value.length <= 10) {
            setmobile(value);
        }

    }

    const uploadToFirebase1 = async () => {
        if (visitorPhoto) {
            try {
                const storageRef = ref(storage, `bookings/${Date.now()}.jpg`);
                await uploadString(storageRef, visitorPhoto, 'data_url');
                const downloadURL1 = await getDownloadURL(storageRef);
                setvisitorPhotoUrl(downloadURL1)
                Toast.fire({ icon: "success", title: 'Visitor Photo Successfully Uploaded' });
            } catch (error) {
                Toast.fire({ icon: "error", title: 'Error in Visitor Photo Uploading!!' });
            }

        }
    };

    const uploadToFirebase2 = async () => {
        if (idFront) {
            try {
                const storageRef = ref(storage, `bookings/${Date.now()}.jpg`);
                await uploadString(storageRef, idFront, 'data_url');
                const downloadURL = await getDownloadURL(storageRef);
                setidFrontUrl(downloadURL)
                Toast.fire({ icon: "success", title: 'ID Front Successfully Uploaded' });
            } catch (error) {
                Toast.fire({ icon: "error", title: 'Error in ID Front Uploading!!' });
            }
        }
    };

    const uploadToFirebase3 = async () => {
        if (idBack) {
            try {
                const storageRef = ref(storage, `bookings/${Date.now()}.jpg`);
                await uploadString(storageRef, idBack, 'data_url');
                const downloadURL = await getDownloadURL(storageRef);
                setidBackUrl(downloadURL)
                Toast.fire({ icon: "success", title: 'ID Back Successfully Uploaded' });
            } catch (error) {
                Toast.fire({ icon: "error", title: 'Error in ID Back Uploading!!' });
            }

        }
    };

    const uploadToFirebase4 = async () => {
        if (couplePhoto) {
            try {
                const storageRef = ref(storage, `bookings/${Date.now()}.jpg`);
                await uploadString(storageRef, couplePhoto, 'data_url');
                const downloadURL = await getDownloadURL(storageRef);
                setcouplePhotoUrl(downloadURL)
                Toast.fire({ icon: "success", title: 'Couple Photo Successfully Uploaded' });
            } catch (error) {
                Toast.fire({ icon: "error", title: 'Error in Couple Photo Uploading!!' });
            }

        }
    };

    const fetchData1 = async () => {
        try {
            const q = query(collection(db, 'roomtypes'), orderBy('createdAt', 'asc'))
            onSnapshot(q, (querySnapshot) => {
                setroomtypes(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });

            const q1 = query(collection(db, 'paymentmodes'), orderBy('createdAt', 'asc'))
            onSnapshot(q1, (querySnapshot) => {
                setpaymodes(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });
            const q2 = query(collection(db, 'bookingtypes'), orderBy('createdAt', 'asc'))
            onSnapshot(q2, (querySnapshot) => {
                setbookingtypes(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });
        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const handleDate1Change = (event) => {
        if (event.target.value < today) {
            setcheckindate(moment().format('YYYY-MM-DD'));
            handleDateChange(moment().format('YYYY-MM-DD'), checkoutdate);
        } else {
            setcheckindate(event.target.value);
            setcheckoutdate(moment(event.target.value).add(1, 'days').format('YYYY-MM-DD'));
            handleDateChange(event.target.value, moment(event.target.value).add(1, 'days').format('YYYY-MM-DD'));
        }
    };

    const handleDate2Change = (event) => {
        if (event.target.value < today) {
            setcheckoutdate(moment().add(1, 'days').format('YYYY-MM-DD'));
            handleDateChange(checkindate, moment().add(1, 'days').format('YYYY-MM-DD'));
        } else {
            setcheckoutdate(event.target.value);
            handleDateChange(checkindate, event.target.value);
        }

    };

    const handleDateChange = (date1, date2) => {
        if (date1 && date2) {
            const momentDate1 = moment(date1);
            const momentDate2 = moment(date2);
            const diffInDays = momentDate2.diff(momentDate1, 'days');
            if (diffInDays === 0) {
                setnoofdays(1);
                calFinalTotal(1, rooamount);
            } else {
                setnoofdays(diffInDays);
                calFinalTotal(diffInDays, rooamount);
            }
        }
    };

    const calFinalTotal = (days, roomamountxx) => {
        let total = parseFloat(days) * parseFloat(roomamountxx)

        settotalamount(total.toFixed(2));
        setremaining(total.toFixed(2));
    }


    const handleroomtypeChange = (value) => {
        setrtypeid(value);
        setamount(0);
        setroomrate(0);
        setdiscount(0);
        setdiscountupto(0);

        const q = query(collection(db, 'roomdetails'), where('rtypeid', '==', value), where('status', '==', 'Available'))
        onSnapshot(q, (querySnapshot) => {
            setrooms(querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })));
        });

        const q1 = query(collection(db, 'roomtarrifs'), where('rtypeid', '==', value))
        onSnapshot(q1, (querySnapshot) => {
            setplans(querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })));
        });
    }

    const handleplanChange = async (value) => {
        setplanid(value);
        try {
            const userDocRef = doc(db, 'roomtarrifs', value);
            const userDocSnap = await getDoc(userDocRef);

            if (userDocSnap.exists()) {
                setamount(userDocSnap.data().amount);
                setroomrate(userDocSnap.data().amount);
                setdiscount(0);
                setdiscountupto(userDocSnap.data().discount);
            } else {
                Toast.fire({
                    icon: "error",
                    title: 'No Data Found'
                });

            }
        } catch (error) {
            console.error('Error fetching document:', error);
        }
    }

    function caltotal(value) {
        setdiscount(value);
        if (value) {
            if (parseFloat(value) > parseFloat(discountupto)) {
                Toast.fire({ icon: "error", title: 'Discount should not be greater than ' + discountupto });
                setdiscount(discountupto);
                let roomr = parseFloat(amount) * parseFloat(discountupto) / 100;
                let xamount = (parseFloat(amount) - parseFloat(roomr));
                setroomrate(xamount.toFixed(2));
            } else {
                let roomr = parseFloat(amount) * parseFloat(value) / 100;
                let xamount = (parseFloat(amount) - parseFloat(roomr));
                setroomrate(xamount.toFixed(2));
            }
        }
        else {
            setdiscount(0)
        }

    }

    const handleRoomArray = async () => {
        if (!rtypeid || !planid || !amount || !roomrate) {
            Toast.fire({ icon: "error", title: 'Required all fields' });
            return;
        }

        let roomname;
        let roomtypename;
        let plananme;


        const docRef1 = doc(db, "roomtypes", rtypeid);
        const docSnap1 = await getDoc(docRef1);
        if (docSnap1.exists()) {
            roomtypename = docSnap1.data().roomtype;
        } else {
            roomtypename = "";
        }

        const docRef2 = doc(db, "roomtarrifs", planid);
        const docSnap2 = await getDoc(docRef2);
        if (docSnap2.exists()) {
            plananme = docSnap2.data().rateplan;
        } else {
            plananme = "";
        }

        const newItem = { rtypeid, planid, amount, discount, roomrate, roomtypename, plananme, username: username };

        setbookingrooms([...bookingrooms, newItem]);
        setrtypeid("");
        setroomid("");
        setplanid("");
        setamount("");
        setdiscountupto(0);
        setdiscount("");
        setroomrate("");
        setnoofrooms(bookingrooms.length + 1);
        let tamount = 0;
        for (let i = 0; i < bookingrooms.length; i++) {
            tamount = parseFloat(tamount) + parseFloat(bookingrooms[i].roomrate);
        }
        let rxamount = parseFloat(tamount) + parseFloat(roomrate)
        calFinalTotal(noofdays, rxamount);
        setrooamount(rxamount.toFixed(2))
    }

    const handleamount1 = (e) => {
        let value = e.target.value;
        let advance = parseFloat(value) + parseFloat(amount2);
        if (parseFloat(advance) > parseFloat(totalamount)) {
            Toast.fire({ icon: "error", title: 'Advance amount should be greater than ' + totalamount });
            setadvance(0);
            setamount1(0)
            let rem = parseFloat(totalamount) - parseFloat(amount2);
            setremaining(rem.toFixed(2))
            return;
        }
        setamount1(parseFloat(value, 10))
        setadvance(advance);
        let rem = parseFloat(totalamount) - parseFloat(advance);
        setremaining(rem.toFixed(2))

    }

    const handleamount2 = (e) => {
        let value = e.target.value;
        let advance = parseFloat(amount1) + parseFloat(value);
        if (parseFloat(advance) > parseFloat(totalamount)) {
            Toast.fire({ icon: "error", title: 'Advance amount should be greater than ' + totalamount });
            setadvance(0);
            setamount2(0)
            let rem = parseFloat(totalamount) - parseFloat(amount1);
            setremaining(rem.toFixed(2))
            return;
        }

        setamount2(parseFloat(value, 10))
        setadvance(advance);
        let rem = parseFloat(totalamount) - parseFloat(advance);
        setremaining(rem.toFixed(2))
    }


    const removeItem = (itemToRemove) => {
        if (window.confirm(`Are you sure you want to remove`)) {
            const updatedItems = bookingrooms.filter((item) => item !== itemToRemove);
            setbookingrooms(updatedItems);

            let tamount = 0;
            for (let i = 0; i < updatedItems.length; i++) {
                tamount = parseFloat(tamount) + parseFloat(updatedItems[i].roomrate);
            }
            let rxamount = parseFloat(tamount);
            setrooamount(rxamount.toFixed(2))
            calFinalTotal(noofdays, rxamount);
        }
    };


    const checkoutReservation = async () => {
        if (!checkindate || !checkoutdate || !noofdays) {
            Toast.fire({ icon: "error", title: 'Please Select Checkin Date & Checkout Date ' });
            return
        }
        if (!noofrooms) {
            Toast.fire({ icon: "error", title: 'Please Select at least 1 Room' });
            return
        }
        if (!custname || !mobile || !address || !city || !address) {
            Toast.fire({ icon: "error", title: 'Please fill required fields in customer details' });
            return
        }

        let amt1 = amount1.toString();
        let amt2 = amount2.toString();

        if (isNaN(advance)) {
            Toast.fire({ icon: "error", title: 'Please fill required fields in payment details' });
            return
        }

        if (advance !== 0) {
            if (!paymode1 || !amt1 || !paymode2 || !amt2) {
                Toast.fire({ icon: "error", title: 'Please fill required fields in payment details' });
                return
            }
        }

        setIsLoading(true)
        try {
            const customerData = {
                custname: custname,
                mobile: mobile,
                email: email,
                address: address,
                city: city,
                state: state,
                gender: gender,
                gstname: gstname,
                gstno: gstno,
                documenttype: documenttype,
                idno: idno,
                uid: userData.uid,
                createdAt: Timestamp.now(),
            };

            const customerRef = await addDoc(collection(db, 'customers'), {
                customerData,
            });

            const bookingRef = await addDoc(collection(db, 'advancebookings'), {
                customerId: customerRef.id,
                customers: customerData,
                rooms: bookingrooms,
                services: [],
                checkindate: checkindate,
                checkoutdate: checkoutdate,
                noofdays: noofdays,
                checkintime: checkintime,
                checkouttime: checkouttime,
                adults: adults,
                visitpurpose: visitpurpose,
                bookingsource: bookingsource,
                childs: childs,
                noofrooms: noofrooms,
                visitorPhotoUrl: visitorPhotoUrl,
                idFrontUrl: idFrontUrl,
                idBackUrl: idBackUrl,
                couplePhotoUrl: couplePhotoUrl,
                bookingType: bookingType,
                payStatus: payStatus,
                rooamount: parseFloat(rooamount),
                totalamount: parseFloat(totalamount),
                serviceamount: 0,
                restoamount: 0,
                advance: parseFloat(advance),
                remaining: parseFloat(remaining),
                note: note,
                type: "GST",
                status: "Paid",
                approval1: "No",
                approval2: "No",
                approval3: "No",
                approval4: "No",
                extra1: null,
                extra2: null,
                extra3: null,
                extra4: null,
                uid: userData.uid,
                createdAt: Timestamp.now(),
            });

            for (let i = 0; i < noofdays; i++) {
                for (let j = 0; j < bookingrooms.length; j++) {
                    if (bookingType === 'Confirm') {
                        await addDoc(collection(db, 'checkindetails'), {
                            bookingid: bookingRef.id,
                            customerId: customerRef.id,
                            checkindate: moment(checkindate).add(i, 'days').format('YYYY-MM-DD'),
                            checkoutdate: moment(checkindate).add(i + 1, 'days').format('YYYY-MM-DD'),
                            roomtypeid: bookingrooms[j].rtypeid,
                            planid: bookingrooms[j].planid,
                            planname: bookingrooms[j].plananme,
                            roomtype: bookingrooms[j].roomtypename,
                            uid: userData.uid,
                            username: username,
                            bookingType: bookingType,
                            status: 'Paid',
                            createdAt: Timestamp.now(),
                        });
                    }
                    else {
                        await addDoc(collection(db, 'checkindetails'), {
                            bookingid: bookingRef.id,
                            customerId: customerRef.id,
                            checkindate: moment(checkindate).add(i, 'days').format('YYYY-MM-DD'),
                            checkoutdate: moment(checkindate).add(i + 1, 'days').format('YYYY-MM-DD'),
                            roomtypeid: bookingrooms[j].rtypeid,
                            planid: bookingrooms[j].planid,
                            planname: bookingrooms[j].plananme,
                            roomtype: bookingrooms[j].roomtypename,
                            uid: userData.uid,
                            username: username,
                            bookingType: bookingType,
                            createdAt: Timestamp.now(),
                        });
                    }

                }
            }

            if (advance !== 0) {
                await addDoc(collection(db, 'payhistory'), {
                    bookingid: bookingRef.id,
                    customerId: customerRef.id,
                    customers: customerData,
                    rooms: bookingrooms,
                    paymode1: paymode1,
                    amount1: parseFloat(amount1),
                    paytime1: paytime1,
                    paymode2: paymode2,
                    amount2: parseFloat(amount2),
                    paytime2: paytime2,
                    uid: userData.uid,
                    username: username,
                    createdAt: Timestamp.now(),
                    note: note,
                    type: 'Advance Booking',
                    status: 'Working',
                });
            }



            Toast.fire({ icon: "success", title: 'Advance Booking sucessfully Done' });

            setrooms([]); setplans([]); setbookingrooms([]); setActiveStep(0); setcheckindate(moment().format('YYYY-MM-DD'));
            setcheckoutdate(moment().add(1, 'days').format('YYYY-MM-DD')); setnoofdays(1); setcheckintime(moment().format('HH:mm A')); setcheckouttime('12:00 PM'); setadults(2);
            setchilds(0); setnoofrooms(0); setcustname(""); setmobile(""); setemail(""); setaddress(""); setcity(""); setstate(""); setgender("Male");
            setvisitpurpose("Darshan"); setgstname(""); setgstno(""); setbookingsource("Walking"); setdocumenttype(""); setidno("");
            setvisitorPhoto(''); setidFront(''); setidBack(''); setcouplePhoto(''); setvisitorPhotoUrl(''); setidFrontUrl(''); setidBackUrl('');
            setcouplePhotoUrl(''); setpaymode1(""); setamount1(0); setpaytime1(moment().format('YYYY-MM-DD HH:mm A')); setpaymode2(""); setamount2(0); setpaytime2(moment().format('YYYY-MM-DD HH:mm A')); setrooamount(0);
            setadvance(0); setremaining(0); setnote(""); setrtypeid(""); setroomid(""); setplanid(""); setamount(""); setdiscountupto(0); setdiscount("");
            setroomrate("");

        } catch (error) {
            Toast.fire({ icon: "error", title: 'Error in Room Reservation, Error : ' + error.message });
            console.log(error.message);

        }

        setIsLoading(false)

    }


    return (
        <div>
            <Menu />
            <div className="content-wrapper" style={{ backgroundColor: '#fff' }}>
                <section className="content"><br />
                    <div className="container-fluid">
                        <div className="row"></div>
                        <Box sx={{ width: '100%' }} >
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            <br />
                            {isLoading && (
                                <div style={overlayStyle}>
                                    <ClipLoader color="#36d7b7" size={60} />
                                </div>
                            )}
                            <React.Fragment>
                                {activeStep === 0 &&
                                    <Box style={{ padding: 10 }}>
                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <div className='card'>
                                                    <div className="card-body">
                                                        <TextField className='form-control' color="secondary" min={today} label="Check-In Date" type='date' value={checkindate} onChange={handleDate1Change} size='small' /> <br /><br />
                                                        <TextField className='form-control' color="secondary" min={today} label="Check-Out Date" type='date' value={checkoutdate} onChange={handleDate2Change} size='small' /><br /><br />
                                                        <TextField className='form-control' label="No Of Days" type='text' value={noofdays} size='small' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-4'>
                                                <div className='card'>
                                                    <div className="card-body">
                                                        <TextField type='text' className='form-control' value={checkintime} onChange={(e) => setcheckintime(e.target.value)} label="Check-In Time" size='small' /> <br /><br />
                                                        <TextField type='text' className='form-control' value={checkouttime} onChange={(e) => setcheckouttime(e.target.value)} label="Check-Out Time" size='small' /><br /><br /><br /><br />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-4'>
                                                <div className='card'>
                                                    <div className="card-body">
                                                        <table width="100%">
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ padding: 12 }}><label><b>Adults</b></label></td>
                                                                    <td style={{ padding: 12 }}><a href='#0' onClick={() => setadults(adults > 1 ? adults - 1 : 1)} className="btn btn-xs btn-danger"><i className='fa fa-minus'></i></a></td>
                                                                    <td style={{ padding: 12 }}><b id="inputadults1">{adults}</b></td>
                                                                    <td style={{ padding: 12 }}><a href='#0' onClick={() => setadults(adults + 1)} className="btn btn-xs btn-info"><i className='fa fa-plus'></i></a></td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ padding: 12 }}><label><b>Childs</b></label></td>
                                                                    <td style={{ padding: 12 }}><a href='#0' onClick={() => setchilds(childs > 0 ? childs - 1 : 0)} className="btn btn-xs btn-danger"><i className='fa fa-minus'></i></a></td>
                                                                    <td style={{ padding: 12 }}><b id="inputchilds1">{childs}</b></td>
                                                                    <td style={{ padding: 12 }}><a href='#0' onClick={() => setchilds(childs + 1)} className="btn btn-xs btn-info"><i className='fa fa-plus'></i></a></td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ padding: 12 }}><label><b>Rooms</b></label></td>
                                                                    <td style={{ padding: 12 }}></td>
                                                                    <td style={{ padding: 12 }}><b id="inputrooms1">{noofrooms}</b></td>
                                                                    <td style={{ padding: 12 }}></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                <div className='card'>
                                                    <div className="card-body">
                                                        <table width="100%">
                                                            <tbody>
                                                                <tr>
                                                                    <th style={{ padding: 10 }}>Room Type</th>
                                                                    <th style={{ padding: 10 }}>Plan Name</th>
                                                                    <th style={{ padding: 10 }}>Amount</th>
                                                                    <th style={{ padding: 10 }}>Discount</th>
                                                                    <th style={{ padding: 10 }}>Discount Rate</th>
                                                                    <th style={{ padding: 10 }}>Action</th>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ padding: 10 }}>
                                                                        <select className="form-control" value={rtypeid} onChange={(e) => handleroomtypeChange(e.target.value)}>
                                                                            <option> Select </option>
                                                                            {
                                                                                roomtypes.map((item, index) =>
                                                                                    <option key={index} value={item.id}>{item.data.roomtype}</option>

                                                                                )
                                                                            }
                                                                        </select>
                                                                    </td>
                                                                    <td style={{ padding: 10 }}>
                                                                        <select className="form-control" value={planid} onChange={(e) => handleplanChange(e.target.value)}>
                                                                            <option> Select </option>
                                                                            {
                                                                                plans.map((item, index) =>
                                                                                    <option key={index} value={item.id}>{item.data.rateplan}</option>

                                                                                )
                                                                            }
                                                                        </select>
                                                                    </td>
                                                                    <td style={{ padding: 10 }}>
                                                                        <input type="text" value={amount} readOnly className="form-control" />
                                                                    </td>
                                                                    <td style={{ padding: 10 }}>
                                                                        <input type="text" value={discount} onChange={(e) => caltotal(e.target.value)} readOnly className="form-control" />
                                                                    </td>
                                                                    <td style={{ padding: 10 }}>
                                                                        <input type="text" value={roomrate} readOnly className="form-control" />
                                                                    </td>
                                                                    <td style={{ padding: 10 }}>
                                                                        <button className='btn btn-sm btn-info' onClick={() => handleRoomArray()}>
                                                                            <i className='fa fa-plus'></i> Add
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                {
                                                                    bookingrooms.map((item, index) =>
                                                                        <tr key={index}>
                                                                            <td style={{ padding: 10 }}>
                                                                                <input readOnly type="text" defaultValue={item.roomtypename} className="form-control" />
                                                                            </td>
                                                                            <td style={{ padding: 10 }}>
                                                                                <input readOnly type="text" defaultValue={item.plananme} className="form-control" />
                                                                            </td>
                                                                            <td style={{ padding: 10 }}>
                                                                                <input readOnly type="text" defaultValue={item.amount} className="form-control" />
                                                                            </td>
                                                                            <td style={{ padding: 10 }}>
                                                                                <input readOnly type="text" defaultValue={item.discount} className="form-control" />
                                                                            </td>
                                                                            <td style={{ padding: 10 }}>
                                                                                <input readOnly type="text" defaultValue={item.roomrate} className="form-control" />
                                                                            </td>
                                                                            <td style={{ padding: 10 }}>
                                                                                <button onClick={() => removeItem(item)} className='btn btn-sm btn-danger'>
                                                                                    <i className='fa fa-trash'></i>
                                                                                </button>&nbsp;&nbsp;
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Box>

                                }
                                {activeStep === 1 &&
                                    <Box >
                                        <div className='card'>
                                            <div className="card-body">
                                                <div className='row'>
                                                    <div className='col-sm-3 mt-4'>
                                                        <TextField className='form-control' color="secondary" value={custname} onChange={(e) => setcustname(e.target.value)} label="Customer Name" type='text' size='small' />
                                                    </div>
                                                    <div className='col-sm-3 mt-4'>
                                                        <TextField className='form-control' color="secondary" value={mobile} onChange={handlemobile} label="Mobile" type='tel' size='small' />
                                                    </div>
                                                    <div className='col-sm-3 mt-4'>
                                                        <TextField className='form-control' value={email} onChange={(e) => setemail(e.target.value)} label="Email" type='email' size='small' />
                                                    </div>
                                                    <div className='col-sm-3 mt-4'>
                                                        <TextField className='form-control' color="secondary" value={address} onChange={(e) => setaddress(e.target.value)} label="Address" type='text' size='small' />
                                                    </div>
                                                    <div className='col-sm-3 mt-4'>
                                                        <TextField className='form-control' color="secondary" value={city} onChange={(e) => setcity(e.target.value)} label="City" type='text' size='small' />
                                                    </div>
                                                    <div className='col-sm-3 mt-4'>
                                                        <TextField className='form-control' value={state} onChange={(e) => setstate(e.target.value)} label="State" type='text' size='small' />
                                                    </div>
                                                    <div className='col-sm-3 mt-4'>
                                                        <FormControl className='form-control'>
                                                            <InputLabel id="gender" style={{ backgroundColor: "white" }}>Gender</InputLabel>
                                                            <Select labelId='gender' size='small' value={gender} onChange={(e) => setgender(e.target.value)} label="Age">
                                                                <MenuItem value='Male'>Male</MenuItem>
                                                                <MenuItem value='Female'>Female</MenuItem>
                                                                <MenuItem value='Other'>Other</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className='col-sm-3 mt-4'>
                                                        <FormControl className='form-control'>
                                                            <InputLabel id="visitpurpose" style={{ backgroundColor: "white" }}>Visit Purpose</InputLabel>
                                                            <Select labelId='visitpurpose' size='small' value={visitpurpose} onChange={(e) => setvisitpurpose(e.target.value)} label="Age">
                                                                <MenuItem value='Darshan'>Darshan</MenuItem>
                                                                <MenuItem value='Tourist'>Tourist</MenuItem>
                                                                <MenuItem value='Business'>Business</MenuItem>
                                                                <MenuItem value='Official'>Official</MenuItem>
                                                                <MenuItem value='Sponsored Event'>Sponsored Event</MenuItem>
                                                                <MenuItem value='Party'>Party</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className='col-sm-3 mt-4'>
                                                        <TextField className='form-control' value={gstname} onChange={(e) => setgstname(e.target.value)} label="GST Name" type='text' size='small' />
                                                    </div>
                                                    <div className='col-sm-3 mt-4'>
                                                        <TextField className='form-control' value={gstno} onChange={(e) => setgstno(e.target.value)} label="GST No" type='text' size='small' />
                                                    </div>
                                                    <div className='col-sm-3 mt-4'>
                                                        <FormControl className='form-control'>
                                                            <InputLabel id="bookingsource" style={{ backgroundColor: "white" }}>Booking Source</InputLabel>
                                                            <Select labelId='bookingsource' size='small' value={bookingsource} onChange={(e) => setbookingsource(e.target.value)} label="Age">
                                                                <MenuItem value=''></MenuItem>
                                                                {
                                                                    bookingtypes.map((item, index) =>
                                                                        <MenuItem key={index} value={item.data.bookingtype}>{item.data.bookingtype}</MenuItem>
                                                                    )
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className='col-sm-3 mt-4'>
                                                        <TextField className='form-control' color="secondary" value={documenttype} onChange={(e) => setdocumenttype(e.target.value)} label="Document Type" type='text' size='small' />
                                                    </div>
                                                    <div className='col-sm-3 mt-4'>
                                                        <TextField className='form-control' color="secondary" value={idno} onChange={(e) => setidno(e.target.value)} label="ID No" type='text' size='small' />
                                                    </div>
                                                    <div className='col-sm-9 mt-4'>
                                                    </div>
                                                    {/* <div className='col-sm-3 mt-4'>
                                                        <label>Visitor Photo</label><br />
                                                        <button data-toggle="modal" data-target="#visitorModal" className='btn btn-sm btn-success'>Choose Image</button>
                                                    </div>
                                                    <div className='col-sm-3 mt-4'>
                                                        <label>ID Proof Front</label><br />
                                                        <button data-toggle="modal" data-target="#idFrontmodal" className='btn btn-sm btn-success'>Choose Image</button>
                                                    </div>
                                                    <div className='col-sm-3 mt-4'>
                                                        <label>ID Proof Back</label><br />
                                                        <button data-toggle="modal" data-target="#idBackmodal" className='btn btn-sm btn-success'>Choose Image</button>
                                                    </div>
                                                    <div className='col-sm-3 mt-4'>
                                                        <label>Couple Photo</label><br />
                                                        <button data-toggle="modal" data-target="#coupleModal" className='btn btn-sm btn-success'>Choose Image</button>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </Box>

                                }
                                {activeStep === 2 &&
                                    <Box >

                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <div className='card'>
                                                    <div className="card-body">
                                                        <table width="100%">
                                                            <tr>
                                                                <td width="80%">
                                                                    <h6>{custname}</h6>
                                                                    <h6>{mobile}</h6>
                                                                    <h6>{email}</h6>
                                                                </td>
                                                                <td width="20%">
                                                                    {
                                                                        visitorPhoto &&
                                                                        <img src={visitorPhoto} alt="Captured" height={70} width={70} />
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <table width="100%">
                                                            <tr>
                                                                <td width="50%"><h6>Address</h6></td>
                                                                <td width="50%" style={{ float: "right" }}>{address} {city} {state}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%"><h6>Gender</h6></td>
                                                                <td width="50%" style={{ float: "right" }}>{gender}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%"><h6>Visit Purpose</h6></td>
                                                                <td width="50%" style={{ float: "right" }}>{visitpurpose}</td>
                                                            </tr>

                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-4'>
                                                <div className='card'>
                                                    <div className="card-body">
                                                        <table width="100%">
                                                            <tr>
                                                                <td width="50%"><h6>No of Adult</h6></td>
                                                                <td width="50%" style={{ float: "right" }}>{adults}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%"><h6>No of Child</h6></td>
                                                                <td width="50%" style={{ float: "right" }}>{childs}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%"><h6>GST No</h6></td>
                                                                <td width="50%" style={{ float: "right" }}>{gstno}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%"><h6>GST Name</h6></td>
                                                                <td width="50%" style={{ float: "right" }}>{gstname}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%"><h6>Document Type</h6></td>
                                                                <td width="50%" style={{ float: "right" }}>{documenttype}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%"><h6>ID No</h6></td>
                                                                <td width="50%" style={{ float: "right" }}>{idno}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-4'>
                                                <div className='card'>
                                                    <div className="card-body">
                                                        <table width="100%">
                                                            <tr>
                                                                <td width="50%"><h6>Checkin Date</h6></td>
                                                                <td width="50%" style={{ float: "right" }}>{checkindate}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%"><h6>Checkout Date</h6></td>
                                                                <td width="50%" style={{ float: "right" }}>{checkoutdate}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%"><h6>No of Day</h6></td>
                                                                <td width="50%" style={{ float: "right" }}>{noofdays}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%"><h6>No of Rooms</h6></td>
                                                                <td width="50%" style={{ float: "right" }}>{noofrooms}</td>
                                                            </tr>

                                                            <tr>
                                                                <td width="50%"><h6>Total (Inc. Tax)</h6></td>
                                                                <td width="50%" style={{ float: "right" }}>{totalamount}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%">&nbsp;</td>
                                                                <td width="50%" style={{ float: "right" }}></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='card'>
                                            <div className="card-body">
                                                <div className='row'>
                                                    <div className='col-sm-3'>
                                                        <FormControl className='form-control'>
                                                            <InputLabel id="bookingType" style={{ backgroundColor: "white" }}>Booking Type</InputLabel>
                                                            <Select labelId='bookingType' size='small' value={bookingType} onChange={(e) => setbookingType(e.target.value)} label="Age">
                                                                <MenuItem value="Confirm">Confirm</MenuItem>
                                                                <MenuItem value="Not Confirm">Not Confirm</MenuItem>
                                                            </Select>
                                                        </FormControl><br /><br />
                                                        <FormControl className='form-control'>
                                                            <InputLabel id="payStatus" style={{ backgroundColor: "white" }}>Payment Status</InputLabel>
                                                            <Select labelId='payStatus' size='small' value={payStatus} onChange={(e) => setpayStatus(e.target.value)} label="Age">
                                                                <MenuItem value="Paid">Paid</MenuItem>
                                                                <MenuItem value="Unpaid">Unpaid</MenuItem>
                                                                <MenuItem value="Pay At Hotel">Pay At Hotel</MenuItem>
                                                            </Select>
                                                        </FormControl><br /><br />
                                                        <TextField className='form-control' value={note} onChange={(e) => setnote(e.target.value)} label="Note" type='text' size='small' />
                                                    </div>
                                                    {
                                                        payStatus === 'Paid' &&
                                                        <>
                                                            <div className='col-sm-3'>
                                                                <FormControl className='form-control'>
                                                                    <InputLabel id="paymode1" style={{ backgroundColor: "white" }}>Pay Mode 1</InputLabel>
                                                                    <Select labelId='paymode1' size='small' value={paymode1} onChange={(e) => setpaymode1(e.target.value)} label="Age">
                                                                        <MenuItem></MenuItem>
                                                                        {
                                                                            paymodes.map((item, index) =>
                                                                                <MenuItem key={index} value={item.id}>{item.data.paymode}</MenuItem>
                                                                            )
                                                                        }
                                                                    </Select>
                                                                </FormControl><br /><br />
                                                                <TextField className='form-control' value={amount1} label="Amount 1" onChange={handleamount1} type='number' size='small' /><br /><br />
                                                                <TextField className='form-control' value={paytime1} onChange={(e) => setpaytime1(e.target.value)} label="Payment Time 1" type='datetime-local' size='small' />
                                                            </div>
                                                            <div className='col-sm-3'>
                                                                <FormControl className='form-control'>
                                                                    <InputLabel id="paymode2" style={{ backgroundColor: "white" }}>Pay Mode 2</InputLabel>
                                                                    <Select labelId='paymode2' size='small' value={paymode2} onChange={(e) => setpaymode2(e.target.value)} label="Age">
                                                                        <MenuItem></MenuItem>
                                                                        {
                                                                            paymodes.map((item, index) =>
                                                                                <MenuItem key={index} value={item.id}>{item.data.paymode}</MenuItem>
                                                                            )
                                                                        }
                                                                    </Select>
                                                                </FormControl><br /><br />
                                                                <TextField className='form-control' value={amount2} label="Amount 2" onChange={handleamount2} type='number' size='small' /><br /><br />
                                                                <TextField className='form-control' value={paytime2} onChange={(e) => setpaytime2(e.target.value)} label="Payment Time 2" type='datetime-local' size='small' />
                                                            </div>
                                                            <div className='col-sm-3'>
                                                                <TextField className='form-control' value={advance} readOnly label="Paid Amount" type='text' size='small' /><br /><br />
                                                                <TextField className='form-control' value={remaining} readOnly label="Remaining" type='text' size='small' /><br /><br />
                                                            </div>
                                                        </>
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    </Box>
                                }

                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        <img src={window.location.origin + '/back.png'} height={30} alt="A" style={{ opacity: '.8' }} />
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    {activeStep === steps.length - 1 ?
                                        <Button variant="contained" onClick={checkoutReservation}>Save Advance Booking</Button> :
                                        <Button variant="contained" onClick={handleNext}>Next</Button>
                                    }
                                </Box>
                            </React.Fragment >


                            <div className="modal fade" id="visitorModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-lg" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">Visitor Image</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className='col-sm-8'>
                                                    <Webcam audio={false} ref={webcamRef1} screenshotFormat="image/jpeg" width="100%" />
                                                    <button className='btn btn-primary' onClick={captureVisitor}>Capture</button>
                                                </div>
                                                <div className="col-sm-4">
                                                    {visitorPhoto && (
                                                        <div>
                                                            <h3>Preview</h3>
                                                            <img src={visitorPhoto} alt="Captured" width="100%" />
                                                            <button className='btn btn-info' onClick={uploadToFirebase1}>Upload Image</button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="modal fade" id="idFrontmodal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-lg" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">ID Proof Front</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className='col-sm-8'>
                                                    <Webcam audio={false} ref={webcamRef2} screenshotFormat="image/jpeg" width="100%" />
                                                    <button className='btn btn-primary' onClick={captureIdFront}>Capture</button>
                                                </div>
                                                <div className="col-sm-4">
                                                    {idFront && (
                                                        <div>
                                                            <h3>Preview</h3>
                                                            <img src={idFront} alt="Captured" width="100%" />
                                                            <button className='btn btn-info' onClick={uploadToFirebase2}>Upload Image</button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="modal fade" id="idBackmodal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-lg" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">Id Proof Back</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className='col-sm-8'>
                                                    <Webcam audio={false} ref={webcamRef3} screenshotFormat="image/jpeg" width="100%" />
                                                    <button className='btn btn-primary' onClick={captureIdBack}>Capture</button>
                                                </div>
                                                <div className="col-sm-4">
                                                    {idBack && (
                                                        <div>
                                                            <h3>Preview</h3>
                                                            <img src={idBack} alt="Captured" width="100%" />
                                                            <button className='btn btn-info' onClick={uploadToFirebase3}>Upload Image</button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="modal fade" id="coupleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-lg" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">Couple Photo</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className='col-sm-8'>
                                                    <Webcam audio={false} ref={webcamRef4} screenshotFormat="image/jpeg" width="100%" />
                                                    <button className='btn btn-primary' onClick={captureCouple}>Capture</button>
                                                </div>
                                                <div className="col-sm-4">
                                                    {couplePhoto && (
                                                        <div>
                                                            <h3>Preview</h3>
                                                            <img src={couplePhoto} alt="Captured" width="100%" />
                                                            <button className='btn btn-info' onClick={uploadToFirebase4}>Upload Image</button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </Box >
                    </div>
                </section>
            </div >
        </div >
    );
}

const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Ensure the overlay is on top
};
