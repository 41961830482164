import { signInWithEmailAndPassword } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { addDoc, collection, doc, getDoc } from 'firebase/firestore';
import moment from 'moment/moment';

function Login() {
    useEffect(() => {
        if (localStorage.getItem('user_info')) {
        }
    }, [])

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("");
    const [systemDetails, setSystemDetails] = useState({
        userAgent: "",
        platform: "",
        screenWidth: 0,
        screenHeight: 0,
        language: "",
        timezone: "",
    });

    useEffect(() => {
        // Get system details using browser APIs
        const details = {
            userAgent: navigator.userAgent, // Browser and OS details
            platform: navigator.platform,   // Platform details (e.g., Win32, Linux x86_64)
            screenWidth: window.screen.width,  // Screen width
            screenHeight: window.screen.height, // Screen height
            language: navigator.language,    // Browser language
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Timezone
        };

        setSystemDetails(details);
    }, []);



    async function login1() {
        if (email.length === 0 || password.length === 0) {
            Toast.fire({
                icon: 'error',
                title: 'Please Enter Username Or Password'
            })
        }
        else {
            try {
                await signInWithEmailAndPassword(auth, email, password);
                const user = auth.currentUser;
                if (user) {
                    const docRef = doc(db, "users", user.uid)
                    const docSnap = await getDoc(docRef)
                    if (docSnap.data().role === 'General Manager') {
                        Toast.fire({
                            icon: "success",
                            title: 'User Logged Successfully!!'
                        });

                        await addDoc(collection(db, 'logindetails'), {
                            uid: user.uid,
                            username: docSnap.data().username,
                            systemDetails,
                            createdTime: moment().format("DD/MM/YYY HH:mm:SS A")
                        });


                        window.location.href = "/home";
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: 'Match not found!!'
                        });
                        auth.signOut();
                    }

                } else {
                    Toast.fire({
                        icon: "error",
                        title: 'Login not found!!'
                    });
                }
            } catch (error) {
                Toast.fire({
                    icon: "error",
                    title: 'Login not found! Please Try Again'
                });
            }

        }
    }

    return (
        <>
            <body className="hold-transition login-page" >
                <div className="login-box">
                    {/* /.login-logo */}
                    <div className="card card-outline card-info">
                        <div className="card-header text-center">
                            <a href="#0" className="h1"><b>SKYLAND   </b> HOTEL</a>
                        </div>
                        
                        <div className="card-body">
                            <p className="login-box-msg">Sign in to start your session</p>
                            <div className="input-group mb-3">
                                <input type="email" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <button type="submit" onClick={login1} className="btn btn-info btn-block">Sign In</button>
                                </div>
                            </div>
                            <br />
                            {/* <p className="mb-1 text-center">
                                <a href="/forgot-password" class="text-center">I forgot my password</a>
                            </p> */}
                            <p className="mb-0">
                                <center>
                                    {/* <a href="/register" className="text-center">Register a new membership</a> */}
                                </center>
                            </p>
                        </div>
                        {/* /.card-body */}
                    </div>
                    {/* /.card */}
                </div>
                {/* /.login-box */}

            </body>

        </>
    )
}

export default Login;