import React, { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2';
import { collection, doc, getCountFromServer, getDocs, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import "../../App.css";
import Menu from '../../panel/Menu';

function Livebookingrooms() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    const [roomTypes, setroomTypes] = useState([]);

    useEffect(() => {
        fetchData1()
    }, []);


    const fetchData1 = async () => {
        try {
            const roomtypesQuery = query(collection(db, 'roomtypes'));
            const roomtypesSnapshot = await getDocs(roomtypesQuery);
            const roomtypesData = roomtypesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            const rTypeId = roomtypesData.map((item) => item.id);
            const roomdetailsQuery = query(collection(db, 'roomdetails'), where('rtypeid', 'in', rTypeId));
            const roomdetailsSnapshot = await getDocs(roomdetailsQuery);
            const roomdetailsData = roomdetailsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            const combinedData = roomtypesData.map((rtype) => {
                const rTyperoomdetails = roomdetailsData.filter((item) => item.rtypeid === rtype.id);
                return { ...rtype, roomdetails: rTyperoomdetails };
            });

            setroomTypes(combinedData);

        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }



    return (
        <div>
            <Menu />
            <div className="content-wrapper" style={{ backgroundColor: '#fff' }}>
                <section className="content"><br />
                    <div className="container-fluid">
                        <button className="btn btn-secondary " >|| Live Booking Rooms ||</button><br /><br />

                        {
                            roomTypes.map((item, index) =>
                                <div key={index}>
                                    <h5 key={index} className='mt-2'>{item.roomtype}</h5>
                                    <div className="row" style={{ marginTop: -10 }}>
                                        {
                                            item.roomdetails.map((room, index) =>
                                                <>
                                                    {
                                                        room.status === 'Booked' &&
                                                        <div class="col-lg-1">
                                                            <button className="form-control btn btn-danger">{room.roomno}</button>
                                                        </div>
                                                    }
                                                </>
                                            )
                                        }

                                    </div>
                                </div>
                            )
                        }

                    </div>
                </section>

            </div>


        </div>
    )
}

export default Livebookingrooms
