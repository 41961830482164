import React, { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2';
import { addDoc, collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import "../../App.css";
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import Menu from '../../panel/Menu';

function Receptionistreport() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
            fethUsers()
        });
    });
    const printRef = useRef();
    const [loading, setloading] = useState(false);
    const [loadincount, setloadincount] = useState(true);
    const [userData, setUserData] = useState();
    const [fromDate, setfromDate] = useState("");
    const [toDate, settoDate] = useState("");
    const [receptId, setreceptId] = useState("");
    const [username, setusername] = useState("");
    const [bookingData, setbookingData] = useState([]);
    const [payhistory, setpayhistory] = useState([]);
    const [paymodes, setpaymodes] = useState([]);
    const [recepts, setrecepts] = useState([]);
    const [total, settotal] = useState(0);

    const fethUsers = async () => {
        try {
            if (userData) {
                if (loadincount) {
                    const q = query(collection(db, 'users'), where('role', '==', 'Receptionist'));
                    onSnapshot(q, (querySnapshot) => {
                        setrecepts(querySnapshot.docs.map(doc => ({
                            id: doc.id,
                            data: doc.data()
                        })));
                    });
                    setloadincount(false);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }


    const getReport = async () => {

        if (!fromDate || !toDate || !receptId) {
            Toast.fire({ icon: "error", title: 'Please select From Date & To Date & Receptionist' });
            return
        }
        setloading(false)
        setbookingData([])
        setpaymodes([])
        setpayhistory([])
        const today = new Date(fromDate);
        today.setHours(0, 0, 0, 0);

        const tomorrow = new Date(toDate);
        tomorrow.setDate(tomorrow.getDate() + 1);

        const docRef = doc(db, "users", receptId)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            setusername(docSnap.data().username);
        }

        const q = query(collection(db, 'bookings'), where('uid', '==', receptId), where('createdAt', '>=', Timestamp.fromDate(today)), where('createdAt', '<', Timestamp.fromDate(tomorrow)));
        onSnapshot(q, (querySnapshot) => {
            setbookingData(querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })));
        });
        const querySnapshot = await getDocs(q);
        const total = querySnapshot.docs.reduce((sum, doc) => sum + (doc.data().totalamount || 0), 0);
        settotal(total);


        const q1 = query(collection(db, 'payhistory'), where('uid', '==', receptId), where('createdAt', '>=', Timestamp.fromDate(today)), where('createdAt', '<', Timestamp.fromDate(tomorrow)));
        onSnapshot(q1, async (querySnapshot1) => {
            const combinedData = [];
            let paymode1 = ""
            let paymode2 = ""
            for (const doc1 of querySnapshot1.docs) {
                const docRef1 = doc(db, "paymentmodes", doc1.data().paymode1)
                const docSnap1 = await getDoc(docRef1)
                if (docSnap1.exists()) {
                    paymode1 = docSnap1.data().paymode;
                } else {
                    paymode1 = ""
                }

                const docRef2 = doc(db, "paymentmodes", doc1.data().paymode2)
                const docSnap2 = await getDoc(docRef2)
                if (docSnap2.exists()) {
                    paymode2 = docSnap2.data().paymode;
                } else {
                    paymode2 = ""
                }

                const date = doc1.data().createdAt.toDate();
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear();
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                const seconds = date.getSeconds().toString().padStart(2, '0');

                const createdat = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

                combinedData.push({
                    id: doc1.id,
                    data: doc1.data(),
                    paymode1: paymode1,
                    paymode2: paymode2,
                    createdat: createdat,
                });
            }
            setpayhistory(combinedData);
        });

        const q2 = query(collection(db, 'paymentmodes'));
        onSnapshot(q2, async (querySnapshot1) => {
            const combinedData = [];
            let total = 0;
            for (const doc1 of querySnapshot1.docs) {
                const q1 = query(collection(db, 'payhistory'), where('paymode1', '==', doc1.id), where('uid', '==', receptId), where('createdAt', '>=', Timestamp.fromDate(today)), where('createdAt', '<', Timestamp.fromDate(tomorrow)));
                const querySnapshot = await getDocs(q1);
                const total1 = querySnapshot.docs.reduce((sum, doc) => sum + (doc.data().amount1 || 0), 0);

                const q2 = query(collection(db, 'payhistory'), where('paymode2', '==', doc1.id), where('uid', '==', receptId), where('createdAt', '>=', Timestamp.fromDate(today)), where('createdAt', '<', Timestamp.fromDate(tomorrow)));
                const querySnapshot1 = await getDocs(q2);
                const total2 = querySnapshot1.docs.reduce((sum, doc) => sum + (doc.data().amount2 || 0), 0);

                let newTotal = parseFloat(total1) + parseFloat(total2)
                total = parseFloat(newTotal) + parseFloat(total);

                combinedData.push({
                    id: doc1.id,
                    paymode: doc1.data().paymode,
                    totalamount: newTotal
                });
            }
            settotal(total)
            setpaymodes(combinedData);
        });

        setloading(true)
    }

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    })

    const handleFromDate = (e) => {
        setfromDate(e.target.value)
        setloading(false)
    }
    const handleToDate = (e) => {
        settoDate(e.target.value)
        setloading(false)
    }

    return (
        <div>
            <Menu />
            <div className="content-wrapper">
                <section className="content mt-2">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " >|| Receptionist Report ||</button><br /><br />
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary card-outline">
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-sm-3'>
                                                <label>Select Receptionist</label>
                                                <select value={receptId} onChange={(e) => setreceptId(e.target.value)} className='form-control'>
                                                    <option></option>
                                                    {
                                                        recepts.map((item, index) =>
                                                            <option value={item.data.uid}>{item.data.username}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            <div className='col-sm-3'>
                                                <label>From Date</label>
                                                <input type='date' value={fromDate} onChange={handleFromDate} className='form-control' />
                                            </div>
                                            <div className='col-sm-3'>
                                                <label>To Date</label>
                                                <input type='date' value={toDate} onChange={handleToDate} className='form-control' />
                                            </div>
                                            <div className='col-sm-3'>
                                                <br />
                                                <button className='btn btn-info' onClick={getReport}>Get Report</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    loading &&
                                    <>
                                        <div className="card card-primary card-outline" ref={printRef}>
                                            <div className="card-body">
                                                <div className="row invoice-info">
                                                    <div className="col-sm-12 invoice-col text-center">
                                                        <address>
                                                            <h4>The Sky Land Hotel & Restaurant.</h4>
                                                            Near New Bus Stand, Latur Road, Tuljapur, Dist - Dharashiv, MH - 413601<br />
                                                            Phone: +91 70777779163 |
                                                            Email:  theskylandhotel@gmail.com<br />
                                                            Website:  www.theskylandhotel.com
                                                        </address>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <table className='customers2 '>
                                                        <tr>
                                                            <th style={{ textAlign: "center" }}>From Date : {moment(fromDate).format('DD/MM/YYYY')}  </th>
                                                            <th style={{ textAlign: "center" }}><h5 style={{ textTransform: "uppercase" }}>{username}  REPORT </h5></th>
                                                            <th style={{ textAlign: "center" }}>To Date : {moment(toDate).format('DD/MM/YYYY')}</th>
                                                        </tr>
                                                    </table>
                                                    <table className="customers2">
                                                        <thead>
                                                            <tr>
                                                                <th>Sr No</th>
                                                                <th>Customer Name </th>
                                                                <th>Mobile </th>
                                                                <th>Checkin Date </th>
                                                                <th>Checkout Date </th>
                                                                <th style={{ width: 200 }}>Rooms </th>
                                                                <th>Adults </th>
                                                                <th style={{ textAlign: "right" }}>Total</th>
                                                                <th style={{ textAlign: "right" }}>Paid</th>
                                                                <th style={{ textAlign: "right" }}>Remaining</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                bookingData.map((item, num = 1) =>
                                                                    <tr key={num + 1}>
                                                                        <td width="80">{num + 1}</td>
                                                                        <td>{item.data.customers.custname}</td>
                                                                        <td>{item.data.customers.mobile} </td>
                                                                        <td>{moment(item.data.checkindate).format('DD/MM/YYYY')}</td>
                                                                        <td>{moment(item.data.checkoutdate).format('DD/MM/YYYY')}</td>
                                                                        <td>
                                                                            <div className='row'>
                                                                                {item.data.rooms.map((item1, index) =>
                                                                                    <span key={index}>{item1.roomname} &nbsp;  </span>
                                                                                )}
                                                                            </div>
                                                                        </td>
                                                                        <td>{item.data.adults} Persons</td>
                                                                        <td style={{ textAlign: "right" }}>{item.data.totalamount.toFixed(2)}</td>
                                                                        <td style={{ textAlign: "right" }}>{item.data.advance.toFixed(2)}</td>
                                                                        <td style={{ textAlign: "right" }}>{item.data.remaining.toFixed(2)}</td>

                                                                    </tr>
                                                                )}
                                                        </tbody>
                                                    </table>
                                                    <table className='customers2'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ backgroundColor: "#E0FCFE" }}> <h6> Total Booking Amount :  Rs. {total} /- </h6></th>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                    {/* <table className="customers2">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ backgroundColor: "#E0FCFE" }} colSpan={10}>Collection Reports</th>
                                                            </tr>
                                                            <tr>
                                                                <th>Paymode 1</th>
                                                                <th>Amount 1</th>
                                                                <th>Paytime 1</th>
                                                                <th>Paymode 2</th>
                                                                <th>Amount 2</th>
                                                                <th>Paytime 2</th>
                                                                <th>Datetime</th>
                                                                <th>Note</th>
                                                                <th>Username</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                payhistory.map((item, index) =>
                                                                    <tr key={index}>
                                                                        <td>{item.paymode1}</td>
                                                                        <td>{item.data.amount1}</td>
                                                                        <td>{item.data.paytime1}</td>
                                                                        <td>{item.paymode2}</td>
                                                                        <td>{item.data.amount2}</td>
                                                                        <td>{item.data.paytime2}</td>
                                                                        <td>{item.createdat}</td>
                                                                        <td>{item.data.note}</td>
                                                                        <td>{item.data.username}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table> */}
                                                    <table className='customers2'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ backgroundColor: "#E0FCFE" }} colSpan={paymodes.length + 1}>Paymode Wise Collection</th>
                                                            </tr>
                                                            <tr>
                                                                <th>Paymodes</th>
                                                                {
                                                                    paymodes.map((item, index) =>
                                                                        <th key={index}>{item.paymode}</th>
                                                                    )
                                                                }
                                                            </tr>
                                                            <tr>
                                                                <th>Amount</th>
                                                                {
                                                                    paymodes.map((item, index) =>
                                                                        <td key={index}>{item.totalamount}</td>
                                                                    )
                                                                }
                                                            </tr>
                                                        </thead>
                                                    </table><br />
                                                    <table className='customers2'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ backgroundColor: "#E0FCFE" }}> <h6> Total Collection of {username} :  Rs. {total} /- </h6></th>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                        <center>
                                            <button className='btn btn-success' onClick={handlePrint}>Print Report</button>
                                        </center>
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </section >




            </div>



        </div >

    )
}

export default Receptionistreport
